import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoffeeComponent} from './coffee.component';



@NgModule({
	declarations: [CoffeeComponent],
	imports: [
		CommonModule
	],
	exports: [CoffeeComponent],

})
export class CoffeeModule { }
