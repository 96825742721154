import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MarioComponent} from './mario.component';



@NgModule({
	declarations: [MarioComponent],
	imports: [
		CommonModule
	],
	exports: [MarioComponent],


})
export class MarioModule { }
