import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {MenuModule} from './components/menu/menu.module';
import {RouterModule} from '@angular/router';
import {MarioModule} from './components/mario/mario.module';
import {AboutMeComponent} from './pages/about-me/about-me.component';
import {CoffeeModule} from './components/coffee/coffee.module';
import {LaptopModule} from './components/laptop/laptop.module';
//import { SpiralComponent } from './components/spiral/spiral.component';
//import { PenComponent } from './components/pen/pen.component';
import {PostitModule} from "./components/postit/postit.module";
import {BookModule} from "./components/book/book.module";
import {IphoneModule} from "./components/iphone/iphone.module";
import {GlassesModule} from "./components/glasses/glasses.module";

@NgModule({
	declarations: [
		AppComponent,
		AboutMeComponent,
		//SpiralComponent,
		//PenComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MenuModule,
		RouterModule,
		MarioModule,
		CoffeeModule,
		LaptopModule,
		PostitModule,
		BookModule,
		IphoneModule,
		GlassesModule

	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
