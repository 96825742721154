<div (mouseenter)="marioStatus = true"  (mouseleave)="marioStatus = false">
	<div *ngIf="marioStatus">
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 91.405 123.304" enable-background="new 0 0 91.405 123.304"
			 xml:space="preserve">
	<g>
		<path fill="#271F20" d="M63.939,123.304c-0.918-0.354-1.912-0.283-2.854-0.524c-1.13-0.289-2.335-0.493-3.225-1.243
			c-0.739-0.623-1.473-0.806-2.379-0.847c-0.872-0.039-1.748-0.23-2.204-1.152c-0.113-0.229-0.312-0.078-0.477-0.049
			c-0.93,0.168-1.927,0.426-2.797,0.143c-1.396-0.457-2.589,0.122-3.861,0.366c-4.192,0.807-8.392,1.063-12.618,0.221
			c-1.455-0.29-2.413-1.003-2.61-2.544c-0.017-0.132-0.069-0.26-0.09-0.393c-0.139-0.904-0.428-1.725-0.888-2.549
			c-1.671-2.998-0.889-8.347,4.104-10.265c0.062-0.024,0.126-0.05,0.191-0.062c0.894-0.183,1.671-0.49,1.979-1.485
			c0.077-0.249,0.341-0.512,0.585-0.625c0.757-0.354,0.56-0.764,0.255-1.349c-2.305-4.433-4.352-8.977-5.456-13.875
			c-0.352-1.56-0.857-3.022-1.564-4.46c-2.316-4.705-2.413-9.558-0.732-14.482c0.175-0.509,0.268-0.997,0.197-1.555
			c-0.195-1.547,0.154-3.026,0.733-4.457c0.092-0.229,0.183-0.457,0.306-0.765c-0.603,0.086-1.146,0.171-1.691,0.239
			c-0.267,0.033-0.284,0.215-0.333,0.422c-0.201,0.854-0.513,1.66-1.021,2.388c-1.405,2.007-4.1,2.337-5.562,1.171
			c-0.361-0.289-0.469,0.043-0.637,0.198c-2.303,2.112-4.876,3.786-7.838,4.817c-1.792,0.625-3.629,0.982-5.541,0.768
			c-0.872-0.098-1.669-0.37-2.221-1.098c-0.166-0.219-0.359-0.229-0.583-0.242c-1.233-0.086-2.167-0.645-2.751-1.752
			c-0.071-0.137-0.108-0.296-0.276-0.358C1.018,67.512,0.418,66.687,0,65.689c0-0.538,0-1.076,0-1.615
			c0.247-0.492,0.491-0.984,0.742-1.474c0.054-0.104,0.078-0.199,0.031-0.315c-0.436-1.085,0.001-2.023,0.542-2.914
			c1.341-2.202,3.229-3.883,5.272-5.406c0.844-0.629,1.719-1.222,2.682-1.652c0.399-0.18,0.324-0.33,0.159-0.637
			c-0.978-1.816-1.276-3.713-0.561-5.695c0.488-1.352,1.44-2.236,2.804-2.688c1.112-0.367,2.074-0.158,2.866,0.725
			c0.794,0.885,1.617,1.744,2.365,2.667c0.903,1.114,2.164,1.532,3.445,1.952c0.202,0.066,0.286-0.021,0.396-0.127
			c1.938-1.854,5.014-1.673,6.557,1.633c0.246,0.528,0.459,0.586,0.979,0.484c3.92-0.764,7.873-1.268,11.868-1.423
			c0.168-0.007,0.355,0.038,0.542-0.124c-0.848-0.862-1.699-1.715-2.434-2.67c-1.464-1.905-2.662-3.93-2.871-6.398
			c-0.012-0.135-0.025-0.25-0.162-0.339c-1.588-1.023-2.045-2.714-2.501-4.372c-0.426-1.545-0.399-3.111,0.165-4.63
			c0.496-1.336,1.378-2.333,2.692-2.843c-2.014-3.924-2.028-7.914-0.498-11.927c2.245-5.89,6.401-10.115,11.796-13.206
			c2.353-1.347,4.871-2.239,7.576-2.564C54.547,0.121,54.628,0.107,54.651,0c0.135,0,0.27,0,0.404,0c0.09,0.085,0.179,0.085,0.27,0
			c0.986,0,1.974,0,2.961,0c1.477,0.114,2.918,0.38,4.299,0.94c2.098,0.85,4.004,2.006,5.702,3.505
			c4.796,4.233,8.359,9.331,10.698,15.28c1.102,2.805,1.727,5.703,1.549,8.739c-0.069,1.176-0.505,2.229-1.126,3.201
			c-0.269,0.422-0.316,0.705,0.054,1.105c0.853,0.922,1.182,2.078,1.304,3.305c0,0.852,0,1.705,0,2.557
			c-0.337,2.666-1.635,4.873-3.428,6.775c-1.407,1.494-3.138,2.545-5.231,2.822c-0.554,0.074-0.752,0.229-0.647,0.844
			c0.111,0.655-0.195,1.236-0.782,1.602c-1.068,0.662-2.237,0.876-3.363,0.752c1.007,0.373,2.062,0.918,3.105,1.488
			c2.785,1.527,5.414,3.269,7.632,5.571c0.657,0.682,1.237,1.421,1.702,2.245c1.518,2.697,1.021,5.277-0.52,7.757
			c-1.105,1.779-2.644,3.159-4.318,4.383c-0.424,0.31-0.567,0.565-0.407,1.103c0.384,1.295-0.16,2.721-1.283,3.49
			c-0.452,0.309-0.716,0.676-0.865,1.213c-0.504,1.815-1.342,3.422-3.024,4.449c-0.484,0.295-0.995,0.523-1.541,0.652
			c-0.438,0.104-0.519,0.309-0.395,0.726c0.476,1.614,1.025,3.204,1.577,4.792c1.272,3.666,2.626,7.303,3.82,10.994
			c0.192,0.596,0.502,1.004,1.045,1.317c1.248,0.718,2.228,1.726,2.938,2.984c0.335,0.594,0.632,1.229,0.62,1.901
			c-0.013,0.758,0.33,1.239,0.837,1.73c1.225,1.188,2.044,2.613,2.239,4.336c0.165,1.473-0.099,2.861-0.974,4.088
			c-0.207,0.289-0.32,0.584-0.312,0.934c0.015,0.498-0.083,0.984-0.189,1.466c-0.317,1.429-1.085,2.457-2.516,2.992
			c-1.996,0.748-4.05,1.132-6.172,1.196c-0.195,0.006-0.403-0.067-0.586,0.067c-0.18,0-0.358,0-0.538,0
			c-0.135-0.102-0.269-0.102-0.404,0c-1.256,0-2.513,0-3.769,0c-0.18-0.108-0.358-0.108-0.539,0
			C64.299,123.304,64.119,123.304,63.939,123.304z"/>
		<path fill="#726D6D" d="M64.478,123.304c0.181-0.237,0.359-0.181,0.539,0C64.837,123.304,64.658,123.304,64.478,123.304z"/>
		<path fill="#726D6D" d="M68.785,123.304c0.136-0.173,0.27-0.206,0.404,0C69.055,123.304,68.921,123.304,68.785,123.304z"/>
		<path fill="#726D6D" d="M55.325,0c-0.091,0.145-0.18,0.187-0.27,0C55.146,0,55.234,0,55.325,0z"/>
		<path fill="#0066AE" d="M30.572,69.25c2.203,1.918,4.438,1.587,5.957,0.457c1.084-0.804,1.837-1.887,2.372-3.126
			c0.634-1.469,1.008-3.013,1.294-4.577c0.079-0.438,0.251-0.588,0.688-0.623c3.311-0.27,6.575,0.074,9.822,0.688
			c0.498,0.094,0.638,0.254,0.484,0.795c-0.58,2.051-0.837,4.131-0.184,6.223c0.683,2.191,2.025,3.775,4.352,4.204
			c2.252,0.415,4.071-0.556,5.576-2.163c1.053-1.127,1.567-2.539,1.969-4.078c1.081,1.222,1.835,2.561,2.4,4.002
			c0.191,0.487,0.329,0.477,0.646,0.121c0.354-0.399,0.75-0.761,1.178-1.188c0.295,0.828,0.344,1.631,0.427,2.416
			c0.403,3.779-0.269,7.365-2.053,10.722c-0.323,0.606-0.352,1.125-0.125,1.772c2.045,5.855,4.279,11.644,6.231,17.531
			c0.118,0.355,0.353,0.465,0.659,0.58c1.274,0.477,2.249,1.285,2.74,2.596c0.051,0.137,0.189,0.287,0.033,0.426
			c-0.12,0.107-0.251,0.013-0.372-0.04c-5.1-2.251-10.144-1.616-15.158,0.299c-1.659,0.633-3.23,1.444-4.708,2.433
			c-0.586,0.391-0.849,0.275-0.943-0.455c-0.13-0.986,0.194-1.867,0.751-2.654c0.245-0.345,0.312-0.631,0.17-1.038
			c-1.862-5.398-3.827-10.759-5.976-16.051c-0.429-1.058-1.086-1.628-2.285-1.745c-1.69-0.166-3.351-0.574-4.962-1.146
			c-0.223-0.08-0.445-0.232-0.743-0.102c0.169,0.492,0.563,0.779,0.955,1.053c1.448,1.013,3.084,1.525,4.82,1.719
			c0.45,0.051,0.729,0.154,0.902,0.607c2.027,5.316,3.919,10.676,5.455,16.157c0.099,0.352,0.081,0.634-0.127,0.946
			c-0.34,0.512-0.525,1.113-0.543,1.713c-0.021,0.706-0.433,0.862-0.983,0.918c-0.768,0.078-1.518-0.036-2.255-0.258
			c-1.815-0.547-3.472-1.441-5.113-2.361c-1.849-1.036-3.757-1.9-5.889-2.163c-0.108-0.013-0.212-0.063-0.382-0.116
			c0.344-0.568,0.848-0.899,1.377-1.164c0.421-0.209,0.393-0.379,0.193-0.759c-2.681-5.116-4.879-10.424-6.163-16.073
			c-0.206-0.906-0.497-1.748-0.911-2.571c-1.66-3.305-2.532-6.787-2.268-10.51C29.965,71.506,30.197,70.379,30.572,69.25z"/>
		<path fill="#FACDB1" d="M55.934,44.135c0.324-0.361,0.603-0.937,0.988-1.02c0.335-0.072,0.771,0.451,1.194,0.643
			c2.161,0.971,4.565-0.217,5.146-2.559c0.117-0.475,0.278-0.598,0.761-0.524c1.141,0.173,2.062-0.286,2.605-1.271
			c0.959-1.739,0.975-3.556,0.149-5.358c-0.28-0.611-0.402-0.582-0.873-0.132c-1.969,1.884-4.372,2.822-7.032,3.188
			c-0.362,0.05-0.389-0.065-0.379-0.445c0.029-1.068,0.351-1.614,1.54-1.879c1.693-0.375,2.698-1.715,3.18-3.354
			c0.604-2.063,0.658-4.166,0.025-6.233c-0.518-1.687-1.481-2.988-3.409-3.172c-1.617-0.153-2.869,0.55-3.833,1.804
			c-1.009,1.313-1.338,2.859-1.451,4.469c-0.011,0.156-0.015,0.312-0.025,0.53c-0.566-0.219-1.088-0.442-1.625-0.621
			c-0.661-0.22-1.344-0.371-2.043-0.364c-0.498,0.004-0.683-0.137-0.658-0.685c0.06-1.315-0.218-2.577-0.751-3.789
			c-1.336-3.03-4.336-2.573-5.76-0.546c-1.277,1.816-1.528,3.887-1.487,6.023c0.012,0.602,0.045,1.209,0.188,1.803
			c0.041,0.17,0.118,0.363,0.009,0.526c-0.685,1.021-0.882,2.179-0.917,3.419c-1.088-0.418-2.015-0.994-2.909-1.631
			c-0.205-0.146-0.243-0.287-0.094-0.498c0.297-0.421,0.525-0.906,0.885-1.26c0.521-0.514,0.677-1.094,0.705-1.795
			c0.085-2.083,0.244-4.16,0.701-6.205c0.133-0.592,0.433-1,0.929-1.342c0.074,0.24,0.141,0.453,0.243,0.785
			c0.677-1.697,1.65-2.98,3.576-2.975c1.781,0.006,3.008,1.113,4.275,2.427c-0.072-0.569-0.103-0.996-0.187-1.411
			c-0.095-0.459-0.219-0.915-0.369-1.359c-0.144-0.419-0.338-0.82-0.525-1.267c3.108-0.599,6.131-0.409,9.189,0.503
			c-0.955,1.196-1.833,2.367-2.114,4.033c1.675-1.39,3.397-2.343,5.497-1.969c2.219,0.396,3.187,2.13,3.971,4.06
			c0.351-0.297,0.254-0.763,0.511-1.083c0.695,0.664,1.363,1.361,1.941,2.129c0.881,1.168,1.997,1.78,3.477,1.598
			c0.374-0.047,0.495,0.093,0.602,0.428c0.393,1.229-0.695,3.016-2.125,3.443c-1.912,0.572-2.413,2.172-1.169,3.807
			c0.171,0.225,0.118,0.383,0.074,0.607c-0.168,0.861-0.094,1.697,0.363,2.468c0.693,1.171,1.854,1.2,2.549,0.041
			c0.321-0.535,0.551-1.124,0.816-1.69c0.458-0.984,1.034-1.878,1.85-2.613c1.45-1.309,4.007-1.018,4.636,1.334
			c0.378,1.417,0.546,2.84,0.098,4.242c-1.167,3.649-3.524,6.127-7.306,7.088c-1.107,0.281-2.234,0.181-3.308-0.223
			c-0.347-0.132-0.508-0.054-0.724,0.213c-1.917,2.352-4.295,4.043-7.217,4.924c-0.276,0.084-0.46,0.26-0.67,0.423
			c-2.961,2.28-6.251,3.273-9.957,2.413c-2.167-0.502-3.817-1.82-5.104-3.605c-0.24-0.334-0.519-0.598-0.855-0.831
			c-2.658-1.841-4.916-4.04-6.232-7.063c-0.654-1.504-0.956-3.079-0.825-4.717c0.02-0.238-0.174-0.722,0.308-0.697
			c0.485,0.023,0.272,0.461,0.277,0.744c0.002,0.157-0.028,0.314-0.029,0.471c-0.021,2.375,1.871,3.727,4.097,2.882
			c0.528-0.2,0.72-0.265,0.858,0.414c0.292,1.435,1.732,2.578,3.322,2.709c0.535,0.042,0.719,0.263,0.824,0.75
			c0.46,2.124,1.212,4.125,2.661,5.797c1.411,1.626,3.271,1.761,4.905,0.341c1.587-1.379,2.382-3.217,2.898-5.198
			c0.118-0.454,0.177-0.923,0.261-1.386c0.092-0.498-0.112-0.724-0.619-0.668C56.264,44.225,56.092,44.26,55.934,44.135z"/>
		<path fill="#E52F35" d="M74.074,32.24c-0.49-3.224-1.294-6.377-2.546-9.418c-1.433-3.481-3.846-6.071-7.069-7.926
			c-0.68-0.391-0.936-0.718-0.822-1.577c0.597-4.519-2.84-8.324-6.706-9.152c-4.209-0.901-8.189,1.01-9.749,4.705
			c-0.414,0.981-0.646,2.011-0.595,3.077c0.025,0.491-0.135,0.689-0.602,0.834c-2.791,0.869-5.252,2.285-7.21,4.495
			c-1.071,1.209-1.868,2.571-2.244,4.157c-0.438,1.854,0.083,2.623,1.953,2.841c-0.39,1.008-0.691,2.033-0.858,3.095
			c-0.022,0.147,0.037,0.419-0.202,0.429c-0.214,0.008-0.424-0.083-0.54-0.326c-0.351-0.73-0.603-1.494-0.804-2.275
			c-1.02-3.958-0.199-7.672,1.698-11.154c3.345-6.135,8.421-10.184,15.209-11.906c4.495-1.142,8.654-0.12,12.443,2.51
			c5.204,3.613,8.778,8.516,11.29,14.255c1.127,2.575,1.98,5.236,2.228,8.057c0.132,1.514-0.116,2.949-0.965,4.243
			c-0.157,0.237-0.297,0.515-0.677,0.418c-1.05-0.263-1.989,0.071-2.899,0.55C74.311,32.223,74.22,32.303,74.074,32.24z"/>
		<path fill="#FFFFFF" d="M19.355,53.805c0.26-0.727,0.429-1.465,0.786-2.136c0.628-1.184,1.441-2.172,2.798-2.54
			c1.261-0.342,2.656,0.394,3.137,1.596c0.11,0.279,0.01,0.356-0.229,0.413c-0.52,0.122-1.037,0.255-1.587,0.39
			c-0.017-0.246-0.033-0.482-0.051-0.735c-2.334,2.044-2.452,10.046,1.588,11.804c-0.071-0.775,0.041-0.84,1.352-0.77
			c-0.509,2.438-3.189,3.467-5.233,2.018c-0.88-0.625-1.437-1.502-1.988-2.613c-0.131,1.145,0.158,2,0.647,2.801
			c0.165,0.271,0.193,0.439-0.075,0.671c-3.119,2.7-6.585,4.704-10.72,5.37c-0.669,0.107-1.342,0.108-2.001-0.082
			c-0.927-0.268-1.242-0.886-0.933-1.803c0.24-0.711,0.771-1.199,1.296-1.685c0.729-0.675,1.56-1.202,2.462-1.614
			c0.187-0.087,0.445-0.1,0.267-0.443c-1.304-2.5-1.847-5.157-1.642-8.037c-0.271,1.094-1.246,1.086-2.012,1.392
			c-1.713,0.687-3.296,1.589-4.765,2.85c-0.096-0.64,0.204-1.058,0.471-1.47c0.666-1.026,1.552-1.854,2.475-2.643
			c1.419-1.211,2.938-2.275,4.617-3.098c0.824-0.403,0.942-0.324,1.019,0.576c0.104,1.229,0.596,2.235,1.812,2.98
			c-0.319-0.86-0.676-1.605-0.595-2.406c0.118-1.17,0.026-2.025-1.052-2.961c-1.408-1.223-1.528-3.121-0.92-4.932
			c0.21-0.625,0.631-1.128,1.206-1.486c1.074-0.669,1.827-0.541,2.634,0.43c0.63,0.759,1.278,1.503,1.93,2.242
			c0.802,0.914,1.732,1.623,2.98,1.803c0.577,0.083,0.684,0.342,0.464,0.906C19.09,51.629,19.003,52.714,19.355,53.805z"/>
		<path fill="#9A4B28" d="M58.567,108.069c-0.707,0.843-1.313,1.587-1.217,2.729c0.416-0.274,0.525-0.678,0.765-0.977
			c1.432-1.795,3.182-3.027,5.511-3.416c3.188-0.533,6.341-0.62,9.388,0.614c2.242,0.907,4.119,2.29,5.036,4.653
			c0.571,1.475,0.359,2.948-0.145,4.402c-0.094,0.271-0.301,0.344-0.555,0.41c-2.214,0.578-4.467,0.936-6.74,1.146
			c-1.559,0.143-3.13,0.269-4.693,0.242c-3.503-0.059-6.963-0.448-10.29-1.664c-1.822-0.666-2.359-1.556-2.168-3.49
			c0.131-1.328,0.642-2.311,1.87-2.943C56.372,109.24,57.339,108.555,58.567,108.069z"/>
		<path fill="#E52F35" d="M64.656,62.238c-0.113,0.416,0.049,0.716,0.171,1.012c0.521,1.262,1.281,2.363,2.302,3.27
			c0.32,0.284,0.382,0.515,0.093,0.83c-0.074,0.082-0.124,0.188-0.194,0.273c-0.367,0.455-0.74,1.114-1.242,1.173
			c-0.635,0.074-0.603-0.808-0.911-1.237c-0.495-0.691-0.943-1.42-1.497-2.067c-0.175-0.205-0.239-0.406-0.219-0.67
			c0.302-4.003,0.796-7.974,1.965-11.833c0.099-0.328,0.132-0.6,0.606-0.398c4.551,1.922,8.688,4.443,11.997,8.186
			c1.484,1.68,1.589,3.556,0.709,5.508c-0.969,2.152-2.603,3.793-4.368,5.303c-1.109,0.947-2.297,1.791-3.654,2.363
			c-0.818,0.346-1.043,0.22-1.127-0.645c-0.141-1.43-0.378-2.838-0.808-4.213c-0.214-0.682,0.38-1.302,1.067-1.153
			c0.524,0.113,1.054,0.245,1.594,0.093c0.119-0.032,0.29-0.013,0.32-0.186c0.028-0.164-0.104-0.236-0.226-0.293
			C68.643,66.309,66.611,64.362,64.656,62.238z"/>
		<path fill="#9A4B28" d="M47.387,110.432c0.101-0.467-0.074-0.726-0.116-1.095c1.639,0.64,3.284,0.868,5.055,0.351
			c-0.136,0.619-0.26,1.17-0.375,1.721c-0.219,1.049-0.205,2.094,0.132,3.113c0.133,0.404-0.09,0.438-0.369,0.5
			c-1.772,0.387-3.571,0.57-5.373,0.754c-1.675,0.172-3.357,0.193-5.029,0.186c-2.546-0.01-5.108,0.035-7.632-0.4
			c-1.667-0.289-2.467-1.33-2.72-3.111c-0.642-4.514,3.046-6.747,6.013-6.933c3.13-0.194,5.665,1.229,8.111,2.912
			C45.89,108.984,46.604,109.656,47.387,110.432z"/>
		<path fill="#FACDB1" d="M49.522,40.801c-1.624-0.039-3.634-0.676-5.257-2.264c-2.264-2.217-2.234-5.459,0.041-7.643
			c3.197-3.073,9.098-2.673,11.851,0.803c2.042,2.578,1.621,5.649-1.038,7.576C53.757,40.26,51.971,40.807,49.522,40.801z"/>
		<path fill="#0066AE" d="M64.594,50.938c-0.447,1.37-0.911,2.694-1.245,4.062c-0.646,2.644-1.035,5.315-1.033,8.044
			c0.002,1.825-0.215,3.625-0.948,5.32c-0.835,1.93-2.292,3.146-4.36,3.516c-1.892,0.338-3.641-0.598-4.517-2.31
			c-0.853-1.663-0.815-3.425-0.467-5.188c0.626-3.168,2.094-5.986,3.724-8.733c0.159-0.27,0.392-0.409,0.668-0.525
			c1.41-0.597,2.726-1.362,3.962-2.263c0.238-0.174,0.485-0.305,0.771-0.395C62.344,52.087,63.451,51.503,64.594,50.938z"/>
		<path fill="#E52F35" d="M52.237,13.157c4.369,0.115,8.535,0.982,12.253,3.419c3.086,2.024,5.111,4.843,6.128,8.389
			c0.183,0.636,0.087,0.734-0.582,0.621c-0.515-0.088-0.904-0.391-1.229-0.77c-0.859-1.004-1.678-2.046-2.677-2.923
			c-0.253-0.223-0.253-0.515-0.271-0.799c-0.041-0.61-0.178-1.194-0.407-1.759c-1.033-2.554-3.587-3.43-5.95-2.005
			c-0.447,0.27-0.8,0.315-1.296,0.132c-3.127-1.155-6.341-1.282-9.597-0.697c-0.503,0.09-0.899,0.08-1.356-0.25
			c-1.556-1.119-3.574-0.683-4.704,0.955c-0.454,0.658-0.732,1.398-0.799,2.184c-0.047,0.542-0.251,0.916-0.662,1.243
			c-0.648,0.516-1.199,1.149-1.899,1.602c-0.367,0.239-0.769,0.442-1.182,0.14c-0.43-0.312-0.317-0.765-0.169-1.167
			c0.49-1.325,1.304-2.452,2.27-3.462c2.579-2.697,5.785-4.152,9.444-4.685C50.442,13.195,51.336,13.161,52.237,13.157z"/>
		<path fill="#E52F35" d="M39.24,50.828c-2.108,1.742-3.93,3.552-5.54,5.57c-0.712,0.893-1.392,1.812-1.971,2.797
			c-0.173,0.295-0.379,0.47-0.711,0.54c-1.71,0.361-3.438,0.589-5.183,0.701c-0.442,0.028-0.729-0.134-0.925-0.509
			c-1.087-2.067-1.263-4.258-0.887-6.525c0.06-0.357,0.325-0.434,0.612-0.522c2.015-0.625,4.089-0.974,6.166-1.299
			c2.654-0.415,5.327-0.681,8.015-0.778C38.902,50.799,38.989,50.812,39.24,50.828z"/>
		<path fill="#E52F35" d="M40.675,60.08c0.364-1.943,2.2-6.049,3.578-7.999c2.514,3.442,5.855,4.721,9.997,3.773
			c0.102,0.184-0.021,0.279-0.082,0.381c-0.826,1.362-1.555,2.774-2.166,4.247c-0.104,0.25-0.148,0.484-0.549,0.369
			C47.938,59.84,44.356,59.676,40.675,60.08z"/>
		<path fill="#F6AC6C" d="M77.515,117.627c-0.163,1.426-0.656,2.458-2.156,2.908c-3.104,0.934-6.261,1.298-9.481,1.205
			c-2.109-0.061-4.2-0.277-6.215-0.963c-0.855-0.29-1.677-0.64-2.262-1.372c-0.109-0.135-0.225-0.206-0.399-0.179
			c-0.088,0.013-0.18,0.014-0.269,0.016c-1.944,0.034-2.334-0.377-2.19-2.393c3.657,1.514,7.489,2.057,11.391,2.111
			C69.82,119.018,73.661,118.551,77.515,117.627z"/>
		<path fill="#0066AE" d="M30.781,67.455c0.074,0.02,0.153,0.018,0.191,0.057c1.318,1.355,3.089,0.951,4.224-0.105
			c1.856-1.729,2.631-3.843,2.005-6.381c-0.42-1.706-1.861-2.824-3.71-1.877c-0.103,0.054-0.202,0.096-0.346,0.006
			c0.714-1.117,1.574-2.117,2.456-3.095c1.79-1.987,3.737-3.803,5.888-5.401c0.434-0.321,0.761-0.393,1.154,0.012
			c0.153,0.158,0.352,0.279,0.545,0.391c0.244,0.142,0.293,0.268,0.107,0.52c-2.565,3.504-4.01,7.463-4.819,11.699
			c-0.304,1.587-0.865,3.108-1.866,4.422c-0.702,0.921-1.633,1.452-2.774,1.601C32.467,69.479,31.454,68.861,30.781,67.455z"/>
		<path fill="#FFFFFF" d="M49.211,11.977c-0.544,0.115-1.088,0.229-1.636,0.346c-0.347-3.178,2.098-6.331,5.424-7.04
			c4.473-0.953,8.492,1.662,9.396,6.127c0.129,0.635,0.188,1.285,0.164,1.938c-0.013,0.342-0.087,0.499-0.479,0.312
			c-0.583-0.278-1.193-0.5-1.791-0.747c-0.094-0.645-0.504-1.14-0.813-1.679c-0.767-1.341-1.565-2.665-2.324-4.01
			c-0.258-0.456-0.504-0.51-0.879-0.162c-0.459,0.425-0.935,0.833-1.414,1.237c-0.303,0.253-0.532,0.252-0.792-0.06
			c-0.388-0.465-0.743-0.955-1.115-1.43c-0.178-0.229-0.308-0.283-0.498,0.001c-1.049,1.562-2.044,3.158-3.09,4.722
			C49.276,11.665,49.256,11.826,49.211,11.977z"/>
		<path fill="#F6AC6C" d="M52.752,116.997c0.067,1.158,0.068,1.175-1.107,1.241c-0.873,0.05-1.751,0.045-2.565-0.35
			c-0.134-0.064-0.229-0.079-0.369-0.012c-1.753,0.842-3.653,1.037-5.548,1.191c-3.296,0.267-6.584,0.188-9.839-0.481
			c-0.693-0.144-1.377-1.329-1.163-1.982c0.065-0.201,0.194-0.135,0.314-0.117c4.102,0.606,8.224,0.552,12.347,0.347
			c2.349-0.118,4.693-0.305,6.987-0.879c0.873-0.218,0.873-0.214,0.923,0.707C52.738,116.773,52.746,116.886,52.752,116.997z"/>
		<path fill="#E52F35" d="M49.211,11.977c-0.086-0.133-0.163-0.259-0.054-0.425c1.166-1.774,2.326-3.551,3.509-5.356
			c0.535,0.682,1.048,1.302,1.521,1.953c0.204,0.282,0.334,0.254,0.564,0.046c0.564-0.51,1.159-0.987,1.725-1.498
			c0.242-0.219,0.379-0.308,0.589,0.059c1.064,1.865,2.153,3.715,3.241,5.568c0.124,0.212,0.2,0.398-0.016,0.589
			c-0.925-0.247-1.85-0.493-2.774-0.739c-0.449-0.696-0.72-1.481-1.079-2.223c-0.227-0.469-0.406-0.439-0.711-0.103
			c-0.239,0.266-0.493,0.523-0.77,0.751c-0.636,0.524-0.872,0.505-1.392-0.118c-0.171-0.207-0.346-0.412-0.514-0.622
			c-0.273-0.342-0.432-0.353-0.646,0.075c-0.31,0.615-0.505,1.301-1.016,1.805C50.662,11.818,49.937,11.897,49.211,11.977z"/>
		<path fill="#FCE90D" d="M30.942,64.773c0.035-1.752,0.738-3.212,2.025-4.37c1.354-1.217,2.895-0.692,3.24,1.092
			c0.383,1.97-0.206,3.665-1.567,5.106c-0.212,0.223-0.477,0.41-0.745,0.563c-1.18,0.679-2.354,0.224-2.779-1.065
			C30.975,65.672,30.929,65.227,30.942,64.773z"/>
		<path fill="#E52F35" d="M55.562,47.389c-0.44,1.205-1.029,2.25-1.87,3.13c-1.507,1.575-3.563,1.159-4.735-0.816
			c-0.444-0.75,0.415-2.079,1.288-2.046C52.007,47.723,53.766,47.747,55.562,47.389z"/>
		<path fill="#FFFFFF" d="M8.687,61.922c0.015-0.007,0.078,0.047,0.12,0.133c0.029,0.061,0.046,0.127,0.07,0.189
			c0.26,0.641,0.789,1.322,0.7,1.911c-0.084,0.547-1.023,0.699-1.539,1.101c-1.084,0.844-2.15,1.697-2.66,3.037
			c-0.07,0.186-0.172,0.226-0.352,0.223c-0.931-0.019-1.673-0.93-1.486-1.855c0.121-0.606,0.426-1.131,0.817-1.598
			c1.096-1.31,2.448-2.285,3.992-3.002C8.431,62.022,8.515,61.992,8.687,61.922z"/>
		<path fill="#FFFFFF" d="M56.809,30.66c-0.48-0.418-0.875-0.771-1.284-1.108c-0.132-0.11-0.197-0.222-0.2-0.394
			c-0.038-1.844,0.303-3.576,1.545-5.014c0.865-1.004,1.967-1.356,3.268-1.012c1.31,0.346,1.959,1.291,2.364,2.635
			c-0.164-0.129-0.229-0.174-0.288-0.227c-1.316-1.158-2.882-1.057-4.013,0.289c-0.964,1.148-1.285,2.535-1.389,3.989
			C56.794,30.064,56.809,30.312,56.809,30.66z"/>
		<path fill="#FFFFFF" d="M43.193,30.244c-0.242-2.256-0.312-4.379,0.827-6.346c0.167-0.289,0.368-0.563,0.595-0.807
			c1.343-1.429,3.188-1.093,4.09,0.794c-0.77-0.177-1.475-0.139-2.091,0.358c-0.593,0.477-0.986,1.088-1.249,1.791
			c-0.302,0.805-0.427,1.639-0.4,2.498c0.007,0.205,0.112,0.442-0.165,0.588C44.248,29.41,43.756,29.791,43.193,30.244z"/>
		<path fill="#FFFFFF" d="M8.413,58.521c-0.082,0.763-0.073,1.389,0.059,2.004c0.078,0.365-0.08,0.488-0.402,0.619
			c-1.658,0.67-3.125,1.635-4.379,2.916c-0.487,0.499-0.92,1.036-1.265,1.645c-0.077,0.138-0.075,0.415-0.299,0.359
			c-0.144-0.035-0.281-0.221-0.359-0.372C1.462,65.1,1.507,64.485,1.707,63.87c0.141-0.432,0.369-0.816,0.655-1.163
			C3.957,60.779,6.034,59.55,8.413,58.521z"/>
		<path fill="#9A4B28" d="M72.394,29.92c0.317,1.001,0.532,1.954,0.698,2.92c0.043,0.245-0.066,0.424-0.216,0.605
			c-0.594,0.717-0.972,1.545-1.262,2.424c-0.17,0.512-0.401,1.001-0.825,1.359c-0.241,0.205-0.497,0.393-0.848,0.227
			c-0.321-0.152-0.441-0.428-0.479-0.753c-0.067-0.579,0.078-1.114,0.368-1.606c0.169-0.286,0.172-0.447-0.169-0.617
			c-0.528-0.262-0.767-0.749-0.725-1.33c0.042-0.572,0.513-0.717,0.968-0.881C71.037,31.854,71.697,30.912,72.394,29.92z"/>
		<path fill="#FFFFFF" d="M71.047,78.27c-0.332,2.006-1.404,3.318-3.411,4.006c0.471-1.191,0.869-2.295,1.147-3.436
			c0.272-1.113,0.413-2.244,0.516-3.383c0.012-0.137-0.103-0.434,0.225-0.346c0.997,0.27,1.771-0.285,2.59-0.673
			c0.26-0.124,0.511-0.267,0.769-0.4c0.653,1.599-0.55,2.99-3.249,3.802C70.021,78.176,70.021,78.176,71.047,78.27z"/>
		<path fill="#FACDB1" d="M35.356,38.424c-1.702-1.808-2.243-5.385-1.175-7.471c0.31-0.605,0.714-1.137,1.303-1.5
			c0.387-0.24,0.797-0.398,1.27-0.307c0.456,0.086,0.71,0.273,0.627,0.813s-0.064,1.097-0.09,1.651
			c-0.074-0.016-0.128-0.01-0.147-0.033c-0.627-0.766-0.651-0.781-1.187,0.094c-0.86,1.41-1.182,2.893-0.403,4.453
			c0.114,0.23,0.075,0.418,0.036,0.641C35.499,37.293,35.438,37.824,35.356,38.424z"/>
		<path fill="#00ACD4" d="M60.484,33.701c-0.421,0.317-0.84,0.381-1.267,0.396c-0.445,0.016-0.753-0.131-0.868-0.638
			c-0.123-0.544-0.393-1.044-0.678-1.528c-1.028-1.752-0.326-5.144,1.315-6.352c0.783-0.576,1.624-0.645,2.458-0.156
			c0.979,0.573,1.351,1.482,1.301,2.705c-0.258-0.377-0.432-0.682-0.654-0.947c-0.759-0.904-1.807-0.944-2.614-0.062
			c-1.131,1.236-1.236,2.729-0.954,4.291C58.724,32.511,59.177,33.417,60.484,33.701z"/>
		<path fill="#FFFFFF" d="M51.282,46.838c-0.826,0.01-1.658,0.059-2.47-0.138c-0.606-0.146-1.149-0.394-1.346-1.063
			c-0.087-0.3-0.066-0.471,0.344-0.371c2.522,0.614,5.058,0.391,7.592,0.074c0.228-0.027,0.597-0.324,0.653,0.062
			c0.047,0.325,0.097,0.823-0.461,0.974c-0.953,0.255-1.924,0.394-2.904,0.457C52.223,46.863,51.752,46.838,51.282,46.838z"/>
		<path fill="#FFFFFF" d="M51.39,11.74c0.398-0.842,0.798-1.684,1.237-2.614c0.489,0.566,0.924,1.038,1.319,1.539
			c0.226,0.286,0.381,0.286,0.635,0.036c0.524-0.515,1.077-1,1.675-1.551c0.395,1.061,1.067,1.938,1.26,3.024
			C55.495,11.712,53.448,11.649,51.39,11.74z"/>
		<path fill="#00ACD4" d="M49.231,25.793c-0.694-0.632-1.316-0.608-1.957,0.09c-0.403,0.441-0.62,0.979-0.696,1.565
			c-0.126,0.971-0.119,0.972-1.208,1.31c-0.121-1.391,0.143-2.658,1.007-3.77c0.208-0.267,0.458-0.489,0.759-0.648
			C48.298,23.721,49.23,24.367,49.231,25.793z"/>
		<path fill="#9A4B28" d="M67.686,50.031c-0.423-0.178-1.139,0.143-1.329-0.264c-0.156-0.336,0.577-0.547,0.857-0.877
			c0.23-0.27,0.503-0.505,0.767-0.746c0.309-0.283,1.388-0.209,1.643,0.104c0.425,0.521,0.208,1.247-0.455,1.539
			C68.707,49.992,68.201,49.917,67.686,50.031z"/>
		<path fill="#9A4B28" d="M39.451,24.51c-0.229,1.768-0.479,3.531-0.438,5.322c0.013,0.58-0.625,0.619-0.872,1.006
			c-0.064-2.293,0.277-4.489,1.194-6.58C39.567,24.252,39.513,24.379,39.451,24.51z"/>
		<path fill="#FFFFFF" d="M61.177,29.476c-0.063,0.407-0.049,0.933-0.617,0.948c-0.521,0.015-0.59-0.48-0.631-0.875
			c-0.056-0.541,0.29-1.062,0.648-1.006C61.094,28.625,61.111,29.068,61.177,29.476z"/>
		<path fill="#facdb1" d="M47.484,44.38c0.59-0.331,1.049-0.56,1.426-0.905c0.164-0.15,0.29-0.171,0.443,0.013
			c0.34,0.405,0.774,0.703,1.354,1.042C49.582,44.629,48.62,44.485,47.484,44.38z"/>
		<path fill="#FFFFFF" d="M47.988,27.574c0.083-0.326,0.015-0.816,0.495-0.811c0.45,0.006,0.481,0.477,0.495,0.814
			c0.022,0.533-0.46,0.25-0.698,0.35C47.961,28.062,48.014,27.761,47.988,27.574z"/>
		<path fill="#D9AA8E" d="M55.934,44.135c0.168,0.014,0.335,0.026,0.503,0.04c-0.16,0.134-0.366,0.156-0.54,0.106
			C55.684,44.219,55.837,44.165,55.934,44.135z"/>
		<path fill="#68311B" d="M39.451,24.51c-0.039-0.084-0.077-0.168-0.116-0.252c0.024-0.106,0.106-0.271,0.18-0.201
			C39.65,24.188,39.582,24.372,39.451,24.51z"/>
		<path fill="#271F20" d="M75.583,39.504c-0.783,0.089-1.412,0.336-1.986,0.734c-0.696,0.482-1.104,1.096-1.014,1.977
			c0.033,0.311,0.062,0.625,0.065,0.938c0.003,0.252,0.195,0.628-0.101,0.728c-0.354,0.121-0.439-0.293-0.551-0.541
			c-0.886-1.994-0.093-5.102,1.637-6.418c0.604-0.461,1.286-0.721,2.056-0.705c0.434,0.008,0.821,0.135,1.292,0.557
			c-1.912-0.143-3.014,0.712-3.618,2.371C74.121,39.058,74.896,38.732,75.583,39.504z"/>
		<path fill="#271F20" d="M61.248,65.253c0.021,3.11-2.939,5.593-5.824,4.892c-1.411-0.344-2.298-1.27-2.686-2.639
			c-0.581-2.053-0.171-3.909,1.315-5.463c1.222-1.275,2.716-1.723,4.405-1.107c1.71,0.621,2.521,1.979,2.784,3.713
			C61.273,64.846,61.248,65.052,61.248,65.253z"/>
		<path fill="#271F20" d="M33.033,66.324c1.146-1.77,1.945-3.584,1.081-5.744c0.788,0.533,1.062,1.292,1.051,2.166
			C35.143,64.335,34.296,65.455,33.033,66.324z"/>
		<path fill="#FCE90D" d="M53.394,65.791c0.027-1.969,1.241-3.626,2.944-4.018c1.347-0.311,2.535,0.129,3.247,1.305
			c0.858,1.416,1.001,2.912,0.196,4.409c-0.849,1.583-2.456,2.337-3.972,1.918C54.285,68.984,53.369,67.613,53.394,65.791z"/>
		<path fill="#271F20" d="M58.339,63.357c1.316,1.818,0.383,4.694-1.618,5.164C58.131,67.077,58.52,65.396,58.339,63.357z"/>
	</g>
</svg>
	</div>
	<div *ngIf="!marioStatus">
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 91.405 123.304" enable-background="new 0 0 91.405 123.304"
			 xml:space="preserve">
	<g>
		<path fill="#e52f35" d="M64.127,65.645l3.672,4.223l3.67-2.197l3.798-1.393l2.786-0.59l-4.051-6.035l-4.684-4.725l-4.43-3.883
			l-4.896-1.096c0,0-18.735,1.096-18.651,1.139s-4.22,2.15-4.22,2.15l-3.334,1.477c0,0-2.277,1.479-2.277,1.604
			c0,0.127-1.055,1.354-1.055,1.354l-1.394,2.023l-1.56,1.352l-2.785,1.352l-2.617,0.293c0,0-2.489,0.211-2.32,0.168
			c0.168-0.041,1.35,2.658,1.35,2.658l3.377,3.25l2.954,1.562l2.025,0.336l31.225-0.59L64.127,65.645z"/>
		<path fill="#facdb1" d="M36.108,30.115l-2.53,0.506l-2.364,1.436l-1.182,2.531l0.338,3.293l1.435,3.291l2.363,3.121l0.506,1.941
			l1.857,2.533l2.363,1.35l2.869,1.098l2.278,2.447l2.87,1.266l4.22,0.168l2.362-0.928l2.532-2.193l3.546-1.689l2.784-2.277
			l0.929-1.352l3.881,0.338l3.292-1.266l2.786-3.121l0.758-3.631l-0.59-2.953l-1.434-2.109l-2.363-1.098l-3.039,0.506l-2.532-4.557
			l-1.941-2.363l-8.523-4.557l-7.933-0.76l-4.473,0.168l-3.123-0.084l-1.266,3.545L36.108,30.115z"/>
		<path fill="#e52f35" d="M29.442,14.671l-0.423,2.109l0.929,2.87l2.279,3.039l4.049,3.039l1.013-2.703l0.76-1.856l2.448-0.168
			l1.095,0.337l2.364-0.253h3.039l2.531,0.676l4.727,0.504l0.844,1.35l1.352,1.436l2.615,1.35l1.773,0.254l1.265-0.254l2.195,2.871
			l2.279,4.051l2.701-0.508l2.362,0.594l1.604,1.688l0.589,1.436l2.195-1.857l1.266-3.715l-0.083-2.615l-1.689-2.533l-1.941-1.096
			l-2.869-0.76l-3.629-8.693l-5.064-7.427l-5.655-4.81l-5.739-2.11l-5.484,0.76l-3.039,1.013l-2.363,2.446L38.05,7.751l-1.266,4.135
			l-2.871,0.422l-2.699,0.759L29.442,14.671z"/>
		<path fill="#FFFFFF" d="M39.484,12.562l5.656,1.519l4.135,1.856l2.784,1.435l1.521-2.023l0.253-2.617l-1.014-3.293L50.54,7.161
			l-2.87-1.266l-3.208,0.254l-2.445,1.181l-1.773,2.363L39.484,12.562z"/>
		<path fill="#e52f35" d="M51.6,14.689l-1.631,1.35l-0.619-0.281c0,0-1.069-2.502-2.124-5.358c0,0-1.056,1.181-2.118,2.395
			c-0.2-0.36-0.717-1.382-1.16-2.311c0,0-0.676,1-1.295,2.629l-1.631-0.337c0,0,1.125-2.308,2.981-4.952
			c0,0,0.633,1.296,1.252,2.701c0,0,0.984-1.196,2.462-2.926C47.718,7.601,49.49,11.047,51.6,14.689"/>
		<path fill="#FFFFFF" d="M41.791,31.775l0.619-2.645l-0.057-2.475l-0.674-1.576l-1.408-0.281l-1.124,0.281l-0.957,1.408
			c0,0-0.564,1.855-0.507,2.023c0.056,0.17,0.056,1.971,0.056,1.971l2.588,0.338L41.791,31.775z"/>
		<path fill="#FFFFFF" d="M49.837,35.713l1.801-0.504l1.012-1.127l0.9-2.701l0.225-2.645l-0.338-2.025l-0.899-1.236L51.13,24.91
			l-1.518,0.115l-1.24,0.785L47.53,27.5l-0.619,1.969l-0.057,2.082c0,0,0.225,1.574,0.281,1.744
			c0.056,0.168,0.394,1.238,0.394,1.238l1.182,0.732L49.837,35.713z"/>
		<path fill="#00ACD4" d="M42.353,28.512l0.057-1.293c0,0-0.844-1.125-1.406-1.098s-1.295,0.619-1.688,1.969
			c-0.395,1.35-0.254,2.166-0.254,2.166l1.352,0.477l0.028-1.602l0.535-1.068l0.506-0.141L42.353,28.512z"/>
		<path fill="#00ACD4" d="M53.662,29.863l0.199-1.492l-0.169-0.76c0,0-0.422-1.098-1.688-1.154
			c-1.267-0.057-2.786,1.211-3.349,4.361c-0.562,3.152,1.492,5.092,2.42,4.781c0.929-0.309,1.464-1.996,1.464-1.996
			s-0.986-0.984-1.014-1.098c-0.027-0.111-0.14-1.771-0.14-1.939c0-0.17,0.225-1.465,0.393-1.492
			c0.17-0.027,0.761-0.279,0.761-0.279l0.675,0.645L53.662,29.863z"/>
		<path fill="#FFFFFF" d="M44.182,46.965l3.966-1.461l3.123-1.885l0.309,1.209l0.029,1.35c0,0-1.238,0.562-1.322,0.648
			c-0.085,0.082-2.139,1.096-2.279,1.096s-1.66,0.367-1.772,0.453c-0.113,0.082-1.181,0.225-1.181,0.225l-0.535-0.789L44.182,46.965
			z"/>
		<path fill="#e52f35" d="M51.328,49.246l-0.647,1.066l-0.873,0.789l-1.096,0.365l-1.209-0.084l-1.295-0.479l-0.787-1.293
			c0,0,0.449-0.789,0.449-0.873s1.154-0.59,1.154-0.59l1.883-0.732l1.604-0.871l1.014-0.535L51.328,49.246z"/>
		<path fill="#9A4B28" d="M64.1,28.484l-0.732,1.939l-0.984,1.295l-1.322,0.619l-1.576,0.393c0,0-0.844,0.451-0.928,0.592
			c-0.084,0.141-0.479,1.041-0.506,1.154c-0.027,0.111-0.057,0.9,0.141,1.07c0.195,0.166,0.703,0.73,0.73,0.84
			c0.029,0.115-0.393,1.154-0.421,1.24c-0.028,0.082-0.395,1.518-0.309,1.688c0.084,0.17,0.535,0.703,0.562,0.9
			c0.029,0.197,0.393,0.562,0.76,0.592c0.366,0.027,1.463-0.199,1.604-0.254c0.139-0.057,1.518-1.408,1.518-1.408l1.014-1.885
			l1.125-2.109l1.885-1.715c0,0-1.547-2.645-1.463-2.645C65.28,30.791,64.1,28.484,64.1,28.484"/>
		<path fill="#9A4B28" d="M63.341,46.74l-3.152,3.291l2.561,0.479c0,0,1.828,0.34,1.912,0.34c0.086,0,2.055-0.592,2.055-0.592
			l0.789-0.703L67.306,48.4l-0.73-1.377L63.341,46.74z"/>
		<path fill="#9A4B28" d="M51.722,107.533c0,0,0.816,2.758,0.843,2.842c0.028,0.084-0.365,2.418-0.365,2.504
			s-0.732,1.801-0.732,1.801l-1.603,0.477l-8.243,0.535l-8.775-0.479l-5.88-1.041c0,0-1.66-2.25-1.577-2.25
			c0.086,0-0.026-4.613,0.029-4.697c0.057-0.084,1.744-3.434,1.828-3.404c0.086,0.027,3.236-1.801,3.375-1.857
			c0.142-0.057,5.008,0.111,5.008,0.111s3.291,1.773,3.375,1.773c0.085,0,1.688,1.576,1.688,1.576s4.528,2.025,4.641,2.082
			c0.113,0.057,4.417,0.338,4.417,0.338L51.722,107.533z"/>
		<path fill="#9A4B28" d="M55.408,109.84l-0.48,2.645c0,0,0.253,2.195,0.31,2.277c0.056,0.086,1.323,1.973,1.323,1.973l4.276,1.432
			c0,0,7.342,0.506,7.426,0.535c0.084,0.027,6.273-0.506,6.273-0.506l3.094-0.871l2.109-2.785c0,0,0.535-3.178,0.508-3.012
			c-0.029,0.17-1.182-3.629-1.182-3.629l-2.251-2.531c0,0-4.303-1.969-4.36-1.857c-0.056,0.113-5.121,0.086-5.121,0.086
			l-3.459,1.377l-1.35,0.029c0,0-2.447,1.125-2.363,1.182c0.084,0.059-2.476,2.277-2.476,2.277l-1.181,1.125L55.408,109.84z"/>
		<path fill="#F6AC6C" d="M56.532,117.379c0-0.084,0.169,2.025,0.169,2.025s1.406,0.93,1.491,0.93c0.085,0,1.744,0.057,1.744,0.057
			s1.406,0.898,1.492,0.928c0.083,0.027,2.784,0.844,2.784,0.844s3.517,0.393,3.601,0.393s4.08-0.111,4.08-0.111
			s3.347-0.844,3.432-0.816c0.083,0.029,1.997-1.154,1.997-1.154s0.535-3.262,0.591-3.205c0.057,0.055-5.457,1.238-5.457,1.238
			l-5.964,0.166l-5.149-0.562l-4.865-1.236L56.532,117.379z"/>
		<path fill="#F6AC6C" d="M51.004,114.51l-2.531,0.887l-7.133,0.379c0,0-8.592-0.576-8.494-0.562
			c0.098,0.016-5.641-1.168-5.641-1.041c0,0.129,0.042,1.814,0.042,1.814s0.125,1.73,0.252,1.771
			c0.127,0.043,1.393,0.465,1.52,0.549c0.125,0.084,4.979,0.844,4.979,0.844s5.739-0.127,5.866-0.127
			c0.126,0,2.447-0.506,2.447-0.506l1.435-0.465l0.928,0.762l3.418-0.043l2.068-0.674l0.675-1.014L51.004,114.51z"/>
		<path fill="#FFFFFF" d="M30.538,54.252c0.127,0,0.718,1.477,0.76,1.604c0.043,0.127-0.801,2.488-0.801,2.488l-1.984,2.025
			l-2.742,1.689l-3.881,0.801l-3.082-0.336l-2.869-1.562l-0.717-1.855l-0.043-0.803l-3.04-3.248l-0.884-4.051l0.338-3.545
			l2.236-4.008l3.67-3.207l4.769-1.771l3.967,0.504l2.784,2.152l0.972,2.109l0.505,1.773l1.731,0.844l0.633,1.434
			c0.125,0,0.041,2.574,0.041,2.574l-1.688,3.166L30.538,54.252z"/>
		<path fill="#FFFFFF" d="M68.136,69.951l-0.97,1.855c0,0-0.042,1.645,0.083,1.688c0.127,0.043,1.309,1.604,1.309,1.604
			l-0.549,2.658l-0.548,2.615l0.378,3.209l1.604,2.533l2.405,0.463l1.688,3.164l2.574,2.068l2.279-0.043l1.435-0.971l0.547-0.674
			l2.702-0.508l1.73-1.264l0.675-0.678l1.266-0.168l1.182-0.973l0.717-1.559l0.295-1.055l0.673-0.297l0.803-1.266l0.338-2.699
			l-0.76-3.039l-2.575-3.334l-3.291-2.785l-1.604-0.676l-0.127-2.445l-0.801-1.354c0,0-1.646-0.209-1.772-0.25
			c-0.126-0.043-4.22,0.379-4.22,0.379l-3.84,1.434L68.136,69.951z"/>
		<path fill="#0066AE" d="M43.198,53.156l-2.152,2.236l-1.308,2.404l-0.801,2.025l5.274-0.168l6.035,0.379l1.688-3.375l1.772-3.039
			l2.658-1.898c0,0,2.659-1.225,2.701-1.352c0.042-0.125,1.645-0.338,1.645-0.338l-1.181,2.533l-0.506,2.617l-0.169,8.186
			c0,0,3.714,4.303,3.798,4.346c0.084,0.043,1.309-2.279,1.309-2.279l1.35,0.803L64.803,69.4l1.139,3.629
			c0,0,0.168,3.924,0.085,4.051c-0.085,0.127-1.351,3.418-1.351,3.418l5.781,17.766l3.039,1.814l0.633,3.461h-1.311l-4.893,0.295
			l-2.49,0.633l-3.081,0.633l-1.898,0.844l-2.068,1.52l-1.351,1.73l-1.729,0.379c0,0-1.308-0.506-1.267-0.674
			c0.042-0.17-0.253-1.646-0.127-1.771c0.127-0.127,1.141-2.617,1.141-2.617l-5.528-17.133l-1.097,1.479l2.152,13.334l1.393,1.896
			l0.168,1.816l-0.59,1.225l-2.321,0.715l-2.954,0.043l-2.321-0.928l-3.165-1.52l-2.912-2.32c0,0-5.275-1.309-5.148-1.309
			c0.127,0,0.254-1.982,0.381-2.025c0.125-0.043,1.055-1.604,1.055-1.604l0.76-0.211c0,0-1.521-15.402-1.395-15.402
			s-2.996-4.852-2.996-4.852l-0.842-5.613l0.717-4.178l-0.211-2.49l4.473-8.184l3.713-3.926l2.11-1.645l1.394-0.297L43.198,53.156z"
		/>
		<path fill="#FCE90D" d="M53.789,60.414c1.558,0.123,3.334,1.307,3.08,4.387c-0.252,3.082-2.574,3.627-4.051,3.627
			c-1.475,0-3.586-1.391-2.91-4.723C50.583,60.371,52.734,60.328,53.789,60.414"/>
		<path fill="#FCE90D" d="M34.632,58.936c1.086,0.264,2.279,1.52,1.224,4.346c-1.055,2.828-2.532,3.547-3.967,3.209
			c-1.434-0.34-2.025-2.406-1.182-4.727C31.552,59.441,33.407,58.641,34.632,58.936"/>
		<path fill="#271F20" d="M90.098,81.516c-0.029,0.52-0.482,1.283-0.856,1.727c-0.063-0.611-0.189-1.084-0.283-1.262
			c-0.253-0.479-0.535-0.873-0.506-0.281c0.028,0.592,0.057,2.109-0.646,3.488c-0.484,0.945-1.297,1.48-1.759,1.723
			c0.017-0.85-0.22-1.545-0.379-1.807c-0.273-0.447-0.423-0.479-0.423-0.029c0,0.451-0.027,2.109-1.517,3.404
			c-1.016,0.881-2.095,1.07-2.68,1.104c0.251-1.162,0.028-2.082-0.049-2.256c-0.113-0.254-0.534-1.096-0.508-0.199
			c0.029,0.902-0.393,3.742-3.122,3.855c-2.309,0.096-4.055-3.193-4.536-4.207c0.378-0.238,0.709-0.539,0.973-0.873
			c0.238,0.48,0.343,0.861,0.343,0.861l1.012-0.309c-0.393-2.252-2.25-3.35-2.25-3.35l-0.507,1.154
			c0.321,0.193,0.593,0.449,0.818,0.727c-0.365,0.381-1.199,1.035-2.407,0.707c-1.658-0.451-2.672-2.277-2.699-4.809
			c-0.029-2.533,0.926-5.584,2.363-6.668c0.575-0.436,0.709-0.549,0.612-0.598c-0.097-0.045-0.43-0.021-0.726,0.035
			c-0.242,0.045-0.781,0.205-1.363,0.623c-0.29-0.178-0.622-0.473-0.86-0.961c-0.249-0.518-0.313-1.193-0.106-1.732
			c0.34-0.883,1.424-2.436,4.891-3.838c3.134-1.266,7.397-2.172,8.607-0.225c0.502,0.809,0.354,1.396,0.237,1.66
			c-0.833-0.006-1.405,0.262-1.671,0.584c-0.423,0.506-0.168,0.484-0.168,0.484s2.997-0.801,6.708,3.08
			C90.351,77.213,90.141,80.799,90.098,81.516 M61.538,61.73l-0.823,1.15c0.022,0.014,1.04,0.822,2.521,2.471
			c-0.164,0.279-0.427,0.721-0.752,1.225c-0.702-0.736-1.901-2.078-3.182-3.871c0.001-0.367,0.001-0.732,0.002-1.09
			c0.013-2.49,0.038-5.041,0.399-6.781c0.378-1.832,1.036-3.172,1.479-3.916c1.08,0.203,2.679,0.697,4.457,1.861
			c3.065,2.014,8.175,7.473,11.291,12.355c-1.328,0.26-2.851,0.736-4.567,1.43c-1.826,0.738-3.221,1.504-4.214,2.344
			C65.031,64.371,61.701,61.85,61.538,61.73 M66.566,71.01c-0.107,0.277-0.179,0.543-0.226,0.795
			c-0.336-1.139-0.758-1.986-0.955-2.428c0.096-0.35,0.185-0.711,0.262-1.045c0.427,0.588,0.869,1.225,1.312,1.91
			C66.802,70.49,66.668,70.744,66.566,71.01 M69.489,98.621l0.171,0.447l0.468,0.088c0.018,0.002,1.962,0.379,2.813,1.789
			c0.347,0.574,0.468,1.268,0.368,2.062c-1.41-0.328-2.923-0.428-4.463-0.252c-1.526,0.174-2.905,0.598-4.11,1.16v-0.01
			c-3.951,0.578-6.562,3.164-7.623,4.336c-0.465,0.516-1.25,0.656-1.614,0.621c-0.226-0.025-0.43-0.191-0.608-0.498
			c-0.342-0.586,0.231-2.354,0.778-3.406l0.163-0.314l-0.109-0.338c-2.955-9.031-5.34-17.615-5.362-17.699l-0.082-0.238
			l-0.787,0.027c-3.887-0.264-6.478-1.354-7.616-1.914c-0.218-0.102-0.333-0.164-0.597-0.312c-0.249-0.107-0.153,0.178,0.053,0.539
			c0.147,0.186,0.302,0.387,0.522,0.594c0.961,0.902,2.691,1.939,5.868,2.48c0.142,1.283,0.894,7.82,1.949,14.555l0.051,0.312
			l0.241,0.203c0.458,0.387,1.629,1.639,1.556,2.787c-0.107,1.658-2.648,1.732-3.736,1.688c-2.041-0.086-3.405-0.746-5.056-1.703
			c-0.667-0.385-1.487-0.783-2.23-1.084c-1.25-1.383-3.101-2.816-5.579-3.389c-0.417-0.098-0.826-0.166-1.227-0.213
			c0.057-0.619,0.329-1.842,1.591-2.332l0.596-0.234l-0.064-0.637c-0.009-0.104-0.961-9.57-1.461-15.184l-0.027-0.297l-0.207-0.215
			c-0.199-0.205-4.659-5.342-3.187-12.691c0.37,0.205,0.79,0.344,1.229,0.395c2.898,0.328,4.727-1.746,5.402-3.883
			c0.153-0.484,0.385-1.287,0.508-1.793c0.218-0.898,0.476-1.963,1.067-3.57c5.07-0.215,8.816,0.049,10.418,0.201
			c-0.469,1.387-0.728,2.598-0.875,3.453c-0.383,2.229-0.273,4.57,1.008,6.195c0.661,0.836,1.846,1.518,3.066,1.646
			c3.582,0.375,5.768-1.422,6.358-4.67c0.104-0.572,0.15-1.453,0.171-2.451c1.612,2.178,2.729,3.393,2.798,3.457l0.643,0.602
			l0.501-0.727c0.419-0.611,0.788-1.195,1.055-1.637c0.082,0.098,0.166,0.201,0.249,0.303c-0.105,0.516-0.316,1.5-0.543,2.266
			l-0.095,0.326l0.153,0.301c0.026,0.053,2.661,5.342-0.16,10.518l-0.16,0.297l0.086,0.328
			C63.833,80.994,66.262,90.033,69.489,98.621 M78.636,109.002c1.235,3.469,0.083,6.635-1.435,7.637
			c-1.795,1.184-4.896,1.604-9.454,1.604c-4.365,0-7.428-0.506-9.663-1.309c-1.802-0.646-2.491-1.523-2.616-3.545
			c-0.101-1.658,0.365-2.594,0.558-2.902c0.649-0.17,1.26-0.662,1.889-1.359c0.709-0.783,1.727-2.236,3.836-3.268
			c-1.281,1.137-2.065,2.359-2.231,3.268c-0.062,0.289-0.056,0.668,0.085,0.422c1.04-1.697,4.653-4.613,9.378-5.152
			C73.442,103.889,77.482,105.764,78.636,109.002 M76.59,120.182c-0.376,0.393-2.474,1.398-7.757,1.479
			c-5.44,0.082-8.016-1.207-8.356-1.809l-0.356-0.602l-0.634,0.289c-0.019,0.01-0.481,0.217-1.155,0.07
			c-0.881-0.191-1.002-0.309-1.077-0.418c-0.1-0.234-0.127-1.006-0.136-1.586c0.149,0.059,0.302,0.115,0.46,0.172
			c2.424,0.869,5.605,1.518,10.169,1.518c3.991,0,7.109-0.266,9.289-1.141C76.917,119.113,76.736,119.969,76.59,120.182
			 M52.007,111.363c-0.168,1.984-1.143,2.57-1.979,2.967c-1.031,0.486-5.129,1.014-9.711,0.932
			c-8.691-0.162-12.74-1.496-13.274-1.98c-0.777-0.697-2.005-3.166-0.841-6.34c1.042-2.844,4.049-5.26,8.413-4.25
			c4.212,0.977,7.702,5.254,7.729,5.297c0.325,0.393,0.188-0.209,0.127-0.379c-0.053-0.211-0.243-0.643-0.573-1.191
			c0.181,0.115,0.369,0.23,0.573,0.346c1.73,1.002,2.945,1.924,5.4,2.027c1.449,0.061,2.626-0.1,3.503-0.482
			C51.684,108.875,52.127,109.967,52.007,111.363 M50.026,117.26c-0.306,0.305-1.188,0.701-2.669,0.816
			c-0.755,0.057-2.326,0.178-2.693,0.039c-0.143-0.053-0.343-0.311-0.427-0.469l-0.338-0.631l-0.609,0.369
			c-0.01,0.004-0.932,0.553-2.336,0.75c-1.912,0.275-5.278,0.381-8.487,0c-3.57-0.424-4.362-0.816-4.525-0.959
			c-0.113-0.127-0.321-0.82-0.287-2.154c3.057,0.711,8.981,1.186,12.609,1.252c5.999,0.109,8.667-0.309,10.062-0.699
			C50.292,116.338,50.162,117.09,50.026,117.26 M29.611,67.824c-0.175,0.646-0.335,1.322-0.465,1.984
			c-3.626,0.162-6.425-3.455-8.312-6.297c3.41,0.242,7.741-1.809,9.424-4.309c0.308-0.457,0.556-0.879,0.751-1.277
			c0.289,0.418,0.489,0.816,0.599,0.771c0.095-0.037,0.347-0.545,0.232-1.318c0.555-0.516,2.205-1.951,4.399-3.043
			c-1.172,1.102-2.35,2.49-3.304,4.199c-0.677,0.26-1.518,0.824-2.293,2.178c-0.692,1.211-0.891,2.332-0.817,3.27
			C29.271,65.146,29.244,66.758,29.611,67.824 M15.916,59.184c1.168,0.494,2.185,0.611,2.835,0.611c0.957,0,1.154-0.17,0.507-0.395
			c-0.647-0.225-3.263-1.322-4.584-2.504c-1.323-1.182-3.629-3.967-2.758-8.213c0.873-4.248,3.967-6.865,7.146-8.412
			c3.178-1.549,6.554-0.844,8.439,0.393c1.449,0.953,1.966,2.867,2.129,3.703c-0.562,0.078-1.125,0.281-1.658,0.568
			c-0.41-1.65-1.762-2.992-3.257-3.484c-1.632-0.533-2.785,0.029-2.814,0.143c-0.027,0.111,0.562,0.111,1.378,0.309
			c0.817,0.197,1.463,0.365,2.588,1.268c0.749,0.596,1.149,1.631,1.332,2.258c-0.594,0.439-1.114,0.973-1.499,1.527
			c-0.202-0.512-0.6-1.244-1.38-1.928c-1.407-1.24-3.994-1.494-4.783-1.352c-0.788,0.139-1.125,0.449-0.364,0.506
			c1.704,0.127,2.616,0.535,3.796,1.35c1.242,0.857,1.893,1.846,2.196,2.428c-0.64,1.58-0.652,3.109,0.314,3.99
			c-0.388,0.865-1.021,1.109-1.375,1.18c0.048-1.961-1.192-4.703-3.667-5.6c-2.562-0.928-3.855-0.254-3.828-0.113
			c0.029,0.141,1.408,0.227,3.264,1.039C21.733,49.271,23,51.27,23,52.648s-0.619,3.152-2.756,3.152
			c-1.517,0-1.943-1.047-2.063-1.656c0.963,0.02,1.27-0.52,1.275-0.596c0.007-0.098-0.1-0.098-0.606-0.184
			c-0.252-0.041-0.618-0.084-1.053-0.23c-1.009-0.342-1.857-0.795-1.857-0.795l-0.253,1.012c0,0,0.451,0.379,1.607,0.658
			c-0.191,1.941,1.634,2.988,3.484,2.857c1.652-0.113,2.723-1.088,3.138-2.512c1.469-0.242,2.097-1.406,2.311-1.941
			c0.03,0.012,0.062,0.025,0.092,0.039c1.856,0.703,3.517-0.676,3.884-1.379c0.364-0.703,0.168-0.646-0.62-0.143
			c-0.787,0.508-2.391,1.184-3.291,0.086c-0.901-1.098-0.085-3.01,1.294-4.191c1.376-1.184,3.122-1.771,4.246-0.535
			c1.126,1.238,0.675,3.545-0.337,5.176c-1.012,1.633-2.026,3.041-3.264,4.361c-1.238,1.32-3.066,2.729-3.037,2.869
			c0.039,0.199,1.405-0.141,3.233-1.463c0.653-0.473,1.356-1.197,2.026-2.029c0.032,0.428,0.016,1.051-0.197,1.748
			c-0.394,1.295-2.643,4.137-5.823,4.922C21.403,62.627,16.531,62.404,15.916,59.184 M31.627,61.346
			c0.663-1.158,1.44-1.863,2.244-2.045c0.684-0.152,1.164,0.117,1.339,0.24c0.643,0.449,1.051,2.119-0.054,4.422
			c-0.51,1.064-1.216,1.74-2.043,1.961c-0.655,0.174-1.28,0.027-1.584-0.184C30.86,65.275,30.293,63.674,31.627,61.346
			 M41.622,52.086c0.184,0.309,0.433,0.697,0.763,1.113c-0.904,0.809-2.508,2.527-3.711,5.377c-1.211,2.865-1.448,3.953-1.752,5.215
			c-0.123,0.506-0.229,0.941-0.371,1.383c-0.419,1.303-1.901,3.275-4.097,3.027c-0.599-0.068-1.032-0.307-1.323-0.725
			c-0.241-0.352-0.358-0.785-0.402-1.213c0.111,0.121,0.227,0.225,0.338,0.301c0.437,0.305,1.282,0.436,2.264,0.176
			c0.747-0.197,1.804-0.777,2.588-2.412c1.254-2.615,0.822-4.729-0.24-5.471c-0.277-0.193-0.646-0.35-1.035-0.443
			C37.312,54.377,40.507,52.609,41.622,52.086 M52.422,68.75c1.102,0.191,2.087-0.004,2.964-0.57
			c0.944-0.609,1.685-1.736,1.903-2.986c0.498-2.859-0.746-4.955-3.173-5.34c-1.043-0.166-2.01,0.033-2.793,0.568
			c-0.304,0.211-0.578,0.471-0.817,0.771c0.67-2.049,1.784-4.545,3.488-6.746c0.939-0.643,1.664-1.365,2.307-1.961
			c0,0-0.116,0.113,0.025-0.014c0.549-0.092,2.049-0.607,3.695-1.682c-0.465,0.674-1.066,1.861-1.472,3.816
			c-0.383,1.859-0.414,4.33-0.425,7.004c-0.009,2.049-0.019,3.984-0.213,5.045c-0.279,1.533-1.612,4.043-4.876,3.705
			c-0.903-0.092-1.955-0.477-2.588-1.236c-0.625-0.75-0.815-1.811-0.843-2.766C50.094,67.781,51.258,68.547,52.422,68.75
			 M50.244,64.1c0.178-1.363,0.816-2.432,1.632-2.992c0.584-0.402,1.287-0.539,2.085-0.416c1.877,0.299,2.826,2.012,2.416,4.365
			c-0.178,1.02-0.661,1.809-1.394,2.285c-0.673,0.434-1.633,0.621-2.49,0.473C50.827,67.523,49.981,66.131,50.244,64.1
			 M50.051,59.367c-1.428-0.217-5.515-0.73-10.357-0.305c0.069-0.164,0.14-0.334,0.214-0.51c1.023-2.42,2.286-3.918,3.062-4.684
			c1.082,1.109,2.736,2.188,5.171,2.23c1.644,0.031,2.965-0.25,4.059-0.689C51.266,56.752,50.569,58.105,50.051,59.367
			 M41.093,26.006c-0.32-0.033-0.657,0.086-0.945,0.334c-0.463,0.4-1.034,1.316-1.204,3.428c-0.238-0.068-0.489-0.133-0.75-0.188
			c0.032-2.031,0.413-3.475,1.091-4.094c0.312-0.285,0.688-0.398,1.153-0.348c0.439,0.047,0.776,0.234,1.029,0.572
			c0.123,0.162,0.225,0.359,0.308,0.586C41.591,26.148,41.366,26.033,41.093,26.006 M41.912,29.266c0,0.434-0.206,0.781-0.459,0.775
			c-0.254-0.006-0.461-0.361-0.461-0.795c-0.001-0.434,0.203-0.779,0.458-0.773C41.704,28.477,41.911,28.832,41.912,29.266
			 M41.489,27.406c-0.595-0.18-1.468,0.393-1.677,2.615c-0.001,0.016,0,0.025-0.001,0.039c-0.192-0.074-0.392-0.146-0.6-0.213
			c0.154-2.057,0.684-2.934,1.114-3.303c0.296-0.256,0.566-0.287,0.741-0.271c0.4,0.041,0.688,0.336,0.866,0.6
			c0.055,0.293,0.088,0.613,0.095,0.959C41.889,27.645,41.711,27.473,41.489,27.406 M51.204,46.877
			c-0.042,1.783-0.48,3.65-2.039,4.156c-0.703,0.229-1.349,0.18-1.977-0.146c-0.436-0.223-0.818-0.562-1.153-0.945
			c0.059-0.432,0.184-0.734,0.302-0.939c0.55-0.082,1.223-0.271,2.054-0.623C49.573,47.883,50.546,47.307,51.204,46.877 M48,47.445
			c-1.681,0.709-2.65,0.697-2.997,0.449c-0.125-0.088-0.305-0.359-0.457-0.645c2.646-0.504,5.614-2.441,6.474-3.039
			c0.06,0.404,0.119,0.906,0.155,1.455C50.651,46.043,49.491,46.82,48,47.445 M35.326,46.016c0.35-0.178,0.576-0.381,0.576-0.381
			s0.451,1.408,2.111,1.436c1.799,0.031,2.758-1.855,2.758-1.855s0.674,1.631,3.094,1.18c2.419-0.451,2.869-2.59,2.869-2.59
			s0.889,0.543,2.023,0.672c-1.978,1.188-5.074,2.713-7.087,2.086c-0.08-0.023-0.113,0.045-0.092,0.148
			c0.035,0.109,0.158,0.285,0.397,0.404c0.196,0.09,0.269,0.115,0.485,0.164c0.32,0.072,0.658,0.096,1.008,0.09
			c0.188,0.555,1.223,3.367,3.254,4.418c0.868,0.449,1.526,0.488,2.488,0.176c3.52-1.137,2.802-6.861,2.506-8.545
			c1.083-1.131,0.925-2.482,0.925-2.482c1.266-0.379,2.531-0.926,2.869-2.531c0.385-1.83-1.294-3.713-1.294-3.713
			c-2.508,3.328-6.738,4.232-8.565,4.473c0.161-0.266,0.298-0.521,0.408-0.76L44.784,38c-0.721,1.576-3.333,4.227-7.39,4.176
			c-4.085-0.055-6.038-1.73-6.42-5.213c-0.209-1.918,0.24-3.58,1.265-4.678c0.928-0.994,2.29-1.486,3.941-1.418
			c3.798,0.15,5.985,2.363,6.007,2.385l1.059-0.924c-0.037-0.037-0.325-0.371-0.866-0.801c0.128-0.393,0.277-0.973,0.423-1.82
			c0.157-0.926,0.417-3.219-0.593-4.557c-0.411-0.545-0.974-0.859-1.671-0.936c-0.735-0.078-1.368,0.117-1.88,0.586
			c-0.873,0.799-1.338,2.354-1.388,4.625c-0.125-0.016-0.251-0.029-0.38-0.041c0.087-3.623,1.274-6.809,1.485-7.348
			c0.254-0.053,0.543-0.076,0.873-0.061c2.139,0.111,3.208,2.588,3.208,2.588c0.074-0.371-0.025-1.557-0.389-2.762
			c2.367-0.293,4.771-0.027,6.047,0.168c-0.705,1.279-1.045,2.623-1.045,3.045c0,0,2.239-2.615,5.177-2.139
			c2.082,0.338,2.813,2.588,2.813,2.588c0.116-0.178,0.219-0.402,0.305-0.66c1.081,0.852,1.991,1.662,2.735,2.01
			c2.109,0.99,3.388,0.539,3.996,0.146c0.411,0.535,0.783,1.057,1.12,1.561c-0.032,0.23-0.431,2.857-1.742,3.02
			c-2.293,0.287-3.543,0.66-4.151,2.49c-0.099,0.293-0.142,0.572-0.142,0.826c0,0.785,0.402,1.387,0.862,1.793
			c-0.366,0.629-0.589,1.41-0.589,2.16c0,0.064,0.001,0.129,0.005,0.193c0.047,0.902,0.425,1.654,1.063,2.123
			c0.671,0.494,1.437,0.645,2.21,0.436c1.321-0.354,2.335-1.734,3.33-4c1.365-3.113,3.927-4.623,6.132-3.844
			c0.993,0.354,2.026,1.248,2.646,2.83c0.507,1.293,0.402,3.863-0.927,6.014c-2.948,4.76-8.304,3.438-8.53,3.379l-0.537-0.139
			l-0.296,0.467c-2.172,3.277-5.774,4.693-6.729,4.906l-0.01-0.004l-0.206,0.203c-1.465,1.359-3.334,3.232-7.211,3.121
			c-3.882-0.113-5.885-3.439-5.951-3.58l-0.148-0.32l-0.337-0.094C37.432,49.244,35.86,47.146,35.326,46.016 M29.761,15.726
			c0.108-0.668,0.496-1.243,1.152-1.706c1.653-1.168,4.708-1.449,8.383-0.775c7.139,1.31,13.813,4.713,19.297,9.843
			c1.034,0.969,1.939,1.945,2.726,2.895c-0.437,0.168-1.314,0.279-2.774-0.404c-0.661-0.312-1.528-0.984-2.626-1.838
			c-0.107-0.086-0.22-0.174-0.336-0.26c0.029-1.545-0.674-3.371-2.998-4.037c-0.998-0.283-1.853-0.096-2.571,0.354
			c-2.741-1.527-6.059-2.962-9.778-3.709c-0.233-0.046-0.479-0.089-0.731-0.116c-0.316-0.026-0.5-0.007-0.625,0.071
			c-0.105,0.064-0.018,0.108,0.089,0.139c4.183,1.133,7.527,2.702,10.22,4.294c-0.127,0.133-0.248,0.272-0.363,0.416
			c-1.11-0.198-4.169-0.656-7.19-0.226c-0.519-1.093-1.305-1.977-2.442-1.953c-1.966,0.039-2.381,2.555-2.463,3.921
			c-0.186,0.572-0.406,1.33-0.607,2.211C30.932,21.332,29.431,17.772,29.761,15.726 M52.271,16.663
			c-3.806-2.26-7.938-3.882-12.199-4.744c0.141-0.922,0.575-2.215,1.421-3.3c1.17-1.498,2.782-2.256,4.792-2.256
			c2.105,0,3.826,0.679,5.115,2.019c1.562,1.625,2.054,3.814,2.002,5.196C53.342,15.111,52.761,16.096,52.271,16.663 M59.392,35.93
			c-0.225-0.1-0.989-0.746-0.73-1.52c0.387-1.166,0.875-1.439,2.683-1.664c1.695-0.213,2.422-1.771,2.729-2.875
			c0.886,1.484,1.412,2.664,1.667,3.285c-0.951,0.791-1.79,1.963-2.466,3.506c-0.767,1.746-1.881,3.213-2.701,3.432
			c-0.322,0.088-0.773-0.01-1.068-0.227c-0.338-0.246-0.514-0.631-0.529-0.941c-0.042-0.768,0.278-1.596,0.643-1.928l0.562-0.73
			L59.392,35.93z M42.149,4.193c2.135-1.655,4.863-2.384,7.892-2.108c5.209,0.473,10.101,3.99,14.838,10.975
			c3.118,4.596,5.162,10.577,5.917,13.014c0.212,0.684,0.259,0.838,0.264,0.854c0.062,0.268,0.14,0.236,0.221,0.172
			c0.053-0.043,0.147-0.297,0.159-0.572c0.019-0.111,0.041-0.41,0.011-0.844c-0.024-0.312-0.062-0.695-0.125-1.139
			c1.042,0.148,4.103,0.877,4.937,4.629c0.704,3.168-0.97,5.217-2.094,6.281c-0.712-1.463-1.992-2.645-3.466-3.166
			c-1.347-0.479-2.636-0.43-3.805,0.125c-0.732-1.752-2.859-6.082-7.381-10.312C57.5,20.217,55.312,18.542,53,17.106
			c0.569-0.697,1.179-1.823,1.244-3.496c0.062-1.624-0.493-4.001-2.237-5.814c-1.432-1.489-3.414-2.276-5.723-2.276
			c-2.846,0-4.536,1.402-5.456,2.579c-0.928,1.187-1.426,2.579-1.591,3.663c-0.511-0.089-1.008-0.162-1.494-0.217
			C38.551,8.308,40.029,5.836,42.149,4.193 M66.753,49.484c-0.122,0.469-1.44,0.809-2.652,0.857
			c-1.115-0.441-1.979-0.613-2.574-0.678c0.706-0.604,1.397-1.318,2.006-2.15c0.477,0.078,1.253,0.174,2.184,0.16
			C66.201,48.055,66.936,48.777,66.753,49.484 M89.675,75.059c-1.771-2.656-4.431-4.914-6.203-5.547
			c-0.102-0.037-0.203-0.068-0.301-0.1c0.129-0.637,0.187-1.699-0.432-2.773c-0.718-1.248-2.093-1.816-4.079-1.723
			c-3.99-5.932-8.708-11.35-12.146-13.379c-0.17-0.1-0.335-0.193-0.498-0.283c0.856-0.236,1.672-0.672,1.879-1.477
			c0.227-0.871-0.151-1.646-0.63-2.238c2.051-0.322,4.419-1.332,6.081-4.014c1.646-2.66,1.689-4.934,1.353-6.504
			c1.139-0.973,4.263-3.609,3.259-8.125c-1.123-5.055-6.002-5.752-6.892-5.838c-0.583-2.839-1.93-7.027-4.896-11.349
			C61.127,4.353,55.754,0.752,50.066,0.234c-3.418-0.312-6.516,0.527-8.96,2.42c-2.455,1.904-4.043,5.021-4.899,8.773
			c-2.597-0.097-4.702,0.373-6.128,1.377c-0.951,0.671-1.522,1.618-1.688,2.645c-0.425,2.623,1.353,6.867,7.448,10.83
			c-0.157,0.947-0.273,1.982-0.298,3.062c-1.829,0.057-3.42,0.594-4.536,1.791c-1.368,1.465-1.975,3.598-1.71,6.014
			c0.203,1.844,0.796,3.291,1.785,4.352c-0.072,2.068,1.531,2.363,1.531,2.363s0.366,2.07,1.353,2.367
			c0.486,1.213,1.845,3.447,5.489,4.857c-1.627,0.672-5.025,2.238-7.823,4.559c0.004-0.578-0.128-1.088-0.336-1.562
			c0.857-1.236,1.586-2.566,1.973-3.627c0.789-2.166,0.607-4.193-1.491-5.627c-0.275-0.188-0.569-0.318-0.872-0.398
			c-0.49-3.303-2.307-5.791-6.301-6.492c-4.163-0.734-7.99,1.236-10.015,3.205c-2.024,1.969-4.36,5.26-4.36,9.172
			c0,3.91,2.111,6.582,3.966,7.904c0.171,0.121,0.342,0.232,0.511,0.338c-0.045,0.488-0.034,1.195,0.249,1.885
			c0.422,1.031,1.569,2.16,3.94,2.752c1.801,3.375,4.565,6.236,6.664,7.264c1.415,0.689,2.639,0.822,3.355,0.828
			c-0.044,0.4-0.072,0.783-0.072,1.133c0,5.521,3.206,9.598,4.023,10.533c0.438,4.885,0.997,12.164,1.213,14.369
			c-1.659,0.955-2.008,2.688-2.074,3.562c-3.818,0.207-6.537,2.693-7.55,5.459c-1.409,3.842,0.112,6.949,1.404,8.02
			c0.104,0.084,0.254,0.166,0.443,0.25c-0.029,1.229,0.092,3.016,0.75,3.582c0.442,0.377,1.411,0.826,5.254,1.281
			c3.331,0.396,6.84,0.285,8.84-0.002c0.999-0.143,1.785-0.43,2.276-0.648c0.191,0.215,0.447,0.436,0.767,0.557
			c0.551,0.207,1.493,0.178,3.271,0.041c1.591-0.123,2.875-0.562,3.52-1.209c0.581-0.58,0.782-2.453,0.812-3.299
			c0.896-0.688,1.434-1.867,1.609-3.381c0.188-1.662-0.416-3.156-0.889-4.021c0.41-0.463,0.644-1.047,0.689-1.764
			c0.004-0.07,0.008-0.141,0.008-0.209c0-1.73-1.346-3.205-1.918-3.756c-0.664-4.268-1.203-8.432-1.539-11.176
			c0.966,3.25,2.574,8.514,4.365,14.004c-0.321,0.676-0.927,1.986-0.927,3.25c0,0.498,0.008,0.951,0.241,1.354
			c0.229,0.395,0.516,0.711,0.844,0.936c-0.288,0.617-0.607,1.674-0.494,3.281c0.115,1.629,0.604,2.611,1.732,3.371
			c0.014,1.477,0.157,2.754,0.463,3.211c0.422,0.633,1.014,0.859,2.032,1.08c0.634,0.141,1.172,0.092,1.553,0.008
			c1.217,1.152,4.356,2.152,9.295,2.076c5.261-0.078,8.026-1.072,8.86-1.973c0.542-0.588,0.856-2.746,0.975-3.977
			c2.196-1.719,3.044-5.113,1.799-8.523c-0.85-2.33-2.908-4.18-5.525-5.189c0.078-0.346,0.125-0.682,0.125-1.014
			c0-0.885-0.236-1.75-0.713-2.443c-1.018-1.48-2.548-2.082-3.381-2.324c-2.791-7.504-5.213-15.357-5.653-16.963
			c1.02-2.004,1.477-3.992,1.477-5.738c0-0.256-0.013-0.504-0.028-0.748c0.337,0.543,0.741,0.887,1.144,1.084
			c-0.001,0.004-0.004,0.006-0.006,0.008c-0.446,0.652-0.895,2.018-1.06,3.223c-0.379,2.785-0.22,5.547,1.665,7.6
			c0.865,0.941,1.87,1.232,2.804,1.121c0.256,0.75,1.197,2.891,3.104,4.506c1.661,1.406,4.726,1.295,5.909-0.562
			c0.128-0.203,0.237-0.406,0.33-0.607c1.126,0.35,3.023,0.414,4.395-1.193c0.273-0.318,0.475-0.648,0.623-0.98
			c0.742-0.051,2.048-0.344,2.895-1.691c0.419-0.668,0.607-1.459,0.667-2.211c0.415-0.172,0.984-0.598,1.555-1.629
			C91.912,80.969,91.447,77.719,89.675,75.059 M83.98,73.998c-0.147,0.137,0.721,1.982,1.443,2.566
			c0.724,0.582,1.366,0.617,1.66,0.18c0.3-0.441,0.045-1.123-0.569-1.619C85.9,74.631,84.128,73.857,83.98,73.998 M81.495,75.807
			c-0.199,0.109,0.378,2.334,0.839,3.039c0.518,0.789,1.186,0.908,1.699,0.609c0.513-0.299,0.572-1.109,0.129-1.775
			C83.66,76.922,81.695,75.699,81.495,75.807 M78.691,77.031c-0.228,0.012-0.98,2.193-0.813,3.107
			c0.167,0.912,0.656,1.252,1.248,1.201c0.592-0.051,1.044-0.705,0.903-1.479C79.888,79.084,78.917,77.018,78.691,77.031
			 M65.089,42.604c0,0,0.281-0.9,1.322-2.4c0.455-0.205,1.425-0.529,2.572-0.236c0,0,1.051,0.348-0.111-0.398
			c-0.625-0.398-1.227-0.451-1.655-0.414c2.001-2.279,3.609-1.803,3.609-1.803c0.633,0-1.011-1.098-2.785-0.17
			c-1.772,0.93-2.744,2.777-2.996,4.305C64.71,43.531,65.089,42.604,65.089,42.604 M33.737,64.287
			c0.984-0.816,1.068-2.055,0.928-2.309c0,0-0.535,1.746-2.251,2.814C32.414,64.793,32.751,65.102,33.737,64.287 M53.748,66.594
			c0.577-0.141,1.214-0.895,1.364-1.674c0.152-0.781-0.06-1.338-0.243-1.449c0,0,0.061,1.086-0.545,1.951
			c-0.607,0.863-1.486,1.143-1.486,1.143S53.05,66.762,53.748,66.594 M53.403,28.984c-0.167-0.287-0.422-0.592-0.791-0.705
			c-0.731-0.227-1.885,0.307-2.306,2.645c-0.459,2.541,0.506,3.318,1.069,3.486c0.103,0.031,0.213,0.029,0.326,0.006
			c-0.426,0.418-0.921,0.652-1.478,0.695c-0.293-0.133-0.556-0.352-0.774-0.654c-0.635-0.873-0.826-2.268-0.541-3.93
			c0.535-3.123,2.093-4.066,3.264-3.926c0.376,0.043,0.651,0.197,0.851,0.377C53.246,27.48,53.395,28.133,53.403,28.984
			 M52.375,31.057c-0.318-0.021-0.551-0.408-0.52-0.867c0.03-0.457,0.311-0.809,0.63-0.787c0.317,0.021,0.55,0.408,0.52,0.865
			C52.976,30.725,52.693,31.08,52.375,31.057 M47.357,30.062c0.213-2.086,0.847-3.568,1.833-4.289
			c0.568-0.416,1.226-0.551,1.952-0.4c0.335,0.07,1.041,0.305,1.576,1.061c-0.152-0.062-0.319-0.111-0.509-0.137
			c-1.304-0.152-3.029,0.846-3.6,4.178c-0.299,1.744-0.087,3.223,0.594,4.16c0.128,0.176,0.268,0.326,0.422,0.455
			C48.431,34.916,46.935,34.184,47.357,30.062 M51.332,24.467c-0.981-0.205-1.914-0.014-2.691,0.557
			c-1.202,0.881-1.964,2.59-2.206,4.943c-0.378,3.691,0.67,5.727,3.12,6.049c1.135,0.152,3.86-0.074,4.653-5.396
			c0.082-0.551,0.12-1.064,0.12-1.533C54.328,26.17,52.862,24.783,51.332,24.467"/>
	</g>
</svg>
	</div>
</div>
