import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PostitComponent} from "./postit.component";


@NgModule({
	declarations: [PostitComponent],
	imports: [
		CommonModule
	],
	exports: [PostitComponent]
})
export class PostitModule { }
