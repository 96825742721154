import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iphone',
  templateUrl: './iphone.component.html',
  styleUrls: ['./iphone.component.scss']
})
export class IphoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
