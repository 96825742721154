<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 225.84 225.84" enable-background="new 0 0 225.84 225.84" xml:space="preserve">
	<g>
		<image overflow="visible" opacity="0.25" width="941" height="941" xlink:href="/assets/coffee-shadow.png"  transform="matrix(0.24 0 0 0.24 0 0)"></image>
		<g>
			<path fill="#FFFFFF" d="M79.047,25.671c-43.008,27.861-55.285,85.307-27.43,128.311c27.859,43.006,85.305,55.284,128.309,27.429
					c43.006-27.857,55.291-85.309,27.432-128.312C179.498,10.091,122.053-2.186,79.047,25.671z"/>
			</g>
		</g>
	<g>
			<path fill="#FFFFFF" d="M79.047,25.671c-43.008,27.861-55.285,85.307-27.43,128.311c27.859,43.006,85.305,55.284,128.309,27.429
				c43.006-27.857,55.291-85.309,27.432-128.312C179.498,10.091,122.053-2.186,79.047,25.671z"/>
		<circle fill="#EDEDED" cx="131.32" cy="104.209" r="87.526"/>
		<path opacity="0.5" fill="#DCDCDC" d="M201.925,153.377l-15-14.017c-4.039-3.774-10.375-3.561-14.154,0.478l-2.84,3.043
				c-3.775,4.038-3.562,10.379,0.479,14.154l14.996,14.016c4.039,3.778,10.377,3.562,14.152-0.479l2.842-3.04
				C206.179,163.49,205.96,157.154,201.925,153.377z"/>
		<path opacity="0.5" fill="#DCDCDC" d="M111.628,170.77c20.256,6.639,42.119,3.81,59.992-7.766
				c32.445-21.017,41.74-64.514,20.723-96.957c-8.682-13.41-21.811-23.53-36.959-28.494c-20.256-6.64-42.125-3.81-59.996,7.768
				C79.671,55.504,68.86,71.193,64.942,89.508c-3.914,18.313-0.459,37.048,9.719,52.767
				C83.349,155.686,96.476,165.803,111.628,170.77z"/>
		<path fill="#FFFFFF" d="M201.454,156.659L186.46,142.64c-4.041-3.776-10.377-3.56-14.154,0.477l-2.842,3.043
				c-3.775,4.037-3.562,10.374,0.477,14.154l14.998,14.017c4.041,3.775,10.377,3.561,14.152-0.48l2.844-3.042
				C205.71,166.769,205.499,160.433,201.454,156.659z"/>
		<path fill="#F4F3F3" d="M187.884,143.967l4.08,3.818c-4.449,6.569-10.109,12.458-16.895,17.321L170.677,161
				C177.56,156.248,183.319,150.458,187.884,143.967z"/>
		<path fill="#FFFFFF" d="M93.927,46.327c-31.988,20.724-41.123,63.456-20.4,95.444c20.719,31.987,63.451,41.123,95.439,20.399
				c31.992-20.724,41.125-63.454,20.402-95.442C168.647,34.738,125.917,25.604,93.927,46.327z"/>
		<path fill="#8A674F" d="M99.497,54.641c-27.459,17.788-35.301,54.473-17.512,81.935c17.789,27.461,54.473,35.305,81.936,17.513
				c27.463-17.791,35.303-54.473,17.516-81.937C163.644,44.693,126.962,36.85,99.497,54.641z"/>
		<path fill="#AE8B70" d="M101.731,58.084c-25.557,16.555-32.857,50.697-16.301,76.259c16.557,25.557,50.697,32.857,76.258,16.302
				c25.561-16.559,32.857-50.7,16.297-76.258C161.433,48.828,127.29,41.531,101.731,58.084z"/>
		<path fill="#7C593F" d="M104.255,61.983c-13.693,8.87-21.664,23.253-22.883,38.35c-0.082,1.006,2.838,2.59,2.617,7.052
				c-0.109,2.227,3.529,2.171,4.377,6.67c0.805,4.263-4.043,4.691-0.363,10.375c0.994,1.533,0.311,5.818,1.328,7.387
				c15.16,23.408,46.428,30.088,69.832,14.927c23.406-15.162-1.674-25.359-6.896-52.752
				C147.188,67.375,127.663,46.821,104.255,61.983z"/>
		<g>
				<path fill="#644A38" d="M167.647,98.84c-1.316,0.854-1.693,2.615-0.842,3.934c0.855,1.318,2.621,1.698,3.939,0.843
					c1.318-0.854,1.695-2.616,0.84-3.935S168.97,97.985,167.647,98.84z"/>
			<path fill="#644A38" d="M167.196,74.23c-1.016,0.661-1.307,2.021-0.648,3.038c0.66,1.019,2.02,1.311,3.041,0.652
					c1.016-0.661,1.307-2.02,0.648-3.037C169.577,73.863,168.216,73.573,167.196,74.23z"/>
			<path fill="#644A38" d="M158.573,96.198c-1.021,0.66-1.309,2.021-0.65,3.042c0.662,1.016,2.021,1.306,3.041,0.647
					c1.021-0.658,1.312-2.021,0.65-3.042C160.952,95.829,159.591,95.537,158.573,96.198z"/>
			<path fill="#644A38" d="M81.96,122.701c-1.021,0.66-1.311,2.021-0.65,3.042c0.662,1.017,2.02,1.31,3.043,0.647
					c1.016-0.658,1.307-2.021,0.646-3.036C84.339,122.333,82.978,122.045,81.96,122.701z"/>
			<path fill="#644A38" d="M149.04,64.875c-1.02,0.663-1.311,2.02-0.65,3.044c0.658,1.016,2.021,1.308,3.037,0.646
					c1.02-0.658,1.312-2.022,0.65-3.036C151.419,64.508,150.058,64.218,149.04,64.875z"/>
			<path fill="#644A38" d="M163.179,88.906c-1.021,0.661-1.312,2.021-0.652,3.04c0.662,1.02,2.02,1.31,3.037,0.65
					c1.02-0.66,1.312-2.022,0.65-3.039C165.558,88.536,164.194,88.247,163.179,88.906z"/>
			<path fill="#644A38" d="M162.165,105.781c-1.762,1.144-2.264,3.495-1.123,5.254c1.139,1.763,3.494,2.266,5.254,1.125
					s2.264-3.491,1.123-5.253C166.274,105.147,163.925,104.645,162.165,105.781z"/>
			<path fill="#644A38" d="M175.792,106.174c-1.021,0.658-1.311,2.021-0.652,3.036c0.66,1.023,2.02,1.31,3.043,0.652
					c1.018-0.659,1.307-2.021,0.646-3.041C178.171,105.804,176.81,105.512,175.792,106.174z"/>
			<path fill="#644A38" d="M79.077,104.96c-1.018,0.66-1.311,2.022-0.652,3.04c0.66,1.019,2.02,1.308,3.041,0.651
					c1.016-0.662,1.311-2.021,0.65-3.043C81.458,104.592,80.095,104.303,79.077,104.96z"/>
			<path fill="#644A38" d="M170.64,112.482c-0.746,0.481-0.957,1.478-0.475,2.218c0.482,0.747,1.475,0.958,2.217,0.479
					c0.744-0.483,0.957-1.479,0.475-2.223C172.374,112.213,171.382,112,170.64,112.482z"/>
			<circle fill="#644A38" cx="181.977" cy="99.387" r="1.605"/>
			<path fill="#644A38" d="M165.149,82.702c-0.744,0.485-0.959,1.478-0.477,2.22c0.482,0.747,1.479,0.958,2.221,0.475
					c0.742-0.482,0.955-1.473,0.475-2.219C166.888,82.435,165.892,82.222,165.149,82.702z"/>
			<path fill="#644A38" d="M172.747,118.08c-1.389,0.896-1.785,2.753-0.887,4.144c0.9,1.387,2.758,1.785,4.145,0.885
					c1.387-0.9,1.789-2.755,0.887-4.146C175.991,117.574,174.136,117.177,172.747,118.08z"/>
			<path fill="#644A38" d="M154.067,79.63c-1.758,1.136-2.264,3.488-1.123,5.251c1.141,1.762,3.496,2.263,5.258,1.122
					c1.758-1.14,2.26-3.492,1.121-5.254C158.183,78.988,155.829,78.488,154.067,79.63z"/>
			<path fill="#644A38" d="M80.905,111.825c-1.76,1.143-2.266,3.494-1.123,5.256c1.139,1.759,3.494,2.263,5.254,1.121
					c1.762-1.138,2.264-3.493,1.123-5.252C85.017,111.189,82.665,110.688,80.905,111.825z"/>
			<path fill="#644A38" d="M172.55,87.144c-1.76,1.139-2.262,3.491-1.123,5.254c1.141,1.759,3.494,2.263,5.254,1.125
					c1.762-1.143,2.262-3.495,1.123-5.257C176.665,86.507,174.31,86.003,172.55,87.144z"/>
			</g>
		<g>
				<path fill="#AE8B70" d="M168.198,99.687c-0.762,0.494-0.982,1.512-0.488,2.276c0.494,0.761,0.619-0.4,1.381-0.895
					c0.764-0.496,1.877-0.132,1.381-0.896C169.978,99.409,168.96,99.194,168.198,99.687z"/>
			<path fill="#AE8B70" d="M167.618,74.883c-0.586,0.382-0.756,1.171-0.371,1.759c0.381,0.587,0.475-0.31,1.064-0.691
					c0.592-0.38,1.449-0.099,1.07-0.692C168.997,74.669,168.21,74.5,167.618,74.883z"/>
			<path fill="#AE8B70" d="M158.997,96.851c-0.592,0.381-0.76,1.171-0.375,1.758c0.379,0.588,0.477-0.309,1.066-0.691
					c0.588-0.381,1.447-0.104,1.068-0.69C160.374,96.638,159.585,96.469,158.997,96.851z"/>
			<path fill="#AE8B70" d="M82.384,123.358c-0.592,0.38-0.762,1.168-0.377,1.757c0.383,0.591,0.475-0.311,1.066-0.693
					c0.592-0.378,1.449-0.1,1.07-0.692C83.759,123.142,82.972,122.972,82.384,123.358z"/>
			<path fill="#AE8B70" d="M149.464,65.532c-0.592,0.38-0.762,1.168-0.377,1.756c0.379,0.588,0.479-0.311,1.066-0.692
					c0.59-0.382,1.451-0.101,1.068-0.69C150.839,65.316,150.052,65.149,149.464,65.532z"/>
			<path fill="#AE8B70" d="M163.599,89.559c-0.59,0.382-0.762,1.167-0.375,1.763c0.383,0.584,0.475-0.315,1.066-0.695
					c0.59-0.382,1.451-0.104,1.068-0.692S164.188,89.175,163.599,89.559z"/>
			<path fill="#AE8B70" d="M162.897,106.91c-1.02,0.66-1.311,2.022-0.65,3.042c0.658,1.015,0.822-0.536,1.844-1.196
					c1.02-0.659,2.504-0.178,1.846-1.193C165.276,106.542,163.915,106.252,162.897,106.91z"/>
			<path fill="#AE8B70" d="M176.216,106.828c-0.594,0.38-0.762,1.167-0.377,1.758c0.383,0.587,0.475-0.311,1.062-0.692
					c0.594-0.382,1.451-0.104,1.07-0.691C177.589,106.612,176.804,106.447,176.216,106.828z"/>
			<path fill="#AE8B70" d="M79.497,105.611c-0.59,0.384-0.756,1.172-0.373,1.762c0.383,0.588,0.477-0.309,1.066-0.691
					s1.447-0.104,1.066-0.691C80.876,105.401,80.087,105.233,79.497,105.611z"/>
			<path fill="#AE8B70" d="M170.946,112.958c-0.432,0.281-0.555,0.856-0.273,1.286c0.275,0.429,0.348-0.227,0.779-0.507
					c0.428-0.274,1.059-0.075,0.779-0.502C171.95,112.804,171.38,112.681,170.946,112.958z"/>
			<path fill="#AE8B70" d="M181.417,98.516c-0.43,0.279-0.559,0.854-0.277,1.283c0.281,0.432,0.348-0.224,0.781-0.503
					c0.43-0.279,1.055-0.074,0.777-0.507C182.419,98.36,181.847,98.239,181.417,98.516z"/>
			<path fill="#AE8B70" d="M165.456,83.178c-0.43,0.279-0.551,0.856-0.275,1.284c0.279,0.435,0.348-0.225,0.779-0.504
					c0.432-0.275,1.062-0.072,0.783-0.506C166.462,83.024,165.888,82.9,165.456,83.178z"/>
			<path fill="#AE8B70" d="M173.325,118.964c-0.807,0.522-1.035,1.598-0.516,2.401c0.52,0.806,0.65-0.419,1.457-0.941
					c0.803-0.521,1.975-0.144,1.455-0.943C175.2,118.678,174.126,118.446,173.325,118.964z"/>
			<path fill="#AE8B70" d="M154.8,80.756c-1.02,0.662-1.312,2.019-0.648,3.038c0.658,1.022,0.824-0.533,1.844-1.192
					c1.016-0.661,2.5-0.173,1.844-1.196C157.181,80.386,155.817,80.096,154.8,80.756z"/>
			<path fill="#AE8B70" d="M81.636,112.955c-1.018,0.661-1.309,2.022-0.648,3.042c0.656,1.016,0.824-0.535,1.84-1.2
					c1.025-0.656,2.51-0.176,1.848-1.191C84.017,112.585,82.655,112.296,81.636,112.955z"/>
			<path fill="#AE8B70" d="M173.284,88.271c-1.023,0.661-1.311,2.022-0.652,3.041c0.662,1.018,0.824-0.536,1.844-1.195
					c1.02-0.66,2.506-0.176,1.846-1.197C175.663,87.904,174.302,87.611,173.284,88.271z"/>
			</g>
		</g>
</svg>
