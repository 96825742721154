<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 235 156.7" style="enable-background:new 0 0 235 156.7;" xml:space="preserve">
<style type="text/css">
	.st3{fill:url(#SVGID_1_);}
	.st5{fill:url(#SVGID_2_);}
	.st6{fill:url(#SVGID_3_);}
	.st10{fill:#FFFFFF;}
	.st26{fill:#4E4E4D;}
	.st84{fill:none;}
	.st88{fill:#7F8081;}
	.st89{fill:#EBEBEB;}
	.st90{fill:#181818;}
	.st91{fill:#272727;}
	.st92{fill:#050607;}
	.st93{fill:#080F20;}
	.st94{opacity:0.5561;fill:#005A95;}
	.st95{fill:#050505;}
	.st96{fill:#070706;}
	.st97{fill:#2F2F30;}
</style>

	<g>
		<g>

				<image style="overflow:visible;opacity:0.45;" width="979" height="653" xlink:href="/assets/iphone-shadow.png"  transform="matrix(0.24 0 0 0.24 0 0)">
			</image>
			<g>
				<path class="st10" d="M230.1,88l-7-30l-1-0.4l-0.5-2.2l-1.1-4.7l-0.5-2.2l0.7-0.8l-7-30c-1.9-8.2-10.1-13.3-18.3-11.3L78,34
					l0-0.1c-0.1-0.3-0.3-0.4-0.6-0.4l-22.2,5.2c-0.3,0.1-0.4,0.3-0.4,0.6l0,0.1L23,46.9c-8.2,1.9-13.3,10.1-11.3,18.3l16.5,70.3
					c1.9,8.2,10.1,13.3,18.3,11.3l27.1-6.3l0,0.1c0.1,0.3,0.3,0.4,0.6,0.4l13.7-3.2c0.3-0.1,0.4-0.3,0.4-0.6l0-0.1l3.8-0.9l0,0.1
					c0.1,0.3,0.3,0.4,0.6,0.4l13.7-3.2c0.3-0.1,0.4-0.3,0.4-0.6l0-0.1l112.3-26.3C227,104.4,232.1,96.2,230.1,88z"/>
			</g>
		</g>
		<g id="layer2_2_">
			<path class="st84" d="M54.8,39.3c-0.1-0.3,0.1-0.5,0.4-0.6l22.2-5.2c0.3-0.1,0.5,0.1,0.6,0.4l0.9,3.7c0.1,0.3-0.1,0.5-0.4,0.6
				l-22.2,5.2c-0.3,0.1-0.5-0.1-0.6-0.4L54.8,39.3z"/>
			<g id="g5503_2_" transform="translate(0.01350077)">
				<path id="rect5351_2_" d="M58.8,143.9l-0.9-3.7c-0.1-0.3,0.1-0.5,0.4-0.6l6.9-1.6c0.3-0.1,0.5,0.1,0.6,0.4l0.9,3.7
					c0.1,0.3-0.1,0.5-0.4,0.6l-6.9,1.6C59.1,144.3,58.9,144.2,58.8,143.9z"/>
				<g id="rect5351-6_2_">

						<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-452.1457" y1="598.5518" x2="-452.1457" y2="596.6469" gradientTransform="matrix(-0.8632 -3.6796 -3.6796 0.8632 1871.3704 -2036.5333)">
						<stop  offset="0" style="stop-color:#CCCCCB;stop-opacity:0.2745"/>
						<stop  offset="5.000000e-02" style="stop-color:#CCCCCB"/>
						<stop  offset="0.95" style="stop-color:#CCCCCB"/>
						<stop  offset="1" style="stop-color:#CCCCCB;stop-opacity:0.2757"/>
					</linearGradient>
					<path class="st3" d="M59.2,143.7c0.1,0.2,0.3,0.4,0.5,0.3l6.2-1.4c0.2-0.1,0.4-0.3,0.3-0.5l0-0.1l-7,1.6L59.2,143.7z"/>
				</g>
			</g>
			<g id="g5624_2_">
				<path id="rect5351-5_2_" d="M73.5,140.5c0.1,0.3,0.3,0.4,0.6,0.4l13.7-3.2c0.3-0.1,0.4-0.3,0.4-0.6l-0.9-3.7
					c-0.1-0.3-0.3-0.4-0.6-0.4L73,136.2c-0.3,0.1-0.4,0.3-0.4,0.6L73.5,140.5z"/>
				<g id="rect5351-6-6_2_">

						<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-452.1317" y1="608.3981" x2="-452.1317" y2="604.6499" gradientTransform="matrix(-0.8632 -3.6796 -3.6796 0.8632 1922.29 -2048.4211)">
						<stop  offset="0" style="stop-color:#CCCCCB;stop-opacity:0.2745"/>
						<stop  offset="5.000000e-02" style="stop-color:#CCCCCB"/>
						<stop  offset="0.95" style="stop-color:#CCCCCB"/>
						<stop  offset="1" style="stop-color:#CCCCCB;stop-opacity:0.2757"/>
					</linearGradient>
					<path class="st5" d="M73.9,140.3c0.1,0.2,0.3,0.4,0.5,0.3l12.9-3c0.2-0.1,0.4-0.3,0.3-0.5l0-0.1l-13.8,3.2L73.9,140.3z"/>
				</g>
			</g>
			<g id="g5624-1_2_" transform="translate(0,17.37252)">
				<path id="rect5351-5-6_2_" d="M91.9,118.8c0.1,0.3,0.3,0.4,0.6,0.4l13.7-3.2c0.3-0.1,0.4-0.3,0.4-0.6l-0.9-3.7
					c-0.1-0.3-0.3-0.4-0.6-0.4l-13.7,3.2c-0.3,0.1-0.4,0.3-0.4,0.6L91.9,118.8z"/>
				<g id="rect5351-6-6-2_2_">

						<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-452.132" y1="603.3926" x2="-452.132" y2="599.6442" gradientTransform="matrix(-0.8632 -3.6796 -3.6796 0.8632 1922.288 -2065.7937)">
						<stop  offset="0" style="stop-color:#CCCCCB;stop-opacity:0.2745"/>
						<stop  offset="5.000000e-02" style="stop-color:#CCCCCB"/>
						<stop  offset="0.95" style="stop-color:#CCCCCB"/>
						<stop  offset="1" style="stop-color:#CCCCCB;stop-opacity:0.2757"/>
					</linearGradient>
					<path class="st6" d="M92.3,118.6c0.1,0.2,0.3,0.4,0.5,0.3l12.9-3c0.2-0.1,0.4-0.3,0.3-0.5l0-0.1l-13.8,3.2L92.3,118.6z"/>
				</g>
			</g>
			<g id="g5128_2_">
				<g id="g5116_2_" transform="translate(-244.34124)">
					<path id="path5106_2_" class="st88" d="M296.7,145l-0.3-1.2l-1.4,0.3l0.3,1.2L296.7,145z"/>
					<path id="path5104_2_" class="st89" d="M457.1,107.4l-0.3-1.2l-160.5,37.6l0.3,1.2L457.1,107.4z"/>
					<path id="path5102_2_" class="st88" d="M458.5,107l-0.3-1.2l-1.4,0.3l0.3,1.2L458.5,107z"/>
					<path id="path5098_2_" class="st88" d="M433.9,8.1l0.3,1.2l1.4-0.3l-0.3-1.2L433.9,8.1z"/>
					<path id="path5094_2_" class="st88" d="M272,46.1l0.3,1.2l1.4-0.3l-0.3-1.2L272,46.1z"/>
					<path id="rect4873_2_" class="st89" d="M272.8,134.8c2,8.3,10.2,13.5,18.6,11.5l4-0.9l-0.3-1.2l-4.4,1
						c-7.5,1.7-14.9-2.8-16.6-10.3l-16.4-70c-1.7-7.5,2.8-14.9,10.3-16.6l4.4-1l-0.3-1.2l-4,0.9c-8.3,2-13.5,10.2-11.5,18.6
						L272.8,134.8z"/>
				</g>
				<g>
					<path d="M230.1,88l-7-30l-0.4-0.2l-2.3-9.8l0.3-0.3l-7-30c-1.9-8.2-10.1-13.3-18.3-11.3L78,34l0-0.1c-0.1-0.3-0.3-0.4-0.6-0.4
						l-22.2,5.2c-0.3,0.1-0.4,0.3-0.4,0.6l0,0.1L23,46.9c-8.2,1.9-13.3,10.1-11.3,18.3l16.5,70.3c1.9,8.2,10.1,13.3,18.3,11.3
						l12.4-2.9l0,0.1c0.1,0.3,0.3,0.4,0.6,0.4l6.9-1.6c0.3-0.1,0.4-0.3,0.4-0.6l0-0.1l6.8-1.6l0,0.1c0.1,0.3,0.3,0.4,0.6,0.4
						l13.7-3.2c0.3-0.1,0.4-0.3,0.4-0.6l0-0.1l3.8-0.9l0,0.1c0.1,0.3,0.3,0.4,0.6,0.4l13.7-3.2c0.3-0.1,0.4-0.3,0.4-0.6l0-0.1
						l112.3-26.3C227,104.4,232.1,96.2,230.1,88z"/>
				</g>
			</g>
			<g id="g5823_2_">
				<path id="rect5261_2_" class="st90" d="M28.6,103.7l-2.5-10.5c-0.1-0.4,0.2-0.8,0.6-0.9l0,0c0.4-0.1,0.8,0.2,0.9,0.6l2.5,10.5
					c0.1,0.4-0.2,0.8-0.6,0.9l0,0C29.1,104.4,28.7,104.1,28.6,103.7z"/>
				<path id="path5263_2_" class="st91" d="M24,87.8c-0.2-0.8,0.3-1.6,1.1-1.8c0.8-0.2,1.6,0.3,1.8,1.1c0.2,0.8-0.3,1.6-1.1,1.8
					C25,89.1,24.2,88.6,24,87.8z"/>
				<path id="path5265_2_" class="st92" d="M24.3,87.7c-0.1-0.6,0.2-1.3,0.9-1.4c0.6-0.1,1.3,0.2,1.4,0.9c0.1,0.6-0.2,1.3-0.9,1.4
					C25.1,88.7,24.5,88.4,24.3,87.7z"/>
				<path id="path5267_2_" class="st93" d="M24.6,87.7c-0.1-0.5,0.2-1,0.7-1.1c0.5-0.1,1,0.2,1.1,0.7c0.1,0.5-0.2,1-0.7,1.1
					C25.2,88.4,24.7,88.1,24.6,87.7z"/>
				<g id="path5271_2_">
					<path class="st94" d="M25,88.2c0,0.1,0.2,0.2,0.5,0.1c0.3-0.1,0.4-0.2,0.4-0.3C26,88,25.5,87.7,25,88.2z"/>
				</g>
				<g id="path5273_2_">
					<path class="st94" d="M24.7,87.5c0.1,0.2,0.4,0.1,0.5,0.1c0.1,0,0.5-0.3,0.3-0.6c-0.1-0.2-0.5-0.2-0.7,0
						C24.7,87.1,24.6,87.3,24.7,87.5z"/>
				</g>
				<path id="path5267-7_2_" class="st95" d="M30,106.2c0.6-0.1,1.2,0.2,1.4,0.9c0.1,0.6-0.2,1.2-0.8,1.4c-0.6,0.2-1.2-0.2-1.4-0.8
					C29,106.9,29.4,106.3,30,106.2l0.3,1.1L30,106.2z"/>
				<path id="path5267-7-9_2_" class="st96" d="M31.5,112.5c0.9-0.2,1.8,0.4,2.1,1.3c0.2,0.9-0.3,1.9-1.3,2.1
					c-0.9,0.2-1.8-0.4-2.1-1.3C30,113.7,30.6,112.7,31.5,112.5l0.4,1.7L31.5,112.5z"/>
			</g>
			<path id="rect5756_2_" d="M30.6,134.7c0.6,2.4,1.8,4.5,3.5,6.2l1.6-0.4c-1.6-1.5-2.8-3.6-3.4-5.9l-6.6-28l-3.4-14.6l-6.6-28
				c-0.6-2.4-0.4-4.7,0.4-6.8l-1.6,0.4c-0.8,2.2-0.9,4.6-0.3,7.1l6.5,27.7l3.4,14.6L30.6,134.7z"/>
			<path id="rect5756-4_2_" d="M227.6,88.5c0.6,2.4,0.4,4.9-0.3,7.1l-1.6,0.4c0.8-2.1,1-4.5,0.4-6.8l-6.6-28L216,46.5l-6.6-28
				c-0.6-2.4-1.8-4.4-3.4-5.9l1.6-0.4c1.7,1.6,2.9,3.7,3.5,6.2l6.5,27.7l3.4,14.6L227.6,88.5z"/>
			<path id="rect5872_2_" class="st97" d="M34.1,133.7c1.2,5,6.1,8.1,11.1,6.9l172-40.3c5-1.2,8.1-6.1,6.9-11.1l-16.3-69.7
				c-1.2-5-6.1-8.1-11.1-6.9l-172,40.3c-5,1.2-8.1,6.1-6.9,11.1l2.1,8.8c0.2,0.8,1,1.3,1.9,1.2l0.7-0.2c2.7-0.6,5.4,1,6,3.7
				l9.2,39.3c0.6,2.7-1,5.4-3.7,6l-0.7,0.2c-0.8,0.2-1.3,1-1.1,1.9L34.1,133.7z"/>
			<path id="path5267-7-7_2_" class="st95" d="M23.3,78.5c1.2-0.3,2.5,0.5,2.8,1.7c0.3,1.2-0.5,2.5-1.7,2.8
				c-1.2,0.3-2.5-0.5-2.8-1.7C21.3,80,22.1,78.8,23.3,78.5l0.5,2.3L23.3,78.5z"/>
			<g id="g5259_2_" transform="translate(-0.11323174)">
				<path id="rect5132_2_" class="st26" d="M14.5,58.6c0,0.3,0.3,0.1,0.8-0.8c1.7-3,4.6-5.3,8.2-6.1l86.8-20.4L197.3,11
					c3.6-0.9,7.3-0.1,10.1,1.8c0.9,0.6,1.2,0.7,1.1,0.3c-0.1-0.2-0.3-0.5-0.7-1c-2.7-3.1-7.3-4.2-11.7-3.2l-86.2,20.2L23.8,49.3
					c-4.4,1-8,4.1-9,8.1C14.6,58,14.5,58.4,14.5,58.6z"/>
				<path id="rect5132-5_2_" class="st26" d="M33.6,140c-0.1-0.3,0.3-0.2,1.1,0.3c2.8,1.9,6.5,2.7,10.1,1.8l86.8-20.4l86.8-20.4
					c3.6-0.9,6.6-3.1,8.2-6.1c0.5-0.9,0.8-1.1,0.8-0.8c0,0.2,0,0.6-0.2,1.2c-1.1,4-4.7,7-9,8.1L132.2,124L46,144.2
					c-4.4,1-8.9-0.1-11.7-3.2C33.9,140.6,33.7,140.2,33.6,140z"/>
			</g>
		</g>
	</g>
</svg>
