import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-at-work',
	templateUrl: './at-work.component.html',
	styleUrls: ['./at-work.component.scss']
})
export class AtWorkComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}
