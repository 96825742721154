<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 290.7 353" style="enable-background:new 0 0 290.7 353;" xml:space="preserve">
<style type="text/css">
	.st3{fill:url(#SVGID_1_);}
	.st7{fill:url(#SVGID_4_);}
	.st10{fill:#FFFFFF;}
	.st31{fill:url(#SVGID_5_);}
	.st32{fill:url(#SVGID_6_);}
	.st106{fill:#80B9AC;}
	.st108{opacity:0.3;fill:#CFD1D2;}
	.st109{opacity:0.3;fill:#CEBA83;}
	.st110{opacity:0.5;fill:#CEBA83;}
</style>

<g>
	<image style="overflow:visible;opacity:0.45;" width="1274" height="1537" xlink:href="/assets/books-shadow.png"  transform="matrix(0.24 0 0 0.24 -4.9748 -14.5961)"></image>
	<g>
		<path class="st10" d="M288.8,274.9c2.6-2.7,2.2-6.5,0.9-12c-2.1-9-57.4-245.3-58.8-251c-0.1-0.3-0.1-0.5-0.2-0.8c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.3-0.1-0.5-0.2-0.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c-0.1-0.3-0.2-0.6-0.2-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.1,0-0.1-0.1-0.2
			c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.2-0.5-0.3-0.8c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.5-0.3-0.7
			c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.5-0.4-0.7c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0
			c-0.1-0.2-0.3-0.5-0.4-0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0l0,0c-0.1-0.2-0.3-0.4-0.4-0.6c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.3-0.4-0.5-0.6v0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c-0.2-0.2-0.4-0.4-0.6-0.5v0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.1-0.4-0.3-0.6-0.4c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.5-0.3-0.8-0.4c-0.1,0-0.1,0-0.2-0.1c-0.3-0.1-0.6-0.2-0.9-0.3c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.1-0.6-0.1-0.9-0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-0.6,0-0.9-0.1
			c0,0-0.1,0-0.1,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.7,0.1-1,0.2
			C209,2.6,47.9,40.3,28.4,44.9c-5,1.2-6.6,3-4.2,13.3c0.2,0.9,0.8,3.5,1.8,7.5c0,0.1,0.1,0.3,0.1,0.4c10,42.5,55,235,60.7,259.3
			c0.2,0.7,0.3,1.4,0.5,2.1c0.4,1.8,1.3,3.9,6.6,2.9c5.3-1,183.1-42.9,188.9-44.1c5.7-1.1,8-1.4,7-6.6
			C289.6,278.4,289.2,276.7,288.8,274.9z"/>
	</g>
</g>
<g>

		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="3028.4976" y1="4405.8706" x2="3236.7554" y2="4405.8706" gradientTransform="matrix(0.9737 -0.2281 0.2281 0.9737 -3867.7827 -3277.0161)">
		<stop  offset="0" style="stop-color:#4D2D25"/>
		<stop  offset="0.166" style="stop-color:#593126"/>
		<stop  offset="0.4896" style="stop-color:#7A3A28"/>
		<stop  offset="0.935" style="stop-color:#B34022"/>
		<stop  offset="1" style="stop-color:#BC4120"/>
	</linearGradient>
	<path class="st3" d="M85.4,312.1c-0.6,2.2,0.1,7.5,0.4,8.8c0.5,2.4,0.9,4.2,1.5,6.6c0.4,1.8,1.3,3.9,6.6,2.9
		c5.3-1,183.1-42.9,188.9-44.1c5.7-1.1,8-1.4,7-6.6c-1-5.1-3.3-14.9-3.3-14.9L85.4,312.1z"/>

		<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="3131.0608" y1="4414.6738" x2="3131.0608" y2="4401.1694" gradientTransform="matrix(0.9737 -0.2281 0.2281 0.9737 -3867.7827 -3277.0161)">
		<stop  offset="4.040290e-02" style="stop-color:#F3A194"/>
		<stop  offset="0.3748" style="stop-color:#BC4120"/>
		<stop  offset="0.9916" style="stop-color:#050505"/>
	</linearGradient>
	<path style="opacity:0.4;fill:url(#SVGID_2_);" d="M286.5,283.3l-26,6l-26,6.1l-51.9,12.2c-8.7,2-17.3,4.1-26,6.2l-25.9,6.2
		l-26,6.1l-6.5,1.5c-1.1,0.2-2.2,0.5-3.3,0.7c-1.1,0.2-2.2,0.3-3.3,0.3c-0.5,0-1.1-0.1-1.5-0.4c-0.4-0.3-0.8-0.7-1-1.1
		c-0.5-0.9-0.8-2-1-3.1c-0.5-2.2-1.1-4.3-1.5-6.5l-0.3,0.1l-0.3-2.5l0.6,2.4l-0.3,0.1c0.4,2.2,0.9,4.4,1.5,6.5
		c0.3,1.1,0.5,2.2,1.1,3.2c0.3,0.5,0.7,1,1.2,1.3c0.5,0.3,1.1,0.4,1.7,0.5c1.2,0,2.3-0.1,3.4-0.3c1.1-0.1,2.2-0.4,3.3-0.6l6.5-1.4
		l26-6c17.3-4.1,34.6-8,51.9-12.2l51.9-12.5l25.9-6.3L286.5,283.3z"/>

		<radialGradient id="SVGID_3_" cx="2592.4531" cy="3989.522" r="184.0726" gradientTransform="matrix(0.6473 -0.1517 0.1517 0.6473 -1998.0826 -1908.7559)" gradientUnits="userSpaceOnUse">
		<stop  offset="4.040290e-02" style="stop-color:#F3A194"/>
		<stop  offset="0.3748" style="stop-color:#BC4120"/>
		<stop  offset="0.9916" style="stop-color:#050505"/>
	</radialGradient>
	<path style="opacity:0.4;fill:url(#SVGID_3_);" d="M288.4,275.3c0.1,0.5,0.2,1,0.3,1.5c0.3,1.1,0.5,2.2,0.5,3.3
		c0,0.6-0.2,1.2-0.5,1.7c-0.3,0.5-0.8,0.9-1.3,1.2c-1,0.5-2.1,0.9-3.1,1.2c-1,0.4-2.1,0.6-3.2,0.9l-6.5,1.6l-26,6.2
		c-17.3,4-34.6,8.2-52,12.1l-52,11.9l-26,5.9l-26,5.8l26-6.1l26-6.1l52-12.1c8.7-2,17.3-4,26-6l26-6l26-6l6.5-1.6
		c1.1-0.3,2.2-0.5,3.2-0.9c1.1-0.3,2.1-0.7,3.1-1.2c0.5-0.3,0.9-0.6,1.2-1c0.3-0.4,0.4-0.9,0.4-1.5c0-1.1-0.2-2.2-0.4-3.2
		c-0.1-0.4-0.2-0.9-0.3-1.3c0,0,0.1,0,0.2-0.1C288.3,275.4,288.4,275.3,288.4,275.3z"/>
	<path class="st106" d="M85.4,312.1c-0.6,2.2,0.1,7.5,0.4,8.8c0.5,2.4,0.9,4.2,1.5,6.6c0.4,1.8,1.3,3.9,6.6,2.9
		c5.3-1,183.1-42.9,188.9-44.1c5.7-1.1,8-1.4,7-6.6c-1-5.1-3.3-14.9-3.3-14.9L85.4,312.1z"/>
	<g>

			<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="3133.4595" y1="4384.7837" x2="3136.0908" y2="4424.9961" gradientTransform="matrix(0.9737 -0.2281 0.2281 0.9737 -3867.7827 -3277.0161)">
			<stop  offset="0.2197" style="stop-color:#87888A"/>
			<stop  offset="0.5013" style="stop-color:#C6C7C8"/>
			<stop  offset="0.6973" style="stop-color:#F6F6F6"/>
		</linearGradient>
		<path class="st7" d="M284.4,255.3c0,0,1.5,20,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.3,1-6.9,2.5-7.4-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C94.7,307.6,284.4,255.3,284.4,255.3z"/>
		<path class="st108" d="M284.4,255.3c0,0,1.5,20,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.3,1-6.9,2.5-7.4-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C94.7,307.6,284.4,255.3,284.4,255.3z"/>
		<path class="st108" d="M284.5,254.6c0,0,1.5,20,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.4,1-6.9,2.5-7.5-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C94.8,306.9,284.5,254.6,284.5,254.6z"/>
		<path class="st108" d="M284.2,253.5c0,0,1.5,20,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.4,1-6.9,2.5-7.4-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C94.5,305.8,284.2,253.5,284.2,253.5z"/>
		<path class="st108" d="M284,252.5c0,0,1.5,20,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.3,1-6.9,2.5-7.4-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C94.3,304.8,284,252.5,284,252.5z"/>
		<path class="st108" d="M283.7,251.4c0,0,1.5,20,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.3,1-6.9,2.5-7.4-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C94.1,303.7,283.7,251.4,283.7,251.4z"/>
		<path class="st108" d="M283.5,250.2c0,0,1.5,19.9,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.4,1-6.9,2.5-7.5-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C93.8,302.5,283.5,250.2,283.5,250.2z"/>
		<path class="st109" d="M283.1,248.7c0,0,1.5,19.9,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.4,1-6.9,2.5-7.4-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C93.4,301,283.1,248.7,283.1,248.7z"/>
		<path class="st109" d="M282.8,247.4c0,0,1.5,19.9,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.4,1-6.9,2.5-7.5-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C93.1,299.7,282.8,247.4,282.8,247.4z"/>
		<path class="st109" d="M282.4,245.7c0,0,1.5,19.9,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.3,1-6.9,2.5-7.4-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C92.7,298,282.4,245.7,282.4,245.7z"/>
		<path class="st110" d="M282.1,244.4c0,0,1.5,20,3.8,24.6c0,0-2.9,2.1-13.3,4.3c-10.4,2.1-171.9,41.1-177.2,42.1
			c-5.4,1-6.9,2.5-7.4-1.3c-0.6-3.7-1.3-8-1.8-11.2c-0.5-3.2,2.1-4.6,4.2-5.4C92.4,296.7,282.1,244.4,282.1,244.4z"/>
	</g>

		<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="3239.0461" y1="4267.2632" x2="3027.5398" y2="4267.2632" gradientTransform="matrix(0.9737 -0.2281 0.2281 0.9737 -3867.7827 -3277.0161)">
		<stop  offset="0" style="stop-color:#F18F26"/>
		<stop  offset="0.9493" style="stop-color:#F18F26"/>
		<stop  offset="0.9679" style="stop-color:#EB6C31"/>
		<stop  offset="0.9891" style="stop-color:#E64335"/>
		<stop  offset="1" style="stop-color:#E52F35"/>
	</linearGradient>
	<path class="st31" d="M28.4,44.9C47.9,40.3,209,2.6,218.3,0.4c9.4-2.2,11.3,5.9,12.6,11.6c1.3,5.7,56.7,242,58.8,251
		c2.1,8.9,1.8,13.3-10,16.1c-11.8,2.8-180.3,40.7-185.2,41.9c-4.9,1.1-6.9,3.3-7.1,7c0,0-60.8-259.5-63.2-269.8
		C21.8,47.8,23.4,46,28.4,44.9z"/>

		<linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="3262.4121" y1="4136.5352" x2="3108.1309" y2="4460.1348" gradientTransform="matrix(0.9737 -0.2281 0.2281 0.9737 -3867.7827 -3277.0161)">
		<stop  offset="0" style="stop-color:#BC4120"/>
		<stop  offset="1" style="stop-color:#743222"/>
	</linearGradient>
	<path class="st32" d="M222.3,0.1c4.2,2,6.7,6.5,7.5,10.2c1.3,5.7,56,238.9,58.1,250.4c1.7,9,3.2,13.4-8.6,16.2
		c-11.8,2.8-180.3,40.7-185.2,41.9c-4,0.9-6.3,3.7-7.2,6.7c0.4,1.6,0.6,2.5,0.6,2.5c0.2-3.7,2.3-5.9,7.1-7
		c4.9-1.1,173.4-39.1,185.2-41.9c11.8-2.8,12.1-7.1,10-16.1c-2.1-9-57.4-245.3-58.8-251C229.8,7.2,228.3,0.7,222.3,0.1z"/>
	<defs>
		<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="40.4" y="41.9" width="66.5" height="274.4">
			<feFlood  style="flood-color:white;flood-opacity:1" result="back"/>
			<feBlend  in="SourceGraphic" in2="back" mode="normal"/>
		</filter>
	</defs>
	<mask maskUnits="userSpaceOnUse" x="40.4" y="41.9" width="66.5" height="274.4" id="SVGID_7_">
	</mask>

		<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-2924.9026" y1="4293.9058" x2="-2923.1255" y2="4293.9058" gradientTransform="matrix(-1.3176 0.3087 0.2265 0.9668 -4751.6265 -3069.5793)">
		<stop  offset="0" style="stop-color:#E85835"/>
		<stop  offset="0.7624" style="stop-color:#BC4120"/>
		<stop  offset="1" style="stop-color:#743222"/>
	</linearGradient>
	<polygon style="opacity:0.5;mask:url(#SVGID_7_);fill:url(#SVGID_8_);" points="104.5,316.3 106.9,315.8 42.7,41.9 40.4,42.5
		"/>

		<radialGradient id="SVGID_9_" cx="2597.52" cy="3564.0308" r="296.0952" gradientTransform="matrix(0.6473 -0.1517 0.1517 0.6473 -1998.0826 -1908.7559)" gradientUnits="userSpaceOnUse">
		<stop  offset="0.2182" style="stop-color:#F3A194"/>
		<stop  offset="0.5302" style="stop-color:#BC4120"/>
		<stop  offset="0.9916" style="stop-color:#050505"/>
	</radialGradient>
	<path style="opacity:0.8;fill:url(#SVGID_9_);" d="M280.6,231.3c0,0-6.1-25.5-15.3-63.7c-4.5-19.1-9.8-41.4-15.5-65.4
		c-5.6-23.9-11.6-49.4-17.6-74.9c-0.7-3.2-1.5-6.4-2.2-9.6c-0.8-3.2-1.3-6.4-2.5-9.4c-0.6-1.5-1.4-2.8-2.5-3.9
		c-1.1-1-2.5-1.7-4-1.8c-0.4,0-0.8-0.1-1.1,0c-0.4,0-0.8,0-1.2,0.1c-0.8,0.1-1.5,0.3-2.3,0.5c-1.6,0.4-3.1,0.7-4.7,1.1
		c-12.6,2.9-24.9,5.8-36.9,8.6c-23.9,5.5-46.3,10.7-65.4,15.1c-38.3,8.8-63.9,14.6-63.9,14.6s25.5-6.1,63.7-15.3
		c19.1-4.5,41.4-9.8,65.4-15.5c12-2.8,24.3-5.7,36.9-8.7c1.6-0.4,3.1-0.7,4.7-1.1c0.8-0.2,1.6-0.3,2.4-0.5
		c0.4-0.1,0.8-0.1,1.3-0.1c0.4,0,0.9,0,1.3,0c1.7,0.2,3.4,0.9,4.7,2.1c1.3,1.2,2.1,2.7,2.8,4.3c0.6,1.6,1.1,3.2,1.5,4.8l1.1,4.8
		c0.8,3.2,1.5,6.4,2.3,9.6c5.9,25.5,11.9,51.1,17.5,75c5.5,23.9,10.7,46.3,15.1,65.4C274.7,205.8,280.6,231.3,280.6,231.3z"/>

		<radialGradient id="SVGID_10_" cx="2597.2981" cy="3821.7468" r="3444.8669" gradientTransform="matrix(0.6473 -0.1517 0.1517 0.6473 -1998.0826 -1908.7559)" gradientUnits="userSpaceOnUse">
		<stop  offset="4.040290e-02" style="stop-color:#F3A194"/>
		<stop  offset="0.3748" style="stop-color:#BC4120"/>
		<stop  offset="0.9916" style="stop-color:#050505"/>
	</radialGradient>
	<path style="opacity:0.6;fill:url(#SVGID_10_);" d="M108,314.5c0,0,167.7-37.6,172.6-38.7c4.9-1.1,10.6-2.5,7.3-15.2
		c-3.3-12.7-48.2-204.4-48.2-204.4s46.2,198.9,47.4,205.3c1.2,6.4,1.5,12.1-6.1,13.6C273.3,276.7,108,314.5,108,314.5z"/>
	<path class="st106" d="M289.7,263c-2.1-9-57.4-245.3-58.8-251c-0.1-0.3-0.1-0.5-0.2-0.8c0,0,0-0.1,0-0.1
		c-0.1-0.3-0.1-0.6-0.2-0.9c0,0,0-0.1,0-0.1c-0.1-0.3-0.2-0.6-0.2-0.9c0,0,0,0,0,0c-0.1-0.3-0.2-0.6-0.2-0.8c0-0.1,0-0.1-0.1-0.2
		c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.2-0.5-0.3-0.8c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.5-0.3-0.7
		c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.5-0.4-0.7c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.5-0.4-0.7c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.2-0.3-0.4-0.4-0.6c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.2-0.4-0.4-0.6-0.5
		c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.3-0.6-0.4c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.5-0.3-0.8-0.4c-0.1,0-0.1,0-0.2-0.1
		c-0.3-0.1-0.6-0.2-0.9-0.3c0,0-0.1,0-0.1,0c-0.3-0.1-0.6-0.1-0.9-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-1.1-0.1-2.4,0-3.9,0.3
		C209,2.6,47.9,40.3,28.4,44.9c-5,1.2-6.6,3-4.2,13.3C26.6,68.3,86,321.7,87.4,327.8c0,0.1,0,0.1,0,0.1c0.2-3.7,2.3-5.9,7.1-7
		c4.9-1.1,173.4-39.1,185.2-41.9C291.5,276.3,291.8,271.9,289.7,263z"/>
</g>

</svg>
