<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 140 140" style="enable-background:new 0 0 140 140;" xml:space="preserve">
	<style type="text/css">
	.st14{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.st15{fill-rule:evenodd;clip-rule:evenodd;fill:#FCEE6C;}
	.st16p{fill-rule:evenodd;clip-rule:evenodd;fill:#FFD64C;stroke:#FFEDBA;stroke-width:0.1196;stroke-miterlimit:22.9256;}
</style>
<g>
	<image style="overflow:visible;opacity:0.25;" width="601" height="622" xlink:href="/assets/postit-shadow.png"  transform="matrix(0.24 0 0 0.24 -6.6749 -4.7962)"></image>
	<g>
		<path class="st14" d="M88.5,101.1c0.7-0.3,1.4-0.7,2.1-1c1.2-0.6,2.4-1.2,3.6-1.8c0.6-0.3,1.2-0.6,1.8-0.9
			c1.8-0.9,3.5-1.8,5.2-2.8c0.2-0.1,0.3-0.2,0.5-0.3c1.6-0.9,3.2-1.8,4.7-2.7c0.6-0.3,1.1-0.7,1.7-1c1.2-0.7,2.3-1.4,3.5-2.1
			c0.6-0.4,1.2-0.7,1.8-1.1c1.2-0.7,2.3-1.4,3.4-2.2c0.5-0.3,1.1-0.7,1.6-1c1.6-1.1,3.3-2.2,1.6-2.3L98.7,1L9.9,20.6l21.5,96.5
			c24-5.3,32.7-8,55.7-15.4c0,0,0,0-0.1,0C87.6,101.5,88,101.3,88.5,101.1z"/>
	</g>
</g>
<path class="st15" d="M0,22.4L101.9,0l21.5,81c-28.1,19.5-61.8,33.1-98.4,43.8L0,22.4z"/>
<path class="st16p" d="M123.4,81c-11.3,7.8-23.4,14.7-36.3,20.8c10.4-8.2,22.7-13,29.4-27.8C119,75.9,121.5,77.9,123.4,81z"/>
</svg>
