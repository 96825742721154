import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AboutMeComponent} from './pages/about-me/about-me.component';
import {AtWorkComponent} from './pages/at-work/at-work.component';
import {LanguagesComponent} from './pages/languages/languages.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {ContactComponent} from './pages/contact/contact.component';


const routes: Routes = [
	//{ path: '', redirectTo: '/about-me', pathMatch: 'full' },
	{ path: '', component: AboutMeComponent },
	{ path: 'at-work', component: AtWorkComponent },
	{ path: 'languages', component: LanguagesComponent },
	{ path: 'portfolio', component: PortfolioComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: '**', redirectTo: '/about-me' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
