<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 533.014 307.92" enable-background="new 0 0 533.014 307.92"
	 xml:space="preserve">
	<image overflow="visible" opacity="0.35" width="1905" height="1283" xlink:href="/assets/laptop-shadow.png"  transform="matrix(0.24 0 0 0.24 25.1455 0)"></image>
	<g>
			<path fill="#8A8989" d="M308.135,284.205l-0.619-0.809c-0.267-0.353-0.849-0.938-1.721-0.938h-78.129
				c-0.87,0-1.452,0.586-1.719,0.938l-0.622,0.809H67.053c-6.54,0-11.86-5.322-11.86-11.862V22.348c0-6.54,5.32-11.859,11.86-11.859
				H466.41c6.54,0,11.861,5.319,11.861,11.859v249.995c0,6.54-5.322,11.862-11.861,11.862H308.135z"/>
			<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="96.6895" y1="-22.0693" x2="436.7726" y2="318.0138">
				<stop  offset="0" style="stop-color:#B5B4B4"/>
				<stop  offset="0.4361" style="stop-color:#A4A3A3"/>
				<stop  offset="1" style="stop-color:#8A8989"/>
			</linearGradient>
			<path fill="url(#SVGID_1_)" d="M467.323,12.56H66.139c-5.438,0-9.844,4.405-9.844,9.843v251.139c0,5.437,4.407,9.844,9.844,9.844
				h157.975c0.815-1.065,2.014-1.755,3.374-1.755h78.488c1.357,0,2.556,0.69,3.373,1.755h157.975c5.438,0,9.843-4.408,9.843-9.844
				V22.402C477.166,16.964,472.761,12.56,467.323,12.56z"/>
			<path fill="#9F9E9D" d="M471.034,184.337c0,3.134-2.201,5.675-4.916,5.675H67.343c-2.713,0-4.914-2.541-4.914-5.675V33.692
				c0-3.135,2.201-5.675,4.914-5.675h398.774c2.715,0,4.916,2.54,4.916,5.675V184.337z"/>
			<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="63.4131" y1="109.0151" x2="470.0498" y2="109.0151">
				<stop  offset="0" style="stop-color:#B5B4B4"/>
				<stop  offset="0.4361" style="stop-color:#A4A3A3"/>
				<stop  offset="1" style="stop-color:#8A8989"/>
			</linearGradient>
			<path fill="url(#SVGID_2_)" d="M67.343,188.876c-2.167,0-3.93-2.036-3.93-4.539V33.692c0-2.503,1.763-4.538,3.93-4.538H466.12
				c2.167,0,3.93,2.035,3.93,4.538v150.645c0,2.503-1.763,4.539-3.93,4.539H67.343z"/>
			<path fill="#9F9E9D" d="M330.504,268.82c0,2.898-2.201,5.248-4.919,5.248H202.713c-2.716,0-4.919-2.35-4.919-5.248v-71.174
				c0-2.896,2.203-5.247,4.919-5.247h122.872c2.718,0,4.919,2.352,4.919,5.247V268.82z"/>
			<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="231.0156" y1="175.8706" x2="296.8301" y2="289.8646">
				<stop  offset="0" style="stop-color:#AAA9A9"/>
				<stop  offset="1" style="stop-color:#AFAEAE"/>
			</linearGradient>
			<path fill="url(#SVGID_3_)" d="M329.521,267.916c0,2.854-2.179,5.167-4.867,5.167H203.19c-2.686,0-4.867-2.313-4.867-5.167v-70.1
				c0-2.854,2.181-5.166,4.867-5.166h121.463c2.688,0,4.867,2.312,4.867,5.166V267.916z"/>
			<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="219.4902" y1="-64.001" x2="313.9727" y2="99.6474">
				<stop  offset="0" style="stop-color:#4C4C4B"/>
				<stop  offset="1" style="stop-color:#181715"/>
			</linearGradient>
			<rect x="86.882" y="12.56" fill="url(#SVGID_4_)" width="359.698" height="10.526"/>
			<g>
				<g>
					<g>
						<path fill="#1F191A" d="M121.447,70.71c0,1.86-1.51,3.371-3.372,3.371h-17.798c-1.862,0-3.371-1.511-3.371-3.371v-17.8
							c0-1.862,1.509-3.372,3.371-3.372h17.798c1.862,0,3.372,1.509,3.372,3.372V70.71z"/>
						<path fill="#57585B" d="M118.075,74.363h-17.798c-2.013,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.64-3.65,3.653-3.65h17.798
							c2.015,0,3.653,1.637,3.653,3.65v17.8C121.729,72.724,120.09,74.363,118.075,74.363z M100.277,49.82
							c-1.703,0-3.091,1.388-3.091,3.09v17.8c0,1.703,1.388,3.09,3.091,3.09h17.798c1.705,0,3.091-1.387,3.091-3.09v-17.8
							c0-1.703-1.386-3.09-3.091-3.09H100.277z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M94.095,70.71c0,1.86-1.51,3.371-3.373,3.371H72.925c-1.862,0-3.372-1.511-3.372-3.371v-17.8
							c0-1.862,1.509-3.372,3.372-3.372h17.797c1.863,0,3.373,1.509,3.373,3.372V70.71z"/>
						<path fill="#57585B" d="M90.723,74.363H72.925c-2.014,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.639-3.65,3.653-3.65h17.797
							c2.016,0,3.653,1.637,3.653,3.65v17.8C94.375,72.724,92.738,74.363,90.723,74.363z M72.925,49.82
							c-1.705,0-3.09,1.388-3.09,3.09v17.8c0,1.703,1.386,3.09,3.09,3.09h17.797c1.706,0,3.091-1.387,3.091-3.09v-17.8
							c0-1.703-1.386-3.09-3.091-3.09H72.925z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M176.151,70.71c0,1.86-1.509,3.371-3.372,3.371h-17.797c-1.862,0-3.373-1.511-3.373-3.371v-17.8
							c0-1.862,1.511-3.372,3.373-3.372h17.797c1.863,0,3.372,1.509,3.372,3.372V70.71z"/>
						<path fill="#57585B" d="M172.779,74.363h-17.797c-2.014,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.64-3.65,3.653-3.65h17.797
							c2.014,0,3.653,1.637,3.653,3.65v17.8C176.432,72.724,174.792,74.363,172.779,74.363z M154.982,49.82
							c-1.706,0-3.091,1.388-3.091,3.09v17.8c0,1.703,1.386,3.09,3.091,3.09h17.797c1.704,0,3.092-1.387,3.092-3.09v-17.8
							c0-1.703-1.388-3.09-3.092-3.09H154.982z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M148.8,70.71c0,1.86-1.51,3.371-3.372,3.371h-17.799c-1.862,0-3.372-1.511-3.372-3.371v-17.8
							c0-1.862,1.51-3.372,3.372-3.372h17.799c1.862,0,3.372,1.509,3.372,3.372V70.71z"/>
						<path fill="#57585B" d="M145.428,74.363h-17.799c-2.014,0-3.652-1.64-3.652-3.653v-17.8c0-2.013,1.639-3.65,3.652-3.65h17.799
							c2.014,0,3.653,1.637,3.653,3.65v17.8C149.081,72.724,147.442,74.363,145.428,74.363z M127.629,49.82
							c-1.704,0-3.09,1.388-3.09,3.09v17.8c0,1.703,1.386,3.09,3.09,3.09h17.799c1.704,0,3.09-1.387,3.09-3.09v-17.8
							c0-1.703-1.387-3.09-3.09-3.09H127.629z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M231.043,70.71c0,1.86-1.51,3.371-3.374,3.371h-17.796c-1.863,0-3.374-1.511-3.374-3.371v-17.8
							c0-1.862,1.511-3.372,3.374-3.372h17.796c1.863,0,3.374,1.509,3.374,3.372V70.71z"/>
						<path fill="#57585B" d="M227.67,74.363h-17.796c-2.014,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.639-3.65,3.653-3.65h17.796
							c2.014,0,3.653,1.637,3.653,3.65v17.8C231.323,72.724,229.684,74.363,227.67,74.363z M209.874,49.82
							c-1.705,0-3.091,1.388-3.091,3.09v17.8c0,1.703,1.386,3.09,3.091,3.09h17.796c1.705,0,3.091-1.387,3.091-3.09v-17.8
							c0-1.703-1.386-3.09-3.091-3.09H209.874z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M203.692,70.71c0,1.86-1.511,3.371-3.375,3.371h-17.797c-1.862,0-3.371-1.511-3.371-3.371v-17.8
							c0-1.862,1.509-3.372,3.371-3.372h17.797c1.863,0,3.375,1.509,3.375,3.372V70.71z"/>
						<path fill="#57585B" d="M200.318,74.363h-17.797c-2.013,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.64-3.65,3.653-3.65h17.797
							c2.015,0,3.653,1.637,3.653,3.65v17.8C203.971,72.724,202.333,74.363,200.318,74.363z M182.521,49.82
							c-1.705,0-3.09,1.388-3.09,3.09v17.8c0,1.703,1.386,3.09,3.09,3.09h17.797c1.708,0,3.091-1.387,3.091-3.09v-17.8
							c0-1.703-1.383-3.09-3.091-3.09H182.521z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M285.748,70.71c0,1.86-1.51,3.371-3.375,3.371h-17.795c-1.861,0-3.372-1.511-3.372-3.371v-17.8
							c0-1.862,1.511-3.372,3.372-3.372h17.795c1.865,0,3.375,1.509,3.375,3.372V70.71z"/>
						<path fill="#57585B" d="M282.374,74.363h-17.795c-2.016,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.638-3.65,3.653-3.65h17.795
							c2.015,0,3.653,1.637,3.653,3.65v17.8C286.027,72.724,284.389,74.363,282.374,74.363z M264.578,49.82
							c-1.706,0-3.092,1.388-3.092,3.09v17.8c0,1.703,1.386,3.09,3.092,3.09h17.795c1.708,0,3.093-1.387,3.093-3.09v-17.8
							c0-1.703-1.386-3.09-3.093-3.09H264.578z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M258.396,70.71c0,1.86-1.511,3.371-3.374,3.371h-17.796c-1.863,0-3.373-1.511-3.373-3.371v-17.8
							c0-1.862,1.51-3.372,3.373-3.372h17.796c1.863,0,3.374,1.509,3.374,3.372V70.71z"/>
						<path fill="#57585B" d="M255.021,74.363h-17.796c-2.014,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.639-3.65,3.653-3.65h17.796
							c2.015,0,3.653,1.637,3.653,3.65v17.8C258.675,72.724,257.036,74.363,255.021,74.363z M237.226,49.82
							c-1.705,0-3.091,1.388-3.091,3.09v17.8c0,1.703,1.386,3.09,3.091,3.09h17.796c1.705,0,3.093-1.387,3.093-3.09v-17.8
							c0-1.703-1.388-3.09-3.093-3.09H237.226z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M313.285,70.71c0,1.86-1.508,3.371-3.369,3.371h-17.799c-1.863,0-3.372-1.511-3.372-3.371v-17.8
							c0-1.862,1.509-3.372,3.372-3.372h17.799c1.861,0,3.369,1.509,3.369,3.372V70.71z"/>
						<path fill="#57585B" d="M309.916,74.363h-17.799c-2.016,0-3.655-1.64-3.655-3.653v-17.8c0-2.013,1.639-3.65,3.655-3.65h17.799
							c2.013,0,3.652,1.637,3.652,3.65v17.8C313.568,72.724,311.929,74.363,309.916,74.363z M292.117,49.82
							c-1.705,0-3.092,1.388-3.092,3.09v17.8c0,1.703,1.387,3.09,3.092,3.09h17.799c1.703,0,3.091-1.387,3.091-3.09v-17.8
							c0-1.703-1.388-3.09-3.091-3.09H292.117z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M368.176,70.71c0,1.86-1.506,3.371-3.37,3.371h-17.796c-1.864,0-3.374-1.511-3.374-3.371v-17.8
							c0-1.862,1.51-3.372,3.374-3.372h17.796c1.864,0,3.37,1.509,3.37,3.372V70.71z"/>
						<path fill="#57585B" d="M364.806,74.363h-17.796c-2.015,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.639-3.65,3.653-3.65h17.796
							c2.014,0,3.653,1.637,3.653,3.65v17.8C368.458,72.724,366.82,74.363,364.806,74.363z M347.009,49.82
							c-1.706,0-3.091,1.388-3.091,3.09v17.8c0,1.703,1.385,3.09,3.091,3.09h17.796c1.705,0,3.09-1.387,3.09-3.09v-17.8
							c0-1.703-1.385-3.09-3.09-3.09H347.009z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M340.828,70.71c0,1.86-1.513,3.371-3.374,3.371h-17.799c-1.862,0-3.372-1.511-3.372-3.371v-17.8
							c0-1.862,1.51-3.372,3.372-3.372h17.799c1.861,0,3.374,1.509,3.374,3.372V70.71z"/>
						<path fill="#57585B" d="M337.454,74.363h-17.799c-2.012,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.641-3.65,3.653-3.65h17.799
							c2.015,0,3.653,1.637,3.653,3.65v17.8C341.106,72.724,339.468,74.363,337.454,74.363z M319.655,49.82
							c-1.704,0-3.091,1.388-3.091,3.09v17.8c0,1.703,1.387,3.09,3.091,3.09h17.799c1.703,0,3.09-1.387,3.09-3.09v-17.8
							c0-1.703-1.387-3.09-3.09-3.09H319.655z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M422.881,70.71c0,1.86-1.507,3.371-3.372,3.371h-17.796c-1.864,0-3.375-1.511-3.375-3.371v-17.8
							c0-1.862,1.511-3.372,3.375-3.372h17.796c1.865,0,3.372,1.509,3.372,3.372V70.71z"/>
						<path fill="#57585B" d="M419.509,74.363h-17.796c-2.015,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.638-3.65,3.653-3.65h17.796
							c2.015,0,3.653,1.637,3.653,3.65v17.8C423.163,72.724,421.524,74.363,419.509,74.363z M401.713,49.82
							c-1.704,0-3.092,1.388-3.092,3.09v17.8c0,1.703,1.388,3.09,3.092,3.09h17.796c1.705,0,3.091-1.387,3.091-3.09v-17.8
							c0-1.703-1.386-3.09-3.091-3.09H401.713z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M395.531,70.71c0,1.86-1.511,3.371-3.374,3.371H374.36c-1.863,0-3.373-1.511-3.373-3.371v-17.8
							c0-1.862,1.51-3.372,3.373-3.372h17.797c1.863,0,3.374,1.509,3.374,3.372V70.71z"/>
						<path fill="#57585B" d="M392.157,74.363H374.36c-2.016,0-3.654-1.64-3.654-3.653v-17.8c0-2.013,1.638-3.65,3.654-3.65h17.797
							c2.015,0,3.653,1.637,3.653,3.65v17.8C395.81,72.724,394.172,74.363,392.157,74.363z M374.36,49.82
							c-1.707,0-3.09,1.388-3.09,3.09v17.8c0,1.703,1.384,3.09,3.09,3.09h17.797c1.705,0,3.091-1.387,3.091-3.09v-17.8
							c0-1.703-1.386-3.09-3.091-3.09H374.36z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M354.316,97.126c0,1.862-1.514,3.371-3.375,3.371h-17.798c-1.863,0-3.372-1.508-3.372-3.371V79.327
							c0-1.859,1.509-3.37,3.372-3.37h17.798c1.861,0,3.375,1.511,3.375,3.37V97.126z"/>
						<path fill="#57585B" d="M350.941,100.778h-17.798c-2.013,0-3.653-1.639-3.653-3.652V79.327c0-2.014,1.641-3.654,3.653-3.654
							h17.798c2.015,0,3.654,1.64,3.654,3.654v17.799C354.595,99.14,352.956,100.778,350.941,100.778z M333.143,76.236
							c-1.703,0-3.091,1.387-3.091,3.091v17.799c0,1.704,1.388,3.09,3.091,3.09h17.798c1.706,0,3.091-1.386,3.091-3.09V79.327
							c0-1.704-1.385-3.091-3.091-3.091H333.143z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M409.206,97.126c0,1.862-1.509,3.371-3.374,3.371h-17.797c-1.861,0-3.369-1.508-3.369-3.371V79.327
							c0-1.859,1.508-3.37,3.369-3.37h17.797c1.865,0,3.374,1.511,3.374,3.37V97.126z"/>
						<path fill="#57585B" d="M405.832,100.778h-17.797c-2.015,0-3.652-1.639-3.652-3.652V79.327c0-2.014,1.637-3.654,3.652-3.654
							h17.797c2.016,0,3.653,1.64,3.653,3.654v17.799C409.484,99.14,407.848,100.778,405.832,100.778z M388.035,76.236
							c-1.703,0-3.091,1.387-3.091,3.091v17.799c0,1.704,1.388,3.09,3.091,3.09h17.797c1.707,0,3.093-1.386,3.093-3.09V79.327
							c0-1.704-1.386-3.091-3.093-3.091H388.035z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M381.853,97.126c0,1.862-1.509,3.371-3.372,3.371h-17.798c-1.862,0-3.373-1.508-3.373-3.371V79.327
							c0-1.859,1.511-3.37,3.373-3.37h17.798c1.863,0,3.372,1.511,3.372,3.37V97.126z"/>
						<path fill="#57585B" d="M378.481,100.778h-17.798c-2.015,0-3.654-1.639-3.654-3.652V79.327c0-2.014,1.639-3.654,3.654-3.654
							h17.798c2.015,0,3.655,1.64,3.655,3.654v17.799C382.137,99.14,380.496,100.778,378.481,100.778z M360.684,76.236
							c-1.704,0-3.092,1.387-3.092,3.091v17.799c0,1.704,1.388,3.09,3.092,3.09h17.798c1.705,0,3.093-1.386,3.093-3.09V79.327
							c0-1.704-1.388-3.091-3.093-3.091H360.684z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M463.909,97.126c0,1.862-1.509,3.371-3.375,3.371h-17.794c-1.863,0-3.374-1.508-3.374-3.371V79.327
							c0-1.859,1.511-3.37,3.374-3.37h17.794c1.866,0,3.375,1.511,3.375,3.37V97.126z"/>
						<path fill="#57585B" d="M460.535,100.778h-17.794c-2.017,0-3.655-1.639-3.655-3.652V79.327c0-2.014,1.639-3.654,3.655-3.654
							h17.794c2.018,0,3.655,1.64,3.655,3.654v17.799C464.19,99.14,462.552,100.778,460.535,100.778z M442.741,76.236
							c-1.706,0-3.093,1.387-3.093,3.091v17.799c0,1.704,1.387,3.09,3.093,3.09h17.794c1.708,0,3.095-1.386,3.095-3.09V79.327
							c0-1.704-1.388-3.091-3.095-3.091H442.741z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M436.557,97.126c0,1.862-1.509,3.371-3.373,3.371h-17.798c-1.861,0-3.372-1.508-3.372-3.371V79.327
							c0-1.859,1.51-3.37,3.372-3.37h17.798c1.863,0,3.373,1.511,3.373,3.37V97.126z"/>
						<path fill="#57585B" d="M433.185,100.778h-17.798c-2.012,0-3.653-1.639-3.653-3.652V79.327c0-2.014,1.641-3.654,3.653-3.654
							h17.798c2.013,0,3.654,1.64,3.654,3.654v17.799C436.838,99.14,435.197,100.778,433.185,100.778z M415.387,76.236
							c-1.703,0-3.09,1.387-3.09,3.091v17.799c0,1.704,1.388,3.09,3.09,3.09h17.798c1.706,0,3.093-1.386,3.093-3.09V79.327
							c0-1.704-1.388-3.091-3.093-3.091H415.387z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M217.18,97.126c0,1.862-1.509,3.371-3.374,3.371H196.01c-1.863,0-3.374-1.508-3.374-3.371V79.327
							c0-1.859,1.511-3.37,3.374-3.37h17.796c1.864,0,3.374,1.511,3.374,3.37V97.126z"/>
						<path fill="#57585B" d="M213.806,100.778H196.01c-2.015,0-3.653-1.639-3.653-3.652V79.327c0-2.014,1.638-3.654,3.653-3.654
							h17.796c2.014,0,3.653,1.64,3.653,3.654v17.799C217.459,99.14,215.82,100.778,213.806,100.778z M196.01,76.236
							c-1.706,0-3.091,1.387-3.091,3.091v17.799c0,1.704,1.386,3.09,3.091,3.09h17.796c1.705,0,3.091-1.386,3.091-3.09V79.327
							c0-1.704-1.386-3.091-3.091-3.091H196.01z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M272.07,97.126c0,1.862-1.51,3.371-3.372,3.371h-17.799c-1.861,0-3.37-1.508-3.37-3.371V79.327
							c0-1.859,1.509-3.37,3.37-3.37h17.799c1.862,0,3.372,1.511,3.372,3.37V97.126z"/>
						<path fill="#57585B" d="M268.698,100.778h-17.799c-2.015,0-3.652-1.639-3.652-3.652V79.327c0-2.014,1.638-3.654,3.652-3.654
							h17.799c2.014,0,3.654,1.64,3.654,3.654v17.799C272.353,99.14,270.712,100.778,268.698,100.778z M250.899,76.236
							c-1.703,0-3.091,1.387-3.091,3.091v17.799c0,1.704,1.388,3.09,3.091,3.09h17.799c1.704,0,3.092-1.386,3.092-3.09V79.327
							c0-1.704-1.388-3.091-3.092-3.091H250.899z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M244.718,97.126c0,1.862-1.509,3.371-3.37,3.371h-17.801c-1.861,0-3.37-1.508-3.37-3.371V79.327
							c0-1.859,1.508-3.37,3.37-3.37h17.801c1.861,0,3.37,1.511,3.37,3.37V97.126z"/>
						<path fill="#57585B" d="M241.348,100.778h-17.801c-2.015,0-3.653-1.639-3.653-3.652V79.327c0-2.014,1.638-3.654,3.653-3.654
							h17.801c2.013,0,3.653,1.64,3.653,3.654v17.799C245.001,99.14,243.361,100.778,241.348,100.778z M223.547,76.236
							c-1.704,0-3.091,1.387-3.091,3.091v17.799c0,1.704,1.387,3.09,3.091,3.09h17.801c1.704,0,3.091-1.386,3.091-3.09V79.327
							c0-1.704-1.387-3.091-3.091-3.091H223.547z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M135.123,97.126c0,1.862-1.509,3.371-3.373,3.371h-17.797c-1.862,0-3.372-1.508-3.372-3.371V79.327
							c0-1.859,1.51-3.37,3.372-3.37h17.797c1.864,0,3.373,1.511,3.373,3.37V97.126z"/>
						<path fill="#57585B" d="M131.75,100.778h-17.797c-2.015,0-3.652-1.639-3.652-3.652V79.327c0-2.014,1.638-3.654,3.652-3.654
							h17.797c2.016,0,3.654,1.64,3.654,3.654v17.799C135.404,99.14,133.766,100.778,131.75,100.778z M113.953,76.236
							c-1.704,0-3.09,1.387-3.09,3.091v17.799c0,1.704,1.386,3.09,3.09,3.09h17.797c1.706,0,3.091-1.386,3.091-3.09V79.327
							c0-1.704-1.386-3.091-3.091-3.091H113.953z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M190.014,97.126c0,1.862-1.509,3.371-3.372,3.371h-17.797c-1.862,0-3.373-1.508-3.373-3.371V79.327
							c0-1.859,1.511-3.37,3.373-3.37h17.797c1.863,0,3.372,1.511,3.372,3.37V97.126z"/>
						<path fill="#57585B" d="M186.642,100.778h-17.797c-2.014,0-3.653-1.639-3.653-3.652V79.327c0-2.014,1.639-3.654,3.653-3.654
							h17.797c2.015,0,3.653,1.64,3.653,3.654v17.799C190.295,99.14,188.657,100.778,186.642,100.778z M168.845,76.236
							c-1.705,0-3.09,1.387-3.09,3.091v17.799c0,1.704,1.385,3.09,3.09,3.09h17.797c1.706,0,3.091-1.386,3.091-3.09V79.327
							c0-1.704-1.386-3.091-3.091-3.091H168.845z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M162.662,97.126c0,1.862-1.509,3.371-3.372,3.371h-17.797c-1.863,0-3.374-1.508-3.374-3.371V79.327
							c0-1.859,1.511-3.37,3.374-3.37h17.797c1.862,0,3.372,1.511,3.372,3.37V97.126z"/>
						<path fill="#57585B" d="M159.29,100.778h-17.797c-2.015,0-3.653-1.639-3.653-3.652V79.327c0-2.014,1.639-3.654,3.653-3.654
							h17.797c2.015,0,3.653,1.64,3.653,3.654v17.799C162.943,99.14,161.305,100.778,159.29,100.778z M141.493,76.236
							c-1.705,0-3.092,1.387-3.092,3.091v17.799c0,1.704,1.387,3.09,3.092,3.09h17.797c1.704,0,3.091-1.386,3.091-3.09V79.327
							c0-1.704-1.387-3.091-3.091-3.091H141.493z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M326.774,97.126c0,1.862-1.51,3.371-3.37,3.371h-17.8c-1.862,0-3.37-1.508-3.37-3.371V79.327
							c0-1.859,1.508-3.37,3.37-3.37h17.8c1.859,0,3.37,1.511,3.37,3.37V97.126z"/>
						<path fill="#57585B" d="M323.404,100.778h-17.8c-2.014,0-3.651-1.639-3.651-3.652V79.327c0-2.014,1.637-3.654,3.651-3.654
							h17.8c2.013,0,3.653,1.64,3.653,3.654v17.799C327.057,99.14,325.417,100.778,323.404,100.778z M305.604,76.236
							c-1.704,0-3.089,1.387-3.089,3.091v17.799c0,1.704,1.385,3.09,3.089,3.09h17.8c1.702,0,3.09-1.386,3.09-3.09V79.327
							c0-1.704-1.388-3.091-3.09-3.091H305.604z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M299.422,97.126c0,1.862-1.51,3.371-3.37,3.371h-17.801c-1.861,0-3.37-1.508-3.37-3.371V79.327
							c0-1.859,1.508-3.37,3.37-3.37h17.801c1.86,0,3.37,1.511,3.37,3.37V97.126z"/>
						<path fill="#57585B" d="M296.052,100.778h-17.801c-2.014,0-3.651-1.639-3.651-3.652V79.327c0-2.014,1.637-3.654,3.651-3.654
							h17.801c2.013,0,3.651,1.64,3.651,3.654v17.799C299.703,99.14,298.064,100.778,296.052,100.778z M278.251,76.236
							c-1.703,0-3.09,1.387-3.09,3.091v17.799c0,1.704,1.387,3.09,3.09,3.09h17.801c1.703,0,3.091-1.386,3.091-3.09V79.327
							c0-1.704-1.388-3.091-3.091-3.091H278.251z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M360.967,124.103c0,1.861-1.512,3.372-3.375,3.372h-17.795c-1.861,0-3.374-1.511-3.374-3.372v-17.797
							c0-1.864,1.513-3.374,3.374-3.374h17.795c1.863,0,3.375,1.509,3.375,3.374V124.103z"/>
						<path fill="#57585B" d="M357.592,127.756h-17.795c-2.015,0-3.653-1.639-3.653-3.653v-17.797c0-2.014,1.639-3.652,3.653-3.652
							h17.795c2.015,0,3.653,1.639,3.653,3.652v17.797C361.245,126.117,359.606,127.756,357.592,127.756z M339.796,103.216
							c-1.705,0-3.091,1.383-3.091,3.09v17.797c0,1.704,1.386,3.091,3.091,3.091h17.795c1.704,0,3.094-1.388,3.094-3.091v-17.797
							c0-1.707-1.39-3.09-3.094-3.09H339.796z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M415.856,124.103c0,1.861-1.509,3.372-3.37,3.372h-17.801c-1.861,0-3.37-1.511-3.37-3.372v-17.797
							c0-1.864,1.509-3.374,3.37-3.374h17.801c1.861,0,3.37,1.509,3.37,3.374V124.103z"/>
						<path fill="#57585B" d="M412.486,127.756h-17.801c-2.012,0-3.653-1.639-3.653-3.653v-17.797c0-2.014,1.641-3.652,3.653-3.652
							h17.801c2.011,0,3.653,1.639,3.653,3.652v17.797C416.139,126.117,414.497,127.756,412.486,127.756z M394.686,103.216
							c-1.703,0-3.091,1.383-3.091,3.09v17.797c0,1.704,1.388,3.091,3.091,3.091h17.801c1.701,0,3.089-1.388,3.089-3.091v-17.797
							c0-1.707-1.388-3.09-3.089-3.09H394.686z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M388.504,124.103c0,1.861-1.509,3.372-3.372,3.372h-17.797c-1.862,0-3.371-1.511-3.371-3.372v-17.797
							c0-1.864,1.509-3.374,3.371-3.374h17.797c1.863,0,3.372,1.509,3.372,3.374V124.103z"/>
						<path fill="#57585B" d="M385.132,127.756h-17.797c-2.016,0-3.654-1.639-3.654-3.653v-17.797c0-2.014,1.639-3.652,3.654-3.652
							h17.797c2.015,0,3.653,1.639,3.653,3.652v17.797C388.785,126.117,387.147,127.756,385.132,127.756z M367.335,103.216
							c-1.704,0-3.092,1.383-3.092,3.09v17.797c0,1.704,1.388,3.091,3.092,3.091h17.797c1.705,0,3.09-1.388,3.09-3.091v-17.797
							c0-1.707-1.385-3.09-3.09-3.09H367.335z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M223.831,124.103c0,1.861-1.509,3.372-3.374,3.372h-17.796c-1.863,0-3.374-1.511-3.374-3.372v-17.797
							c0-1.864,1.511-3.374,3.374-3.374h17.796c1.865,0,3.374,1.509,3.374,3.374V124.103z"/>
						<path fill="#57585B" d="M220.457,127.756h-17.796c-2.015,0-3.653-1.639-3.653-3.653v-17.797c0-2.014,1.639-3.652,3.653-3.652
							h17.796c2.015,0,3.653,1.639,3.653,3.652v17.797C224.11,126.117,222.471,127.756,220.457,127.756z M202.661,103.216
							c-1.704,0-3.091,1.383-3.091,3.09v17.797c0,1.704,1.387,3.091,3.091,3.091h17.796c1.706,0,3.091-1.388,3.091-3.091v-17.797
							c0-1.707-1.385-3.09-3.091-3.09H202.661z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M278.721,124.103c0,1.861-1.509,3.372-3.37,3.372h-17.798c-1.864,0-3.372-1.511-3.372-3.372v-17.797
							c0-1.864,1.508-3.374,3.372-3.374h17.798c1.861,0,3.37,1.509,3.37,3.374V124.103z"/>
						<path fill="#57585B" d="M275.351,127.756h-17.798c-2.015,0-3.656-1.639-3.656-3.653v-17.797c0-2.014,1.641-3.652,3.656-3.652
							h17.798c2.013,0,3.653,1.639,3.653,3.652v17.797C279.003,126.117,277.364,127.756,275.351,127.756z M257.553,103.216
							c-1.706,0-3.094,1.383-3.094,3.09v17.797c0,1.704,1.388,3.091,3.094,3.091h17.798c1.703,0,3.09-1.388,3.09-3.091v-17.797
							c0-1.707-1.387-3.09-3.09-3.09H257.553z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M251.368,124.103c0,1.861-1.509,3.372-3.372,3.372h-17.797c-1.861,0-3.371-1.511-3.371-3.372v-17.797
							c0-1.864,1.509-3.374,3.371-3.374h17.797c1.863,0,3.372,1.509,3.372,3.374V124.103z"/>
						<path fill="#57585B" d="M247.996,127.756h-17.797c-2.013,0-3.654-1.639-3.654-3.653v-17.797c0-2.014,1.641-3.652,3.654-3.652
							h17.797c2.018,0,3.653,1.639,3.653,3.652v17.797C251.649,126.117,250.014,127.756,247.996,127.756z M230.199,103.216
							c-1.703,0-3.091,1.383-3.091,3.09v17.797c0,1.704,1.388,3.091,3.091,3.091h17.797c1.706,0,3.093-1.388,3.093-3.091v-17.797
							c0-1.707-1.387-3.09-3.093-3.09H230.199z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M141.773,124.103c0,1.861-1.509,3.372-3.372,3.372h-17.796c-1.863,0-3.373-1.511-3.373-3.372v-17.797
							c0-1.864,1.51-3.374,3.373-3.374h17.796c1.863,0,3.372,1.509,3.372,3.374V124.103z"/>
						<path fill="#57585B" d="M138.401,127.756h-17.796c-2.016,0-3.654-1.639-3.654-3.653v-17.797c0-2.014,1.638-3.652,3.654-3.652
							h17.796c2.015,0,3.654,1.639,3.654,3.652v17.797C142.055,126.117,140.416,127.756,138.401,127.756z M120.604,103.216
							c-1.705,0-3.092,1.383-3.092,3.09v17.797c0,1.704,1.387,3.091,3.092,3.091h17.796c1.705,0,3.092-1.388,3.092-3.091v-17.797
							c0-1.707-1.387-3.09-3.092-3.09H120.604z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M196.665,124.103c0,1.861-1.509,3.372-3.372,3.372h-17.797c-1.863,0-3.372-1.511-3.372-3.372v-17.797
							c0-1.864,1.509-3.374,3.372-3.374h17.797c1.863,0,3.372,1.509,3.372,3.374V124.103z"/>
						<path fill="#57585B" d="M193.293,127.756h-17.797c-2.015,0-3.653-1.639-3.653-3.653v-17.797c0-2.014,1.638-3.652,3.653-3.652
							h17.797c2.015,0,3.653,1.639,3.653,3.652v17.797C196.946,126.117,195.308,127.756,193.293,127.756z M175.496,103.216
							c-1.705,0-3.091,1.383-3.091,3.09v17.797c0,1.704,1.387,3.091,3.091,3.091h17.797c1.705,0,3.093-1.388,3.093-3.091v-17.797
							c0-1.707-1.388-3.09-3.093-3.09H175.496z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M169.313,124.103c0,1.861-1.51,3.372-3.372,3.372h-17.797c-1.864,0-3.373-1.511-3.373-3.372v-17.797
							c0-1.864,1.509-3.374,3.373-3.374h17.797c1.862,0,3.372,1.509,3.372,3.374V124.103z"/>
						<path fill="#57585B" d="M165.941,127.756h-17.797c-2.015,0-3.654-1.639-3.654-3.653v-17.797c0-2.014,1.639-3.652,3.654-3.652
							h17.797c2.014,0,3.653,1.639,3.653,3.652v17.797C169.595,126.117,167.956,127.756,165.941,127.756z M148.144,103.216
							c-1.705,0-3.091,1.383-3.091,3.09v17.797c0,1.704,1.386,3.091,3.091,3.091h17.797c1.704,0,3.092-1.388,3.092-3.091v-17.797
							c0-1.707-1.388-3.09-3.092-3.09H148.144z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M333.424,124.103c0,1.861-1.509,3.372-3.372,3.372h-17.796c-1.863,0-3.372-1.511-3.372-3.372v-17.797
							c0-1.864,1.509-3.374,3.372-3.374h17.796c1.863,0,3.372,1.509,3.372,3.374V124.103z"/>
						<path fill="#57585B" d="M330.052,127.756h-17.796c-2.015,0-3.656-1.639-3.656-3.653v-17.797c0-2.014,1.641-3.652,3.656-3.652
							h17.796c2.017,0,3.655,1.639,3.655,3.652v17.797C333.708,126.117,332.069,127.756,330.052,127.756z M312.256,103.216
							c-1.705,0-3.091,1.383-3.091,3.09v17.797c0,1.704,1.386,3.091,3.091,3.091h17.796c1.706,0,3.091-1.388,3.091-3.091v-17.797
							c0-1.707-1.385-3.09-3.091-3.09H312.256z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M306.073,124.103c0,1.861-1.51,3.372-3.371,3.372h-17.8c-1.861,0-3.369-1.511-3.369-3.372v-17.797
							c0-1.864,1.508-3.374,3.369-3.374h17.8c1.861,0,3.371,1.509,3.371,3.374V124.103z"/>
						<path fill="#57585B" d="M302.702,127.756h-17.8c-2.013,0-3.65-1.639-3.65-3.653v-17.797c0-2.014,1.637-3.652,3.65-3.652h17.8
							c2.015,0,3.653,1.639,3.653,3.652v17.797C306.355,126.117,304.717,127.756,302.702,127.756z M284.902,103.216
							c-1.703,0-3.091,1.383-3.091,3.09v17.797c0,1.704,1.388,3.091,3.091,3.091h17.8c1.703,0,3.091-1.388,3.091-3.091v-17.797
							c0-1.707-1.388-3.09-3.091-3.09H284.902z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M374.547,150.8c0,1.86-1.51,3.372-3.372,3.372h-17.798c-1.863,0-3.37-1.512-3.37-3.372v-17.796
							c0-1.866,1.507-3.375,3.37-3.375h17.798c1.862,0,3.372,1.509,3.372,3.375V150.8z"/>
						<path fill="#57585B" d="M371.175,154.453h-17.798c-2.013,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.641-3.654,3.653-3.654
							h17.798c2.015,0,3.653,1.638,3.653,3.654V150.8C374.828,152.813,373.19,154.453,371.175,154.453z M353.377,129.911
							c-1.704,0-3.091,1.385-3.091,3.093V150.8c0,1.704,1.387,3.091,3.091,3.091h17.798c1.705,0,3.091-1.388,3.091-3.091v-17.796
							c0-1.708-1.386-3.093-3.091-3.093H353.377z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M402.085,150.8c0,1.86-1.507,3.372-3.37,3.372h-17.799c-1.859,0-3.371-1.512-3.371-3.372v-17.796
							c0-1.866,1.512-3.375,3.371-3.375h17.799c1.863,0,3.37,1.509,3.37,3.375V150.8z"/>
						<path fill="#57585B" d="M398.715,154.453h-17.799c-2.014,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.639-3.654,3.653-3.654
							h17.799c2.015,0,3.653,1.638,3.653,3.654V150.8C402.368,152.813,400.73,154.453,398.715,154.453z M380.916,129.911
							c-1.705,0-3.088,1.385-3.088,3.093V150.8c0,1.704,1.383,3.091,3.088,3.091h17.799c1.705,0,3.09-1.388,3.09-3.091v-17.796
							c0-1.708-1.385-3.093-3.09-3.093H380.916z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M237.412,150.8c0,1.86-1.509,3.372-3.372,3.372h-17.798c-1.861,0-3.37-1.512-3.37-3.372v-17.796
							c0-1.866,1.509-3.375,3.37-3.375h17.798c1.863,0,3.372,1.509,3.372,3.375V150.8z"/>
						<path fill="#57585B" d="M234.04,154.453h-17.798c-2.013,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.641-3.654,3.653-3.654
							h17.798c2.015,0,3.653,1.638,3.653,3.654V150.8C237.693,152.813,236.055,154.453,234.04,154.453z M216.241,129.911
							c-1.703,0-3.091,1.385-3.091,3.093V150.8c0,1.704,1.388,3.091,3.091,3.091h17.798c1.706,0,3.093-1.388,3.093-3.091v-17.796
							c0-1.708-1.388-3.093-3.093-3.093H216.241z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M292.305,150.8c0,1.86-1.51,3.372-3.373,3.372h-17.797c-1.863,0-3.374-1.512-3.374-3.372v-17.796
							c0-1.866,1.511-3.375,3.374-3.375h17.797c1.863,0,3.373,1.509,3.373,3.375V150.8z"/>
						<path fill="#57585B" d="M288.932,154.453h-17.797c-2.015,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.638-3.654,3.653-3.654
							h17.797c2.014,0,3.653,1.638,3.653,3.654V150.8C292.585,152.813,290.946,154.453,288.932,154.453z M271.135,129.911
							c-1.705,0-3.091,1.385-3.091,3.093V150.8c0,1.704,1.386,3.091,3.091,3.091h17.797c1.704,0,3.091-1.388,3.091-3.091v-17.796
							c0-1.708-1.387-3.093-3.091-3.093H271.135z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M264.951,150.8c0,1.86-1.508,3.372-3.372,3.372h-17.796c-1.864,0-3.374-1.512-3.374-3.372v-17.796
							c0-1.866,1.51-3.375,3.374-3.375h17.796c1.864,0,3.372,1.509,3.372,3.375V150.8z"/>
						<path fill="#57585B" d="M261.579,154.453h-17.796c-2.015,0-3.652-1.64-3.652-3.653v-17.796c0-2.016,1.637-3.654,3.652-3.654
							h17.796c2.016,0,3.653,1.638,3.653,3.654V150.8C265.232,152.813,263.595,154.453,261.579,154.453z M243.783,129.911
							c-1.707,0-3.093,1.385-3.093,3.093V150.8c0,1.704,1.386,3.091,3.093,3.091h17.796c1.706,0,3.091-1.388,3.091-3.091v-17.796
							c0-1.708-1.386-3.093-3.091-3.093H243.783z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M155.356,150.8c0,1.86-1.51,3.372-3.372,3.372h-17.797c-1.862,0-3.373-1.512-3.373-3.372v-17.796
							c0-1.866,1.511-3.375,3.373-3.375h17.797c1.862,0,3.372,1.509,3.372,3.375V150.8z"/>
						<path fill="#57585B" d="M151.984,154.453h-17.797c-2.015,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.639-3.654,3.653-3.654
							h17.797c2.015,0,3.653,1.638,3.653,3.654V150.8C155.637,152.813,153.999,154.453,151.984,154.453z M134.187,129.911
							c-1.704,0-3.091,1.385-3.091,3.093V150.8c0,1.704,1.387,3.091,3.091,3.091h17.797c1.704,0,3.091-1.388,3.091-3.091v-17.796
							c0-1.708-1.387-3.093-3.091-3.093H134.187z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M94.095,180.417c0,2.111-1.51,3.824-3.373,3.824H72.925c-1.862,0-3.372-1.712-3.372-3.824v-20.175
							c0-2.111,1.509-3.823,3.372-3.823h17.797c1.863,0,3.373,1.711,3.373,3.823V180.417z"/>
						<path fill="#57585B" d="M90.723,184.521H72.925c-2.014,0-3.653-1.843-3.653-4.104v-20.175c0-2.261,1.639-4.102,3.653-4.102
							h17.797c2.016,0,3.653,1.841,3.653,4.102v20.175C94.375,182.678,92.738,184.521,90.723,184.521z M72.925,156.702
							c-1.705,0-3.09,1.586-3.09,3.54v20.175c0,1.954,1.386,3.543,3.09,3.543h17.797c1.706,0,3.091-1.589,3.091-3.543v-20.175
							c0-1.954-1.386-3.54-3.091-3.54H72.925z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M121.447,180.417c0,2.111-1.51,3.824-3.372,3.824h-17.798c-1.862,0-3.371-1.712-3.371-3.824v-20.175
							c0-2.111,1.509-3.823,3.371-3.823h17.798c1.862,0,3.372,1.711,3.372,3.823V180.417z"/>
						<path fill="#57585B" d="M118.075,184.521h-17.798c-2.013,0-3.653-1.843-3.653-4.104v-20.175c0-2.261,1.64-4.102,3.653-4.102
							h17.798c2.015,0,3.653,1.841,3.653,4.102v20.175C121.729,182.678,120.09,184.521,118.075,184.521z M100.277,156.702
							c-1.703,0-3.091,1.586-3.091,3.54v20.175c0,1.954,1.388,3.543,3.091,3.543h17.798c1.705,0,3.091-1.589,3.091-3.543v-20.175
							c0-1.954-1.386-3.54-3.091-3.54H100.277z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M148.8,180.417c0,2.111-1.51,3.824-3.372,3.824h-17.799c-1.862,0-3.372-1.712-3.372-3.824v-20.175
							c0-2.111,1.51-3.823,3.372-3.823h17.799c1.862,0,3.372,1.711,3.372,3.823V180.417z"/>
						<path fill="#57585B" d="M145.428,184.521h-17.799c-2.014,0-3.652-1.843-3.652-4.104v-20.175c0-2.261,1.639-4.102,3.652-4.102
							h17.799c2.014,0,3.653,1.841,3.653,4.102v20.175C149.081,182.678,147.442,184.521,145.428,184.521z M127.629,156.702
							c-1.704,0-3.09,1.586-3.09,3.54v20.175c0,1.954,1.386,3.543,3.09,3.543h17.799c1.704,0,3.09-1.589,3.09-3.543v-20.175
							c0-1.954-1.387-3.54-3.09-3.54H127.629z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M381.853,180.417c0,2.111-1.509,3.824-3.372,3.824h-17.798c-1.862,0-3.373-1.712-3.373-3.824v-20.175
							c0-2.111,1.511-3.823,3.373-3.823h17.798c1.863,0,3.372,1.711,3.372,3.823V180.417z"/>
						<path fill="#57585B" d="M378.481,184.521h-17.798c-2.015,0-3.654-1.843-3.654-4.104v-20.175c0-2.261,1.639-4.102,3.654-4.102
							h17.798c2.015,0,3.655,1.841,3.655,4.102v20.175C382.137,182.678,380.496,184.521,378.481,184.521z M360.684,156.702
							c-1.704,0-3.092,1.586-3.092,3.54v20.175c0,1.954,1.388,3.543,3.092,3.543h17.798c1.705,0,3.093-1.589,3.093-3.543v-20.175
							c0-1.954-1.388-3.54-3.093-3.54H360.684z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M210.248,150.8c0,1.86-1.511,3.372-3.372,3.372h-17.797c-1.862,0-3.374-1.512-3.374-3.372v-17.796
							c0-1.866,1.511-3.375,3.374-3.375h17.797c1.861,0,3.372,1.509,3.372,3.375V150.8z"/>
						<path fill="#57585B" d="M206.876,154.453h-17.797c-2.015,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.638-3.654,3.653-3.654
							h17.797c2.015,0,3.653,1.638,3.653,3.654V150.8C210.529,152.813,208.891,154.453,206.876,154.453z M189.079,129.911
							c-1.707,0-3.091,1.385-3.091,3.093V150.8c0,1.704,1.384,3.091,3.091,3.091h17.797c1.703,0,3.091-1.388,3.091-3.091v-17.796
							c0-1.708-1.388-3.093-3.091-3.093H189.079z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M182.896,150.8c0,1.86-1.51,3.372-3.373,3.372h-17.798c-1.862,0-3.371-1.512-3.371-3.372v-17.796
							c0-1.866,1.509-3.375,3.371-3.375h17.798c1.863,0,3.373,1.509,3.373,3.375V150.8z"/>
						<path fill="#57585B" d="M179.523,154.453h-17.798c-2.014,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.64-3.654,3.653-3.654
							h17.798c2.015,0,3.654,1.638,3.654,3.654V150.8C183.177,152.813,181.538,154.453,179.523,154.453z M161.726,129.911
							c-1.704,0-3.09,1.385-3.09,3.093V150.8c0,1.704,1.386,3.091,3.09,3.091h17.798c1.706,0,3.091-1.388,3.091-3.091v-17.796
							c0-1.708-1.386-3.093-3.091-3.093H161.726z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M347.009,150.8c0,1.86-1.511,3.372-3.374,3.372h-17.796c-1.863,0-3.375-1.512-3.375-3.372v-17.796
							c0-1.866,1.511-3.375,3.375-3.375h17.796c1.863,0,3.374,1.509,3.374,3.375V150.8z"/>
						<path fill="#57585B" d="M343.635,154.453h-17.796c-2.015,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.638-3.654,3.653-3.654
							h17.796c2.015,0,3.653,1.638,3.653,3.654V150.8C347.288,152.813,345.65,154.453,343.635,154.453z M325.839,129.911
							c-1.705,0-3.091,1.385-3.091,3.093V150.8c0,1.704,1.386,3.091,3.091,3.091h17.796c1.707,0,3.09-1.388,3.09-3.091v-17.796
							c0-1.708-1.383-3.093-3.09-3.093H325.839z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M319.655,150.8c0,1.86-1.509,3.372-3.372,3.372h-17.797c-1.862,0-3.372-1.512-3.372-3.372v-17.796
							c0-1.866,1.51-3.375,3.372-3.375h17.797c1.863,0,3.372,1.509,3.372,3.375V150.8z"/>
						<path fill="#57585B" d="M316.283,154.453h-17.797c-2.013,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.641-3.654,3.653-3.654
							h17.797c2.016,0,3.653,1.638,3.653,3.654V150.8C319.936,152.813,318.298,154.453,316.283,154.453z M298.485,129.911
							c-1.702,0-3.091,1.385-3.091,3.093V150.8c0,1.704,1.39,3.091,3.091,3.091h17.797c1.704,0,3.091-1.388,3.091-3.091v-17.796
							c0-1.708-1.388-3.093-3.091-3.093H298.485z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M94.095,43.734c0,1.86-1.51,3.369-3.373,3.369H72.925c-1.862,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.509-3.372,3.372-3.372h17.797c1.863,0,3.373,1.511,3.373,3.372V43.734z"/>
						<path fill="#57585B" d="M90.723,47.387H72.925c-2.014,0-3.653-1.64-3.653-3.653v-7.589c0-2.015,1.639-3.654,3.653-3.654
							h17.797c2.016,0,3.653,1.639,3.653,3.654v7.589C94.375,45.747,92.738,47.387,90.723,47.387z M72.925,33.055
							c-1.705,0-3.09,1.388-3.09,3.09v7.589c0,1.703,1.386,3.091,3.09,3.091h17.797c1.706,0,3.091-1.388,3.091-3.091v-7.589
							c0-1.703-1.386-3.09-3.091-3.09H72.925z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M122.852,43.734c0,1.86-1.51,3.369-3.372,3.369h-17.798c-1.862,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.51-3.372,3.372-3.372h17.798c1.862,0,3.372,1.511,3.372,3.372V43.734z"/>
						<path fill="#57585B" d="M119.48,47.387h-17.798c-2.015,0-3.653-1.64-3.653-3.653v-7.589c0-2.015,1.638-3.654,3.653-3.654
							h17.798c2.014,0,3.653,1.639,3.653,3.654v7.589C123.133,45.747,121.494,47.387,119.48,47.387z M101.682,33.055
							c-1.704,0-3.091,1.388-3.091,3.09v7.589c0,1.703,1.387,3.091,3.091,3.091h17.798c1.705,0,3.09-1.388,3.09-3.091v-7.589
							c0-1.703-1.386-3.09-3.09-3.09H101.682z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M150.956,43.734c0,1.86-1.511,3.369-3.374,3.369h-17.796c-1.863,0-3.374-1.509-3.374-3.369v-7.589
							c0-1.861,1.51-3.372,3.374-3.372h17.796c1.862,0,3.374,1.511,3.374,3.372V43.734z"/>
						<path fill="#57585B" d="M147.582,47.387h-17.796c-2.015,0-3.653-1.64-3.653-3.653v-7.589c0-2.015,1.638-3.654,3.653-3.654
							h17.796c2.015,0,3.653,1.639,3.653,3.654v7.589C151.235,45.747,149.597,47.387,147.582,47.387z M129.786,33.055
							c-1.705,0-3.093,1.388-3.093,3.09v7.589c0,1.703,1.388,3.091,3.093,3.091h17.796c1.706,0,3.091-1.388,3.091-3.091v-7.589
							c0-1.703-1.385-3.09-3.091-3.09H129.786z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M179.711,43.734c0,1.86-1.511,3.369-3.373,3.369h-17.797c-1.863,0-3.373-1.509-3.373-3.369v-7.589
							c0-1.861,1.51-3.372,3.373-3.372h17.797c1.862,0,3.373,1.511,3.373,3.372V43.734z"/>
						<path fill="#57585B" d="M176.338,47.387h-17.797c-2.014,0-3.654-1.64-3.654-3.653v-7.589c0-2.015,1.64-3.654,3.654-3.654
							h17.797c2.015,0,3.654,1.639,3.654,3.654v7.589C179.993,45.747,178.353,47.387,176.338,47.387z M158.542,33.055
							c-1.705,0-3.091,1.388-3.091,3.09v7.589c0,1.703,1.386,3.091,3.091,3.091h17.797c1.705,0,3.092-1.388,3.092-3.091v-7.589
							c0-1.703-1.387-3.09-3.092-3.09H158.542z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M207.58,43.734c0,1.86-1.51,3.369-3.374,3.369H186.41c-1.863,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.509-3.372,3.372-3.372h17.796c1.863,0,3.374,1.511,3.374,3.372V43.734z"/>
						<path fill="#57585B" d="M204.206,47.387H186.41c-2.015,0-3.654-1.64-3.654-3.653v-7.589c0-2.015,1.639-3.654,3.654-3.654
							h17.796c2.014,0,3.653,1.639,3.653,3.654v7.589C207.859,45.747,206.22,47.387,204.206,47.387z M186.41,33.055
							c-1.703,0-3.092,1.388-3.092,3.09v7.589c0,1.703,1.389,3.091,3.092,3.091h17.796c1.707,0,3.091-1.388,3.091-3.091v-7.589
							c0-1.703-1.384-3.09-3.091-3.09H186.41z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M236.336,43.734c0,1.86-1.509,3.369-3.375,3.369h-17.796c-1.863,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.509-3.372,3.372-3.372h17.796c1.865,0,3.375,1.511,3.375,3.372V43.734z"/>
						<path fill="#57585B" d="M232.961,47.387h-17.796c-2.015,0-3.653-1.64-3.653-3.653v-7.589c0-2.015,1.639-3.654,3.653-3.654
							h17.796c2.015,0,3.653,1.639,3.653,3.654v7.589C236.615,45.747,234.977,47.387,232.961,47.387z M215.165,33.055
							c-1.703,0-3.091,1.388-3.091,3.09v7.589c0,1.703,1.388,3.091,3.091,3.091h17.796c1.705,0,3.093-1.388,3.093-3.091v-7.589
							c0-1.703-1.388-3.09-3.093-3.09H215.165z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M264.67,43.734c0,1.86-1.509,3.369-3.37,3.369h-17.802c-1.86,0-3.369-1.509-3.369-3.369v-7.589
							c0-1.861,1.508-3.372,3.369-3.372h17.802c1.861,0,3.37,1.511,3.37,3.372V43.734z"/>
						<path fill="#57585B" d="M261.301,47.387h-17.802c-2.012,0-3.652-1.64-3.652-3.653v-7.589c0-2.015,1.64-3.654,3.652-3.654
							h17.802c2.013,0,3.65,1.639,3.65,3.654v7.589C264.951,45.747,263.313,47.387,261.301,47.387z M243.499,33.055
							c-1.702,0-3.09,1.388-3.09,3.09v7.589c0,1.703,1.388,3.091,3.09,3.091h17.802c1.703,0,3.09-1.388,3.09-3.091v-7.589
							c0-1.703-1.388-3.09-3.09-3.09H243.499z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M293.428,43.734c0,1.86-1.511,3.369-3.373,3.369h-17.798c-1.863,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.509-3.372,3.372-3.372h17.798c1.861,0,3.373,1.511,3.373,3.372V43.734z"/>
						<path fill="#57585B" d="M290.056,47.387h-17.798c-2.013,0-3.653-1.64-3.653-3.653v-7.589c0-2.015,1.641-3.654,3.653-3.654
							h17.798c2.013,0,3.654,1.639,3.654,3.654v7.589C293.709,45.747,292.069,47.387,290.056,47.387z M272.258,33.055
							c-1.706,0-3.091,1.388-3.091,3.09v7.589c0,1.703,1.385,3.091,3.091,3.091h17.798c1.704,0,3.091-1.388,3.091-3.091v-7.589
							c0-1.703-1.388-3.09-3.091-3.09H272.258z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M321.53,43.734c0,1.86-1.509,3.369-3.372,3.369H300.36c-1.861,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.51-3.372,3.372-3.372h17.797c1.863,0,3.372,1.511,3.372,3.372V43.734z"/>
						<path fill="#57585B" d="M318.158,47.387H300.36c-2.013,0-3.653-1.64-3.653-3.653v-7.589c0-2.015,1.64-3.654,3.653-3.654
							h17.797c2.013,0,3.652,1.639,3.652,3.654v7.589C321.81,45.747,320.17,47.387,318.158,47.387z M300.36,33.055
							c-1.703,0-3.09,1.388-3.09,3.09v7.589c0,1.703,1.388,3.091,3.09,3.091h17.797c1.704,0,3.094-1.388,3.094-3.091v-7.589
							c0-1.703-1.39-3.09-3.094-3.09H300.36z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M350.286,43.734c0,1.86-1.51,3.369-3.372,3.369h-17.796c-1.863,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.509-3.372,3.372-3.372h17.796c1.862,0,3.372,1.511,3.372,3.372V43.734z"/>
						<path fill="#57585B" d="M346.914,47.387h-17.796c-2.017,0-3.655-1.64-3.655-3.653v-7.589c0-2.015,1.638-3.654,3.655-3.654
							h17.796c2.017,0,3.654,1.639,3.654,3.654v7.589C350.568,45.747,348.931,47.387,346.914,47.387z M329.118,33.055
							c-1.705,0-3.092,1.388-3.092,3.09v7.589c0,1.703,1.388,3.091,3.092,3.091h17.796c1.706,0,3.093-1.388,3.093-3.091v-7.589
							c0-1.703-1.387-3.09-3.093-3.09H329.118z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M378.156,43.734c0,1.86-1.512,3.369-3.375,3.369h-17.796c-1.863,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.509-3.372,3.372-3.372h17.796c1.863,0,3.375,1.511,3.375,3.372V43.734z"/>
						<path fill="#57585B" d="M374.782,47.387h-17.796c-2.015,0-3.655-1.64-3.655-3.653v-7.589c0-2.015,1.64-3.654,3.655-3.654
							h17.796c2.015,0,3.653,1.639,3.653,3.654v7.589C378.435,45.747,376.797,47.387,374.782,47.387z M356.986,33.055
							c-1.704,0-3.092,1.388-3.092,3.09v7.589c0,1.703,1.388,3.091,3.092,3.091h17.796c1.706,0,3.091-1.388,3.091-3.091v-7.589
							c0-1.703-1.385-3.09-3.091-3.09H356.986z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M406.911,43.734c0,1.86-1.511,3.369-3.372,3.369h-17.798c-1.863,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.508-3.372,3.372-3.372h17.798c1.861,0,3.372,1.511,3.372,3.372V43.734z"/>
						<path fill="#57585B" d="M403.539,47.387h-17.798c-2.016,0-3.653-1.64-3.653-3.653v-7.589c0-2.015,1.637-3.654,3.653-3.654
							h17.798c2.013,0,3.653,1.639,3.653,3.654v7.589C407.192,45.747,405.552,47.387,403.539,47.387z M385.741,33.055
							c-1.703,0-3.091,1.388-3.091,3.09v7.589c0,1.703,1.388,3.091,3.091,3.091h17.798c1.701,0,3.089-1.388,3.089-3.091v-7.589
							c0-1.703-1.388-3.09-3.089-3.09H385.741z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M435.154,43.734c0,1.86-1.51,3.369-3.372,3.369h-17.799c-1.863,0-3.373-1.509-3.373-3.369v-7.589
							c0-1.861,1.509-3.372,3.373-3.372h17.799c1.862,0,3.372,1.511,3.372,3.372V43.734z"/>
						<path fill="#57585B" d="M431.782,47.387h-17.799c-2.016,0-3.654-1.64-3.654-3.653v-7.589c0-2.015,1.638-3.654,3.654-3.654
							h17.799c2.013,0,3.652,1.639,3.652,3.654v7.589C435.434,45.747,433.795,47.387,431.782,47.387z M413.983,33.055
							c-1.704,0-3.091,1.388-3.091,3.09v7.589c0,1.703,1.388,3.091,3.091,3.091h17.799c1.7,0,3.088-1.388,3.088-3.091v-7.589
							c0-1.703-1.388-3.09-3.088-3.09H413.983z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M463.909,43.734c0,1.86-1.509,3.369-3.375,3.369h-17.794c-1.863,0-3.372-1.509-3.372-3.369v-7.589
							c0-1.861,1.509-3.372,3.372-3.372h17.794c1.866,0,3.375,1.511,3.375,3.372V43.734z"/>
						<path fill="#57585B" d="M460.535,47.387h-17.794c-2.017,0-3.655-1.64-3.655-3.653v-7.589c0-2.015,1.639-3.654,3.655-3.654
							h17.794c2.018,0,3.655,1.639,3.655,3.654v7.589C464.19,45.747,462.552,47.387,460.535,47.387z M442.741,33.055
							c-1.706,0-3.093,1.388-3.093,3.09v7.589c0,1.703,1.387,3.091,3.093,3.091h17.794c1.708,0,3.095-1.388,3.095-3.091v-7.589
							c0-1.703-1.388-3.09-3.095-3.09H442.741z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M107.49,97.126c0,1.862-1.51,3.371-3.373,3.371H72.925c-1.862,0-3.372-1.508-3.372-3.371V79.327
							c0-1.859,1.509-3.37,3.372-3.37h31.192c1.863,0,3.373,1.511,3.373,3.37V97.126z"/>
						<path fill="#57585B" d="M104.118,100.778H72.925c-2.014,0-3.653-1.639-3.653-3.652V79.327c0-2.014,1.639-3.654,3.653-3.654
							h31.192c2.014,0,3.654,1.64,3.654,3.654v17.799C107.771,99.14,106.132,100.778,104.118,100.778z M72.925,76.236
							c-1.705,0-3.09,1.387-3.09,3.091v17.799c0,1.704,1.386,3.09,3.09,3.09h31.192c1.705,0,3.092-1.386,3.092-3.09V79.327
							c0-1.704-1.387-3.091-3.092-3.091H72.925z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M114.516,124.103c0,1.861-1.511,3.372-3.373,3.372H72.925c-1.862,0-3.372-1.511-3.372-3.372v-17.797
							c0-1.864,1.509-3.374,3.372-3.374h38.218c1.862,0,3.373,1.509,3.373,3.374V124.103z"/>
						<path fill="#57585B" d="M111.144,127.756H72.925c-2.014,0-3.653-1.639-3.653-3.653v-17.797c0-2.014,1.639-3.652,3.653-3.652
							h38.218c2.014,0,3.653,1.639,3.653,3.652v17.797C114.796,126.117,113.157,127.756,111.144,127.756z M72.925,103.216
							c-1.705,0-3.09,1.383-3.09,3.09v17.797c0,1.704,1.386,3.091,3.09,3.091h38.218c1.705,0,3.091-1.388,3.091-3.091v-17.797
							c0-1.707-1.387-3.09-3.091-3.09H72.925z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M128.566,150.8c0,1.86-1.51,3.372-3.372,3.372h-52.27c-1.862,0-3.372-1.512-3.372-3.372v-17.796
							c0-1.866,1.509-3.375,3.372-3.375h52.27c1.862,0,3.372,1.509,3.372,3.375V150.8z"/>
						<path fill="#57585B" d="M125.195,154.453h-52.27c-2.014,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.639-3.654,3.653-3.654
							h52.27c2.015,0,3.653,1.638,3.653,3.654V150.8C128.848,152.813,127.209,154.453,125.195,154.453z M72.925,129.911
							c-1.705,0-3.09,1.385-3.09,3.093V150.8c0,1.704,1.386,3.091,3.09,3.091h52.27c1.704,0,3.091-1.388,3.091-3.091v-17.796
							c0-1.708-1.387-3.093-3.091-3.093H72.925z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M182.896,180.868c0,1.862-1.51,3.373-3.373,3.373h-24.542c-1.862,0-3.373-1.51-3.373-3.373v-21.074
							c0-1.864,1.511-3.375,3.373-3.375h24.542c1.863,0,3.373,1.511,3.373,3.375V180.868z"/>
						<path fill="#57585B" d="M179.523,184.521h-24.542c-2.014,0-3.653-1.639-3.653-3.653v-21.074c0-2.016,1.64-3.654,3.653-3.654
							h24.542c2.015,0,3.654,1.639,3.654,3.654v21.074C183.177,182.882,181.538,184.521,179.523,184.521z M154.982,156.702
							c-1.706,0-3.091,1.384-3.091,3.092v21.074c0,1.705,1.386,3.092,3.091,3.092h24.542c1.706,0,3.091-1.387,3.091-3.092v-21.074
							c0-1.708-1.386-3.092-3.091-3.092H154.982z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M353.661,180.868c0,1.862-1.512,3.373-3.375,3.373h-24.542c-1.861,0-3.372-1.51-3.372-3.373v-21.074
							c0-1.864,1.511-3.375,3.372-3.375h24.542c1.862,0,3.375,1.511,3.375,3.375V180.868z"/>
						<path fill="#57585B" d="M350.286,184.521h-24.542c-2.013,0-3.652-1.639-3.652-3.653v-21.074c0-2.016,1.639-3.654,3.652-3.654
							h24.542c2.015,0,3.652,1.639,3.652,3.654v21.074C353.938,182.882,352.301,184.521,350.286,184.521z M325.744,156.702
							c-1.702,0-3.089,1.384-3.089,3.092v21.074c0,1.705,1.387,3.092,3.089,3.092h24.542c1.705,0,3.091-1.387,3.091-3.092v-21.074
							c0-1.708-1.386-3.092-3.091-3.092H325.744z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M463.909,70.71c0,1.86-1.509,3.371-3.375,3.371h-32.032c-1.863,0-3.374-1.511-3.374-3.371v-17.8
							c0-1.862,1.511-3.372,3.374-3.372h32.032c1.866,0,3.375,1.509,3.375,3.372V70.71z"/>
						<path fill="#57585B" d="M460.535,74.363h-32.032c-2.015,0-3.653-1.64-3.653-3.653v-17.8c0-2.013,1.638-3.65,3.653-3.65h32.032
							c2.018,0,3.655,1.637,3.655,3.65v17.8C464.19,72.724,462.552,74.363,460.535,74.363z M428.503,49.82
							c-1.706,0-3.091,1.388-3.091,3.09v17.8c0,1.703,1.385,3.09,3.091,3.09h32.032c1.708,0,3.095-1.387,3.095-3.09v-17.8
							c0-1.703-1.388-3.09-3.095-3.09H428.503z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M463.911,124.103c0,1.861-1.511,3.372-3.376,3.372h-38.591c-1.859,0-3.372-1.511-3.372-3.372v-17.797
							c0-1.864,1.512-3.374,3.372-3.374h38.591c1.866,0,3.376,1.509,3.376,3.374V124.103z"/>
						<path fill="#57585B" d="M460.535,127.756h-38.591c-2.013,0-3.653-1.639-3.653-3.653v-17.797c0-2.014,1.64-3.652,3.653-3.652
							h38.591c2.018,0,3.655,1.639,3.655,3.652v17.797C464.19,126.117,462.552,127.756,460.535,127.756z M421.944,103.216
							c-1.702,0-3.09,1.383-3.09,3.09v17.797c0,1.704,1.388,3.091,3.09,3.091h38.591c1.708,0,3.095-1.388,3.095-3.091v-17.797
							c0-1.707-1.388-3.09-3.095-3.09H421.944z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M463.909,150.8c0,1.86-1.509,3.372-3.375,3.372h-52.078c-1.863,0-3.37-1.512-3.37-3.372v-17.796
							c0-1.866,1.507-3.375,3.37-3.375h52.078c1.866,0,3.375,1.509,3.375,3.375V150.8z"/>
						<path fill="#57585B" d="M460.535,154.453h-52.078c-2.016,0-3.653-1.64-3.653-3.653v-17.796c0-2.016,1.637-3.654,3.653-3.654
							h52.078c2.018,0,3.655,1.638,3.655,3.654V150.8C464.19,152.813,462.552,154.453,460.535,154.453z M408.457,129.911
							c-1.706,0-3.091,1.385-3.091,3.093V150.8c0,1.704,1.385,3.091,3.091,3.091h52.078c1.708,0,3.095-1.388,3.095-3.091v-17.796
							c0-1.708-1.388-3.093-3.095-3.093H408.457z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M409.206,180.868c0,1.862-1.509,3.373-3.374,3.373h-17.797c-1.861,0-3.369-1.51-3.369-3.373v-7.167
							c0-1.86,1.508-3.372,3.369-3.372h17.797c1.865,0,3.374,1.511,3.374,3.372V180.868z"/>
						<path fill="#57585B" d="M405.832,184.521h-17.797c-2.015,0-3.652-1.639-3.652-3.653v-7.167c0-2.014,1.637-3.653,3.652-3.653
							h17.797c2.016,0,3.653,1.639,3.653,3.653v7.167C409.484,182.882,407.848,184.521,405.832,184.521z M388.035,170.61
							c-1.703,0-3.091,1.387-3.091,3.09v7.167c0,1.705,1.388,3.092,3.091,3.092h17.797c1.707,0,3.093-1.387,3.093-3.092v-7.167
							c0-1.704-1.386-3.09-3.093-3.09H388.035z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M463.909,180.868c0,1.862-1.509,3.373-3.375,3.373h-17.794c-1.863,0-3.374-1.51-3.374-3.373v-7.167
							c0-1.86,1.511-3.372,3.374-3.372h17.794c1.866,0,3.375,1.511,3.375,3.372V180.868z"/>
						<path fill="#57585B" d="M460.535,184.521h-17.794c-2.017,0-3.655-1.639-3.655-3.653v-7.167c0-2.014,1.639-3.653,3.655-3.653
							h17.794c2.018,0,3.655,1.639,3.655,3.653v7.167C464.19,182.882,462.552,184.521,460.535,184.521z M442.741,170.61
							c-1.706,0-3.093,1.387-3.093,3.09v7.167c0,1.705,1.387,3.092,3.093,3.092h17.794c1.708,0,3.095-1.387,3.095-3.092v-7.167
							c0-1.704-1.388-3.09-3.095-3.09H442.741z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M436.557,180.868c0,1.862-1.509,3.373-3.373,3.373h-17.798c-1.861,0-3.372-1.51-3.372-3.373v-21.074
							c0-1.864,1.51-3.375,3.372-3.375h17.798c1.863,0,3.373,1.511,3.373,3.375V180.868z"/>
						<path fill="#57585B" d="M433.185,184.521h-17.798c-2.012,0-3.653-1.639-3.653-3.653v-21.074c0-2.016,1.641-3.654,3.653-3.654
							h17.798c2.013,0,3.654,1.639,3.654,3.654v21.074C436.838,182.882,435.197,184.521,433.185,184.521z M415.387,156.702
							c-1.703,0-3.09,1.384-3.09,3.092v21.074c0,1.705,1.388,3.092,3.09,3.092h17.798c1.706,0,3.093-1.387,3.093-3.092v-21.074
							c0-1.708-1.388-3.092-3.093-3.092H415.387z"/>
					</g>
					<g>
						<path fill="#1F191A" d="M319.655,180.868c0,1.862-1.509,3.373-3.372,3.373H189.079c-1.862,0-3.374-1.51-3.374-3.373v-21.074
							c0-1.864,1.511-3.375,3.374-3.375h127.204c1.863,0,3.372,1.511,3.372,3.375V180.868z"/>
						<path fill="#57585B" d="M316.283,184.521H189.079c-2.015,0-3.653-1.639-3.653-3.653v-21.074c0-2.016,1.638-3.654,3.653-3.654
							h127.204c2.016,0,3.653,1.639,3.653,3.654v21.074C319.936,182.882,318.298,184.521,316.283,184.521z M189.079,156.702
							c-1.707,0-3.091,1.384-3.091,3.092v21.074c0,1.705,1.384,3.092,3.091,3.092h127.204c1.704,0,3.091-1.387,3.091-3.092v-21.074
							c0-1.708-1.388-3.092-3.091-3.092H189.079z"/>
					</g>
				</g>
			</g>
		</g>
			<g>
				<path fill="#F3F4F6" d="M506.456,0.979h-6.373H36.355l0,0c-1.135,0-2.255,0-3.419,0h-5.325c-8.012,0-23.939,3.058-27.605,7.583
					v1.926v0.92l3.375-0.617l527.572,0.339l2.062,0.278v-0.92V8.563C529.347,4.038,514.473,0.979,506.456,0.979z"/>
			</g>
	<rect x="0.933" y="10.489" fill="#404042" width="531.165" height="2.009"/>
	<path fill="#D4D5D7" d="M0,11.183h533.008V8.791H0V11.183z"/>
</svg>
