import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LaptopComponent} from './laptop.component';



@NgModule({
	declarations: [LaptopComponent],
	imports: [
		CommonModule
	],
	exports: [LaptopComponent],

})
export class LaptopModule { }
