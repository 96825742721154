
<coffee></coffee>
<laptop></laptop>
<postit></postit>
<glasses></glasses>
<div class="about-me-text">
	<p class="fs-78 tc-b fw-semi">Hello</p>
	<p class="fs-26 tc-b fw-medi mb-43">there visitor</p>

	<p class="fs-26 tc-b fw-medi">My name is Dimitris Antartis</p>
	<p class="fs-26">I'm a Web Developer and</p>
	<p class="fs-26 mb-25">I made this little page about me.</p>
	<p class="mb-25">I am currently living in Athens, Greece and I love coding on my MacBook!</p>
	<p class="mb-25">In my free-time, I usually go out with my friends but I also like watching Netflix when I'm alone.</p>
	<p class="">My favorite hobbies are spinning, swimming and sailing.</p>
</div>
<mario></mario>
<book></book>
<iphone></iphone>
<style>
	coffee {display: inline-block; position: absolute; width: 18.75%; left: 81%;top: 4.75vw;}
	mario {display: inline-block; position: absolute; width: 7.6%; left: 51.66%;top: 12vw;}
	laptop {display: inline-block; position: absolute; width: 44.4%; left: -10.83%; top: 4.33vw;}
	postit {display: inline-block; position: absolute; width: 12%; left: 18.66%; top: 23.4vw;}
	book {display: inline-block; position: absolute; width: 24.166%; left: 82%; top: 26.66vw;}
	iphone {display: inline-block; position: absolute; width: 19.5%; left: 11.66%; top: 46.66vw;}
	glasses {display: inline-block; position: absolute; width: 15%; left: 9.74%; top: 39.41vw;}

	.about-me-text {position: absolute; width:30.583%;left: 34.83%; top: 15vw;}

	.tc-b {color: #82BAAD;}

	.fs-78 {font-size: 3.714em; }
	.fs-26 {font-size: 1.23em; }
	.fs-21 {font-size: 1em; }

	.fw-regu {font-weight: 400}
	.fw-medi {font-weight: 500}
	.fw-semi {font-weight: 600}
	.fw-bold {font-weight: 700}

	.mb-25 {margin-bottom: 1.190em;}
	.mb-43 {margin-bottom: 2.047em;}
</style>
