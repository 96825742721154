import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IphoneComponent } from './iphone.component';



@NgModule({
	declarations: [IphoneComponent],
	imports: [
		CommonModule
	],
	exports:[IphoneComponent]
})
export class IphoneModule { }
