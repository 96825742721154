import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'glasses',
  templateUrl: './glasses.component.html',
  styleUrls: ['./glasses.component.scss']
})
export class GlassesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
