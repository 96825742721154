<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 181.1 127.8" style="enable-background:new 0 0 181.1 127.8;" xml:space="preserve">
<style type="text/css">
	.st9{fill:#FFFFFF;}
	.st16{fill:#444E54;}
	.st17{opacity:0.38;fill:#F4F3F3;}
	.st18{opacity:0.3;fill:#FFFFFF;}
</style>
<g>
	<image style="overflow:visible;opacity:0.45;" width="828" height="580" xlink:href="/assets/glasses-shadow.png"  transform="matrix(0.24 0 0 0.24 -9.5381 -7.8924)"></image>
	<g>
		<path class="st9" d="M156.2,8.4c3.5-1.6,6.7-2.6,9.5-3l-55,31.5c3.9-4.6,12-9.9,26.2-18.1c0.5-0.3,0.9-0.5,1.4-0.8
			C145,14.1,151.1,10.7,156.2,8.4z M107.8,45.2c11-5,22.7-9.3,31.2-10.9c10.8-1.9,10.7,1,18.6-0.1c7.9-1,13.6-9.3,10.2-8.8
			c-3.4,0.5-7.1-1.5-22-0.2c13.3-6.9,25-12.9,29-15c2.1,6.4,3.2,18.4,1.4,26.8c-2.2,9.9-37.3,28.8-45.3,29.1
			c-3.9,0.1-11.5-5-17.8-11.7C109.9,51.1,108.3,47.7,107.8,45.2z M16.2,91.8c1.4,0,2.7,0,4.1,0c5.5,6.4,15.5,13.6,22.1,16.5
			c7.5,3.2,27.1-4.6,40.6-12c6.2-3.4,11-6.7,12.7-9c3.3-4.5,3-15.3,1.3-23.6c-0.2-1.2-0.7-2.9-1.2-4.8c-0.2-3.5,1.4-6.3,3.8-7.5
			c2.4-1.2,5.6-0.9,8.4,1.3c1.2,1.6,2.4,2.9,3.2,3.8c5.7,6.2,14.4,12.7,19.9,12.6c2.8-0.1,8.3-2.1,14.7-5.2
			c13.8-6.7,31.6-18.2,33.3-26.2c1.5-7.1,1.3-19.4-0.7-27.6c0.8-1.1,1.6-2.2,2.4-3.4c0.5-0.7,0.5-1.5,0.1-2.2
			c-0.8-1.3-1.7-2.6-2.5-3.8c-0.5-0.7-1.3-1-2.2-0.8c-1.5,0.5-3,0.9-4.5,1.4c-7.6-1.8-20.4,3.9-35.9,12.8
			c-0.5,0.3-0.9,0.5-1.4,0.8c-17.3,10-26.1,16.4-29.3,22.7c-2.6,2.8-5.6,5.2-8.9,6.9c-3.3,1.7-7,2.8-10.8,3.3
			c-7-1-17.3,2.5-35.5,10.8c-0.5,0.2-1,0.4-1.5,0.7C32.1,66.8,19.9,73.9,17,81.2c-1.2,1-2.5,1.9-3.7,2.9c-0.7,0.5-0.9,1.4-0.6,2.2
			c0.6,1.4,1.1,2.8,1.7,4.3C14.7,91.3,15.4,91.8,16.2,91.8z M81.3,52.1L23.5,78.8c1.9-2.1,4.7-4.2,8.1-6.1
			c4.9-2.8,11.1-5.8,18.2-9c0.5-0.2,1-0.5,1.5-0.7C66.3,56.1,75.3,52.5,81.3,52.1z M22.4,89.1C27.2,86.6,38,81,50.1,74.8l0,0
			c-9.7,11.4-10.3,15.6-12.6,18c-2.4,2.4,7.7,2.6,13.1-3.2c5.4-5.8,3-7.5,10.8-15.2c6.4-6.3,17.2-13.6,28.1-19.8
			c1.8,1.8,3.6,5,4.6,9.8c1.9,9,1.6,18.2-0.8,21.3c-4.9,6.3-40.6,24-50,20.1C35.6,102.3,26.4,94.5,22.4,89.1z"/>
	</g>
</g>
<g>
	<path class="st16" d="M169.7,3L94.5,46.1c0,0-34.7,17.3-44.4,28.7c-9.7,11.4-10.3,15.6-12.6,18c-2.4,2.4,7.7,2.6,13.1-3.2
		c5.4-5.8,3-7.5,10.8-15.2c7.8-7.7,22.3-17,35.4-23.8c13.1-6.8,79.4-41,79.4-41L169.7,3z"/>
	<path class="st16" d="M18.1,81.2l78.6-36.3c0,0,34.2-18.3,49.1-19.6c14.9-1.3,18.6,0.7,22,0.2c3.4-0.5-2.3,7.8-10.2,8.8
		c-7.9,1-7.8-1.9-18.6,0.1c-10.8,1.9-26.8,8.3-39.9,15.1c-13.1,6.8-79.4,41-79.4,41L18.1,81.2z"/>
	<g>
		<path class="st17" d="M173.6,3.9c-6.4-4.2-18.7,2.6-36.1,12.5c-21.4,12.3-31.8,18.8-32.7,26.2c-0.8,7.4,17.8,25.3,26.1,25.2
			c8.3-0.4,44.2-19.6,46.6-30.2C179.9,26.7,178,6.8,173.6,3.9z"/>
		<path class="st16" d="M111.1,56.6c5.7,6.2,14.4,12.7,19.9,12.6c2.8-0.1,8.3-2.1,14.7-5.2c13.8-6.7,31.6-18.2,33.3-26.2
			c2.1-10.2,0.9-31.5-4.6-35.1c-6.9-4.6-21.1,1.5-38.8,11.6c-0.5,0.3-0.9,0.5-1.4,0.8c-21.6,12.5-29.9,19.3-30.8,27.4
			C102.9,47.2,108.7,53.9,111.1,56.6z M107.7,43c0.8-6.7,8.4-12.3,29.1-24.3c0.5-0.3,0.9-0.5,1.4-0.8c6.8-3.9,12.8-7.3,17.9-9.6
			c7-3.2,13.2-4.2,16.4-2.1c3.4,2.3,6,19.8,3.7,31c-2.2,9.9-37.3,28.8-45.3,29.1c-3.9,0.1-11.5-5-17.8-11.7
			C108.7,49.8,107.5,45.5,107.7,43z"/>
		<path class="st16" d="M176.8,12.4c1.3-1.8,2.6-3.6,3.9-5.4c0.5-0.7,0.5-1.5,0.1-2.2c-0.8-1.3-1.7-2.6-2.5-3.9
			c-0.5-0.7-1.3-1-2.2-0.8c-1.6,0.5-3.3,1-4.9,1.5C173,5.2,174.9,8.8,176.8,12.4z"/>
		<path class="st17" d="M17.8,84.3c0.2-7.7,13-13.8,31.1-22.2c22.5-10.3,33.7-15,40.3-11.4c6.5,3.7,10.3,29.2,5.4,35.9
			C89.5,93.1,53,111.3,42.9,107C32.8,102.6,17.7,89.6,17.8,84.3z"/>
		<path class="st16" d="M89.9,49.4c-7.1-3.9-17.5-1.1-40.1,9.2c-0.5,0.2-1,0.5-1.5,0.7c-18.5,8.5-31.7,16.6-31.9,24.9
			c-0.2,6.5,16.4,19.9,26,24.1c7.5,3.2,27.1-4.6,40.6-12c6.2-3.4,11-6.7,12.7-9c3.3-4.5,3-15.3,1.3-23.6
			C96.2,60.3,94.1,51.8,89.9,49.4z M94.2,64.4c1.9,9,1.6,18.2-0.8,21.3c-4.9,6.3-40.6,24-50,20.1c-10.5-4.6-23.2-16.9-23.1-21
			c0.1-3.8,4.5-8.3,11.2-12.1c4.9-2.8,11.1-5.8,18.2-9c0.5-0.2,1-0.4,1.5-0.7C73,53,82,50,87.9,53.3C90.1,54.4,92.9,58,94.2,64.4z
			"/>
		<path class="st16" d="M96,44.6c3.7-1.9,7-4.5,9.7-7.8c0.4,4.6,1.7,11.3,2.2,15.9c-2.8-2.2-6-2.5-8.4-1.3c-2.4,1.3-4,4-3.8,7.6
			c-3.4-3-8.2-8-11.7-11C88.3,47.7,92.3,46.5,96,44.6z"/>
		<path class="st16" d="M22.9,91.8c-2.2,0-4.5,0-6.7,0c-0.8,0-1.5-0.5-1.8-1.2c-0.6-1.4-1.1-2.8-1.7-4.3c-0.3-0.8-0.1-1.7,0.6-2.2
			c1.4-1,2.7-2.1,4.1-3.1C19.2,84.6,21.1,88.2,22.9,91.8z"/>
	</g>
	<g>
		<path class="st18" d="M134.9,19.9l32.9,27.3c2.2-1.7,4.1-3.5,5.5-5.1L141.8,16c-1.1,0.7-2.3,1.3-3.5,2c-0.5,0.3-0.9,0.5-1.4,0.8
			C136.2,19.1,135.6,19.5,134.9,19.9z"/>
		<path class="st18" d="M125.8,25.3l33.5,27.9c0.7-0.5,1.4-0.9,2.2-1.4L128,24C127.3,24.4,126.6,24.9,125.8,25.3z"/>
		<path class="st18" d="M121,28.4l33.5,27.8c1.1-0.6,2.1-1.3,3.2-1.9l-33.5-27.9l0,0C123,27.1,121.9,27.8,121,28.4z"/>
		<path class="st18" d="M50,63.5L84.4,92c2.6-1.4,4.8-2.8,6.7-4.1L57.6,60.1c-2,0.9-4.1,1.8-6.3,2.8C50.8,63.1,50.4,63.3,50,63.5z
			"/>
		<path class="st18" d="M64.1,57.3l30.6,25.4c0.2-0.8,0.4-1.8,0.5-2.8L66.7,56.2C65.8,56.6,65,56.9,64.1,57.3z"/>
		<path class="st18" d="M32.7,72l34.7,28.8c1.2-0.5,2.4-1,3.7-1.6l-34.9-29C34.9,70.8,33.7,71.4,32.7,72z"/>
	</g>
</g>
</svg>
