import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
	menu = [
		{name:'ABOUT ME', url: '/'},
		/*{name:'AT WORK', url: '/at-work'},
		{name:'LANGUAGES', url: '/languages'},
		{name:'PORTFOLIO', url: '/portfolio'},
		{name:'CONTACT', url: '/contact'}*/
	];

	constructor() { }

	ngOnInit(): void {
	}

}
